<template>
  <button
    :class="[
      { 'px-4 py-2 h-unset md:h-9 lg:h-12 md:py-2 md:px-6': !customClass },
    ]"
    :disabled="isDisabled || isLoading"
    class="text-body-medium md:text-body-big rounded-full outline-none backdrop-blur-sm duration-100 transition-all disabled:cursor-not-allowed whitespace-nowrap"
  >
    <div
      class="h-full w-full flex justify-center items-center font-bold transition-all duration-200"
    >
      <Icon
        v-if="isLoading"
        :size="parsedSize"
        name="IconSpinner"
        class="me-1.5 animate-spin"
      ></Icon>
      <span class="text-white opacity-[0.87] disabled:opacity-50">
        <slot>{{ label }}</slot>
      </span>
    </div>
  </button>
</template>

<script setup>
const { label, isLoading, customClass } = defineProps({
  label: {
    type: String,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  customClass: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: () => false,
  },
});

const { isLgAndUp } = useBreakpoints();

const parsedSize = computed(() => {
  if (isLgAndUp.value) {
    return 24;
  } else {
    return 20;
  }
});
</script>
