export default {
  website_title: "Watch the Latest Movies, Shows & Series Online",
  website_description:
    "Stream your favorite Movies, Series & TV Shows Online and Enjoy a diverse selection of content. Experience Endless Entertainment only on ADtv.",
  movies_main_category_page_title: "Watch your Favorite Movies Online",
  movies_main_category_page_description:
    "Stream the Latest Arabic and International Movies Online. Binge Watch your Favourite Movies at your Convenience Exclusively on ADtv.",
  movie_detail_page_title: "Watch {name}",
  movie_watch_page_title: "Stream {name}",
  movie_watch_page_description:
    "Watch {name} and enjoy a unique captivating cinematic experience. Start streaming your favorite movies now on ADtv.",
  shows_main_category_page_title:
    "Watch your Favorite Programs & Series Online",
  shows_main_category_page_description:
    "Stream the Latest Arabic and International Programs and Series Online. Binge Watch your Favourite TV Shows at your Convenience Exclusively on ADtv.",
  sports_main_category_page_title: "Sports Channel {'|'} Live Sports Broadcast",
  sports_main_category_page_description:
    "Watch the Live Broadcast of the Latest Sports Events. Subscribe Now and Enjoy Watching Games and tournaments on Yas Channel, Abu Dhabi Sports 1 Or 2.",
  kids_main_category_page_title:
    "The Best Children's Programs & TV Shows Online",
  kids_main_category_page_description:
    "Stream Children's Programs and TV Shows Online. Delight your Little ones with a Mix of Educational and Entertaining Content only on ADtv.",
  programs_main_subcategory_page_title: "Watch Kids Animated Programs",
  programs_main_subcategory_page_description:
    "Stream the Best Kids Animated Programs and TV Shows Online. Delight your Little ones with a Mix of Educational and Entertaining Content only on ADtv.",
  songs_main_subcategory_page_title: "The Best Children's Songs",
  songs_main_subcategory_page_description:
    "Stream the Best Children's Songs and Treat your Little ones to a Musical Adventure. Enjoy a Collection of Delightful Tunes only on ADtv.",
  songs_player_page_title: "{songName}",
  songs_player_page_description:
    "Bring joy and entertainment to your kids with delightful children's songs. Tune in to {songName} on ADtv!",
  characters_main_subcategory_page_title:
    "Cartoon Characters {'|'} Animated Children's Programs",
  characters_main_subcategory_page_description:
    "Delight Your Kids with Entertaining and Educational Cartoon Character Programs. Stream the Best Animated Children's Programs on ADtv!",
  character_page_title: "Stream {name} Related Shows",
  character_page_description:
    "Subscribe Now to ADtv for High-Quality Entertainment. Let Your Kids Enjoy Programs and Episodes Related to {name}!",
  majid_tv_title: "Watch Majid TV {'|'} Live Broadcast",
  majid_tv_description:
    "Stream the Live Broadcast of Majid TV Cartoon Programs Online on ADtv. Subscribe Now and Immerse your Kids In a World of Entertainment and Education.",
  live_main_category_page_title: "Live Broadcast Channel",
  live_main_category_page_description:
    "Watch the Live Broadcast of Channels Featuring Informative Content, the Latest Sports Events and News Coverage of the UAE and Abu Dhabi.",
  live_channel_page_title: "Watch {channelName} {'|'} Live Broadcast",
  live_channel_page_description:
    "Watch the live broadcast of {channelName} and follow the latest events In real-time. Enjoy the latest content and programs exclusively on ADtv website.",
  sports_channel_page_title: "Watch the Live Broadcast of {channelName}",
  sports_channel_page_description:
    "Stream the Broadcast of {channelName} and Enjoy Watching Matches, Exclusive tournaments Coverage and Live Streaming of Major Sports Events only on ADtv.",
  show_season_page_title: "Watch {seriesName} - Season {seasonNum}",
  show_season_page_description:
    "Stream {seriesName} and Discover the Latest Twists with Season {seasonNum}. Watch the Storyline Unfold at your Convenience only on ADtv.",
  show_episode_watch_page_title:
    "Watch {seriesName} - Season {seasonNum} - Ep {episodeNum}",
  show_episode_watch_page_description:
    "Explore the Latest Updates in Season {seasonNum} of {seriesName} by Watching Episode {episodeNum}. Stream Your Favorite Shows Online with ADtv!",
  show_season_page_title_to_program: "Watch {seriesName} - Season {seasonNum}",
  show_episode_watch_page_title_to_program:
    "Watch {seriesName} - Season {seasonNum} - Ep {episodeNum}",
  sports_show_detail_season_page_title:
    "Watch {seriesName} {'|'} Sports Programs",
  sports_show_detail_season_page_description:
    "Explore the World of Sports with {seriesName}. Subscribe Now for Exclusive High-Quality Entertainment on ADtv!",
  sports_show_episode_page_title: "Watch {seriesName} - Season {seasonNum}",
  sports_show_episode_page_description:
    "Discover Intriguing Sports Insights in Every Episode of {seriesName}. Watch Episode {episodeNum} from Season {seasonNum} Exclusively on ADtv!",
  program_page_title: "{seriesName} - Season {seasonNum}",
  program_page_description:
    "Watch the best kids shows & programs on ADtv! Stream season {seasonNum} of {seriesName} for your kids entertainment.",
  program_episode_watch_page_title:
    "Watch E{episodeNum} - {seriesName} - Season {seasonNum}",
  program_episode_watch_page_description:
    "Enjoy high-quality entertainment on ADtv! Stream episode {episodeNum} from season {seasonNum} of {seriesName} for your kids entertainment.",
  news_main_category_page_title: "Watch the Latest News & Top Stories Online",
  news_main_category_page_description:
    "Stay Informed with the latest News Updates from around the world. Explore top stories, breaking news, and in-depth coverage on various topics on ADtv. ",
  news_show_detail_season_page_title:
    "Stream {seriesName} - Season {seasonNum}",
  news_show_detail_season_page_description:
    "Watch season {seasonNum} of {seriesName} to discover the latest news and updates. Explore the latest stories in the UAE and around the world on ADtv.",
  news_show_episode_page_title:
    "Watch {seriesName} - Season {seasonNum} - E{episodeNum}",
  news_show_episode_page_description:
    "Discover Intriguing Sports Insights in Every Episode of {seriesName}. Watch Episode {episodeNum} from Season {seasonNum} Exclusively on ADtv!",
  episode_structured_name:
    "{seriesName} - Season {seasonNum} - Ep. - {episodeNum}",
};
