import * as voplayer from "voplayer-html5";
import * as voplayer_adsext from "voplayer-html5/dist/voplayer-adsext.min.js";

export default defineNuxtPlugin(() => {
  return {
    provide: {
      voplayer,
    },
  };
});
