export default {
    whoTitle: "Who are we",
    whoPart: `
    ADtv is Abu Dhabi Media's video-on-demand 
    platform that provides exclusive, free, 
    high-quality entertainment to Arab viewers, 
    including new and premium content from Nat Geo. 
    The ADtv application includes a full range of digital services, 
    including live broadcasts of the Abu Dhabi TV network.
    `,
};