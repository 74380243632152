import focusable, { focusable as focusableProvider } from 'vue-tv-focusable';
import '../assets/css/focusable.css';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(focusable);
  focusableProvider.init({
    focusClassName: 'tvfocus',
    distanceToCenter: true,
    offsetDistance: 0,
  });
  return {
    provide: {
      focusableProvider,
    },
  };
});
