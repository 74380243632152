export default {
  privacyTitle: "سياسة الخصوصية",
  privacySubTitle:
    "تصف سياسة الخصوصية الماثلة سياساتنا وإجراءاتنا بشأن جمع معلوماتك واستخدامها والكشف عنها عند استخدام الخدمة وتوضح لك حقوق الخصوصية الخاصة بك وكيف يحميك القانون. نحن نستخدم بياناتك الشخصية لتوفير الخدمة وتحسينها. بمجرد استخدامك الخدمة، فإنك توافق على جمع واستخدام المعلومات وفقًا لسياسة الخصوصية الماثلة. ",
  privacyUpdate: "آخر تحديث - 16 أغسطس 2024 ",
  privacySection1Title: "التفسير والتعريفات ",
  privacySection1SubTitle1: "التفسير ",
  privacySection1Part1: `
تكون للكلمات المكتوبة بخط عريض فيما يلي المعاني الموضحة وفقًا للشروط التالية، ويكون للتعريفات التالية نفس المعنى سواء كانت بصيغة المفرد أو الجمع.  
    `,
  privacySection1SubTitle2: "التعريفات",
  privacySection1Part2: `لأغراض سياسة الخصوصية الماثلة: 

الحساب يُقصد به حساب خاص تم إنشاؤه لك للوصول إلى خدمتنا كلها أو بعضها. 

الشركة التابعة يُقصد بها أي كيان يتحكم أو يخضع لسيطرة مباشرة أو مشتركة مع طرف ما، ويُقصد بكلمة "السيطرة" ملكية 50% أو أكثر من الأسهم أو حصص الملكية أو الأوراق المالية الأخرى التي يحق لها التصويت لانتخاب أعضاء مجلس الإدارة أو أي سلطة إدارية أخرى. 

التطبيق يُقصد به البرنامج، "ADtv"، الذي تقدمه الشركة، وتقوم أنت بتحميله على أي جهاز إلكتروني.  

الشركة (يشار إليها إما بـ "الشركة" أو "نحن" أو "نا" أو "خاصتنا" في هذه الاتفاقية) وتشير إلى شركة أبوظبي للإعلام، شركة مساهمة عامة، الكائنة في شارع محمد بن خليفة، منطقة المرور، ص.ب. 63، أبوظبي، الإمارات العربية المتحدة. 

ملفات تعريف الارتباط هي ملفات صغيرة يتم وضعها على جهاز الكمبيوتر الخاص بك أو جهازك المحمول أو أي جهاز آخر بواسطة موقع إلكتروني، وتحتوي على تفاصيل سجل التصفح الخاص بك على الموقع الإلكتروني المذكور من بين استخداماته المتعددة. 

البلد تشير إلى: الإمارات العربية المتحدة 

الجهاز يُقصد به أي جهاز يمكنه الوصول إلى الخدمة مثل جهاز كمبيوتر أو هاتف محمول أو جهاز لوحي رقمي. 

البيانات الشخصية هي أي معلومات تتعلق بفرد محدد أو يمكن التعرف عليه. 

المنصات السابقة تشير إلى ما يخص الشركة سواء تطبيقا ماجد وADTV، وموقعا ماجد وADTV الموجودان في <a class="terms-link" href="https://www.majid.ae" target="_blank">www.majid.ae</a>، و <a class="terms-link" href="https://www.adtv.ae" target="_blank">www.adtv.ae</a>. 

الخدمة تشير إلى التطبيق أو الموقع الإلكتروني أو كليهما. 

مقدم الخدمة يُقصد به أي شخص طبيعي أو اعتباري يقوم بمعالجة البيانات نيابة عن الشركة. ويشير إلى شركات الطرف الخارجي أو الأفراد العاملين لدى الشركة لتسهيل الخدمة أو تقديم الخدمة نيابة عن الشركة أو أداء الخدمات المتعلقة بالخدمة أو مساعدة الشركة في تحليل كيفية استخدام الخدمة. 

بيانات الاستخدام تشير إلى البيانات التي يتم جمعها تلقائيًا، إما الناتجة عن استخدام الخدمة أو من البنية التحتية للخدمة نفسها (على سبيل المثال، مدة زيارة الصفحة). 

الموقع الإلكتروني يشير إلى الموقع الإلكتروني الذي يمكن الدخول إليه عن طريق <a class="terms-link" href="https://www.adtv.ae" target="_blank">https://www.adtv.ae</a>. 

أنت مصطلح يشير إلى الفرد الذي يصل إلى الخدمة أو يستخدمها أو الشركة أو أي كيان قانوني آخر يقوم هذا الفرد بالنيابة عنه بالوصول إلى الخدمة أو استخدامها، حسب الاقتضاء.  `,
  privacySection2Title: `جمع واستخدام بياناتك الشخصية `,
  privacySection2SubTitle1: `أنواع البيانات التي يتم جمعها `,
  privacySection2SubTitle2: `البيانات الشخصية `,
  privacySection2Part1: `أثناء استخدام خدمتنا، قد نطلب منك تزويدنا ببعض معلومات التعريف الشخصية التي يمكن استخدامها للاتصال بك أو التعرف عليك. قد تتضمن معلومات التعريف الشخصية، على سبيل المثال لا الحصر، ما يلي: 

عنوان البريد الإلكتروني 

الاسم الأول واسم العائلة 

رقم الهاتف 

العنوان، الدولة، المقاطعة، الرقم البريدي/الرمز البريدي، المدينة 

معلومات الحساب البنكي من أجل الدفع مقابل المنتجات و/أو الخدمات ضمن الخدمة 

بيانات الاستخدام 

عندما تدفع مقابل منتج و/أو خدمة عبر التحويل المصرفي، قد نطلب منك تقديم معلومات لتسهيل هذه المعاملة والتحقق من هويتك. وقد تتضمن هذه المعلومات، على سبيل المثال لا الحصر، ما يلي: 

تاريخ الميلاد 

جواز السفر أو بطاقة الهوية الوطنية 

بيانات البطاقة البنكية 

معلومات أخرى تربطك بالعنوان `,
  privacySection2SubTitle3: `بيانات الاستخدام `,
  privacySection2Part2: `يتم جمع بيانات الاستخدام تلقائيًا عند استخدام الخدمة. 

قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت الخاص بجهازك (مثل عنوان IP)، ونوع المتصفح، وإصدار المتصفح، وصفحات خدمتنا التي تزورها، ووقت وتاريخ زيارتك، والوقت الذي تقضيه في تلك الصفحات، ومعرفات الأجهزة الفريدة والبيانات التشخيصية الأخرى. 

عندما تصل إلى الخدمة عن طريق أو من خلال جهاز محمول، قد نقوم بجمع معلومات معينة تلقائيًا، بما في ذلك، على سبيل المثال لا الحصر، نوع الجهاز المحمول الذي تستخدمه، والمعرف الفريد لجهازك المحمول، وعنوان IP الخاص بجهازك المحمول، ونظام تشغيل هاتفك المحمول ونوع متصفح الإنترنت الذي تستخدمه عبر جهازك المحمول ومعرفات الجهاز الفريدة والبيانات التشخيصية الأخرى. 

يجوز لنا أيضًا جمع المعلومات التي يرسلها متصفحك عندما تزور خدمتنا أو عندما تصل إلى الخدمة عن طريق جهاز محمول أو من خلاله. 

أنت توافق على أن تنقل إلى الخدمة أي بيانات شخصية تقدمها للاستفادة منها في المنصات السابقة، وكذلك على أن تستخدم الشركة تلك المعلومات والبيانات الشخصية وتفصح عنها وفقًا لسياسة الخصوصية. 

المنصات السابقة غير موجهة لأي شخص يقل عمره عن 18 عامًا. ولا نجمع عن عمد معلومات تحديد الهوية الشخصية من أي شخص يقل عمره عن 18 عامًا. وإذا كنت أحد الأبوين أو وصيًا ، وعلى علم بأن طفلك قدم لنا سابقًا بيانات شخصية ليتم استعمالها في المنصات السابقة، فيُرجى التواصل معنا، وسنتخذ الخطوات لحذف المعلومات من خوادمنا. `,
  privacySection2SubTitle4: `تقنيات التتبع وملفات تعريف الارتباط `,
  privacySection2Part4: `نستخدم ملفات تعريف الارتباط وتقنيات التتبع المشابهة لتتبع النشاط على خدمتنا وتخزين معلومات معينة. تقنيات التتبع المستخدمة هي الإشارات والعلامات والبرامج النصية لجمع المعلومات وتتبعها ولتحسين خدمتنا وتحليلها. قد تشمل التقنيات التي نستخدمها ما يلي: 

ملفات تعريف الارتباط أو ملفات تعريف الارتباط للمتصفح. ملف تعريف الارتباط هو ملف صغير يتم وضعه على جهازك. ويمكنك توجيه متصفحك لرفض جميع ملفات تعريف الارتباط أو الإشارة إلى وقت إرسال ملف تعريف الارتباط. ومع ذلك، إذا كنت لا تقبل ملفات تعريف الارتباط، فقد لا تتمكن من استخدام بعض أجزاء خدمتنا. ما لم تقم بتعديل إعداد المتصفح الخاص بك بحيث يرفض ملفات تعريف الارتباط، فقد تستخدم خدمتنا ملفات تعريف الارتباط. 

ملفات تعريف ارتباط الفلاش. قد تستخدم بعض ميزات خدمتنا كائنات مخزنة محلية (أو ملفات تعريف ارتباط الفلاش) لجمع وتخزين معلومات حول تفضيلاتك أو نشاطك على خدمتنا. لا تتم إدارة ملفات تعريف ارتباط الفلاش من خلال إعدادات المتصفح نفسها كتلك المستخدمة لملفات تعريف الارتباط الخاصة بالمتصفح. لمزيد من المعلومات حول كيفية حذف ملفات تعريف ارتباط الفلاش، يرجى قراءة "أين يمكنني تغيير إعدادات تعطيل الكائنات المشتركة المحلية أو حذفها؟" متوفر على  <a class="terms-link" href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_" target="_blank" rel="nofollow">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html</a>  

إشارات الويب. قد تحتوي أقسام معينة من خدمتنا ورسائل البريد الإلكتروني الخاصة بنا على ملفات إلكترونية صغيرة تُعرف باسم إشارات الويب (يُشار إليها أيضًا باسم صور GIF الواضحة وعلامات البكسل وصور GIF أحادية البكسل) والتي تسمح للشركة، على سبيل المثال، بإحصاء المستخدمين الذين قاموا بزيارة تلك الصفحات أو فتح بريد إلكتروني للحصول على إحصائيات موقع الويب الأخرى ذات الصلة (على سبيل المثال، تسجيل مدى شهرة قسم معين والتحقق من سلامة النظام والخادم). 

يمكن أن تكون ملفات تعريف الارتباط "دائمة" أو "مؤقتة ضمن جلسة بعينها". تظل ملفات تعريف الارتباط الدائمة على جهاز الكمبيوتر الشخصي أو الجهاز المحمول الخاص بك عندما تكون غير متصل بالإنترنت، بينما يتم حذف ملفات تعريف الارتباط الخاصة بالجلسة بمجرد إغلاق متصفح الويب الخاص بك. ويمكنك معرفة المزيد حول ملفات تعريف الارتباط هنا: كل شيء عن ملفات تعريف الارتباط من خلال برنامج TermsFeed. 

نستخدم كلًا من ملفات تعريف الارتباط الخاصة بالجلسة والدائمة للأغراض المبينة أدناه: 

ملفات تعريف الارتباط الضرورية / الأساسية 

النوع: ملفات تعريف الارتباط للجلسة 

تُدار من قِبل: شركتنا 

الغرض: تعد ملفات تعريف الارتباط هذه ضرورية لتزويدك بالخدمات المتاحة من خلال الموقع ولتمكينك من استخدام بعض ميزاته. فهي تساعد على مصادقة المستخدمين ومنع الاستخدام الاحتيالي لحسابات المستخدمين. بدون ملفات تعريف الارتباط هذه، لا يمكن تقديم الخدمات التي طلبتها، ونحن لا نستخدم ملفات تعريف الارتباط هذه إلا لتزويدك بهذه الخدمات. 

سياسة ملفات تعريف الارتباط / ملفات تعريف الارتباط لقبول الإشعارات 

النوع: ملفات تعريف الارتباط الدائمة 

تُدار من قِبل: شركتنا 

الغرض: تحدد ملفات تعريف الارتباط هذه ما إذا كان المستخدمون قد قبلوا استخدام ملفات تعريف الارتباط على موقع الويب. 

ملفات تعريف الارتباط الوظيفية 

النوع: ملفات تعريف الارتباط الدائمة 

تُدار من قِبل: شركتنا 

الغرض: تسمح لنا ملفات تعريف الارتباط هذه بتذكر الاختيارات التي تقوم بها عند استخدام موقع الويب، مثل تذكر تفاصيل تسجيل الدخول أو تفضيلات اللغة. الغرض من ملفات تعريف الارتباط هذه هو تزويدك بتجربة شخصية أكثر وتجنب الاضطرار إلى إعادة إدخال تفضيلاتك في كل مرة تستخدم فيها موقع الويب. 

لمزيد من المعلومات حول ملفات تعريف الارتباط التي نستخدمها واختياراتك فيما يتعلق بها، يُرجى الاطلاع على سياسة ملفات تعريف الارتباط الخاصة بنا أو قسم ملفات تعريف الارتباط في سياسة الخصوصية الخاصة بنا.`,
  privacySection2SubTitle5: `استخدام بياناتك الشخصية `,
  privacySection2Part5: `يجوز للشركة استخدام البيانات الشخصية للأغراض التالية: 

لتوفير خدمتنا والحفاظ عليها، بما في ذلك مراقبة استخدام خدمتنا. 

لإدارة حسابك: لإدارة تسجيلك كمستخدم للخدمة. يمكن أن تمنحك البيانات الشخصية التي تقدمها إمكانية الوصول إلى وظائف مختلفة للخدمة المتاحة لك كمستخدم مسجَّل. 

من أجل تنفيذ العقد: التطوير والامتثال والتعهد بعقد الشراء للمنتجات أو العناصر أو الخدمات التي اشتريتها أو أي عقد آخر معنا من خلال الخدمة. 

للتواصل معك: للتواصل معك عن طريق البريد الإلكتروني أو المكالمات الهاتفية أو الرسائل النصية القصيرة أو أي أشكال أخرى مماثلة من الاتصالات الإلكترونية، مثل الإشعارات الفورية لتطبيقات الهاتف المحمول فيما يتعلق بالتحديثات أو الاتصالات الإعلامية المتعلقة بالوظائف أو المنتجات أو الخدمات المتعاقد عليها، بما في ذلك التحديثات الأمنية، عندما يكون ذلك ضروريًا أو معقولًا لتنفيذها. 

لتزويدك بالأخبار والعروض الخاصة والمعلومات العامة حول السلع والخدمات والأحداث الأخرى التي نقدمها والتي تشبه تلك التي اشتريتها بالفعل أو استفسرت عنها ما لم تكن قد اخترت عدم تلقي هذه المعلومات. 

لإدارة طلباتك: للعمل على طلباتك المقدمة إليها وإدارتها. 

لأغراض نقل الأعمال: قد نستخدم معلوماتك لتقييم أو إجراء عملية دمج أو تصفية أو إعادة هيكلة أو إعادة تنظيم أو حل أو بيع أو نقل آخر لبعض أو كل أصولنا، سواء كمنشأة مستمرة أو كجزء من إجراءات الإفلاس أو التصفية، أو إجراء مماثل، حيث تكون البيانات الشخصية التي نحتفظ بها حول مستخدمي خدمتنا من بين الأصول المنقولة. 

لأغراض أخرى: قد نستخدم معلوماتك لأغراض أخرى، مثل تحليل البيانات وتحديد اتجاهات الاستخدام وتحديد فعالية حملاتنا الترويجية وتقييم وتحسين خدمتنا ومنتجاتنا وخدماتنا والتسويق وتجربتك. 

قد نشارك معلوماتك الشخصية في الحالات التالية: 

مع مقدمي الخدمة: قد نشارك معلوماتك الشخصية مع مقدمي الخدمة لمراقبة وتحليل استخدام خدمتنا، لمعالجة الدفع، للاتصال بك. 

لأغراض نقل الأعمال: يجوز لنا مشاركة معلوماتك الشخصية أو نقلها فيما يتعلق أو أثناء المفاوضات بشأن أي اندماج أو بيع أصول الشركة أو تمويل أو الاستحواذ على كل أو جزء من أعمالنا لشركة أخرى. 

مع الشركات التابعة: قد نشارك معلوماتك مع شركاتنا التابعة، وفي هذه الحالة سنطلب من تلك الشركات التابعة احترام سياسة الخصوصية الماثلة. ,تشمل شركاتنا التابعة شركتنا الأم وأي شركات فرعية أخرى أو شركاء في مشاريع مشتركة أو شركات أخرى نسيطر عليها أو تخضع لسيطرة مشتركة معنا. 

مع شركاء العمل: قد نشارك معلوماتك مع شركاء العمل لدينا لنقدم لك منتجات أو خدمات أو عروضًا ترويجية معينة. 

مع مستخدمين آخرين: عندما تشارك معلومات شخصية أو تتفاعل بطريقة أخرى في المناطق العامة مع مستخدمين آخرين، فقد يتم عرض هذه المعلومات من قبل جميع المستخدمين وقد يتم توزيعها بشكل عام في الخارج. 

بموافقتك: يجوز لنا الكشف عن معلوماتك الشخصية لأي غرض آخر بموافقتك. `,
  privacySection2SubTitle6: `الاحتفاظ ببياناتك الشخصية `,
  privacySection2Part6: `ستحتفظ الشركة ببياناتك الشخصية طالما كان ذلك ضروريًا للأغراض المنصوص عليها في سياسة الخصوصية الماثلة، فقط. سنحتفظ ببياناتك الشخصية ونستخدمها بالقدر اللازم للامتثال لالتزاماتنا القانونية (على سبيل المثال، إذا طُلب منا الاحتفاظ ببياناتك للامتثال للقوانين المعمول بها)، وحل النزاعات، وإنفاذ اتفاقياتنا وسياساتنا القانونية. 

ستحتفظ الشركة أيضًا ببيانات الاستخدام لأغراض التحليل الداخلي. يتم الاحتفاظ ببيانات الاستخدام عمومًا لفترة زمنية أقصر، إلا عندما يتم استخدام هذه البيانات لتعزيز الأمان أو لتحسين وظائف خدمتنا، أو عندما نكون ملزمين قانونًا بالاحتفاظ بهذه البيانات لفترات زمنية أطول.`,
  privacySection2SubTitle7: `نقل بياناتك الشخصية `,
  privacySection2Part7: `تتم معالجة معلوماتك، بما في ذلك البيانات الشخصية، في مكاتب تشغيل الشركة وفي أي أماكن أخرى تتواجد فيها الأطراف المشاركة في المعالجة. وهذا يعني أنه قد يتم نقل هذه المعلومات إلى - والاحتفاظ بها - على أجهزة الكمبيوتر الموجودة خارج ولايتك أو مقاطعتك أو بلدك أو أي ولاية قضائية حكومية أخرى حيث قد تختلف قوانين حماية البيانات عن تلك الموجودة في ولايتك القضائية. 

إن موافقتك على سياسة الخصوصية الماثلة متبوعة بتقديمك لهذه المعلومات تمثل موافقتك على هذا النقل. 

ستتخذ الشركة جميع الخطوات الضرورية بشكل معقول لضمان التعامل مع بياناتك بشكل آمن ووفقًا لسياسة الخصوصية الماثلة ولن يتم نقل بياناتك الشخصية إلى منظمة أو دولة ما لم تكن هناك ضوابط كافية مطبقة بما في ذلك أمان بياناتك والمعلومات الشخصية الأخرى. `,
  privacySection2SubTitle8: `الكشف عن بياناتك الشخصية `,
  privacySection2SubTitle9: `المعاملات التجارية `,
  privacySection2Part9: `إذا كانت الشركة مشتركة في عملية دمج أو استحواذ أو بيع أصول، فقد يتم نقل بياناتك الشخصية. وسنقدم إشعارًا قبل نقل بياناتك الشخصية وقبل أن تصبح خاضعة لسياسة خصوصية مختلفة. `,
  privacySection2SubTitle10: `تطبيق القانون `,
  privacySection2Part10: `في ظل ظروف معينة، قد يُطلب من الشركة الكشف عن بياناتك الشخصية إذا كان ذلك مطلوبًا بموجب القانون أو استجابة لطلبات صالحة من السلطات العامة (مثل المحكمة أو وكالة حكومية). `,
  privacySection2SubTitle11: `المتطلبات القانونية الأخرى `,
  privacySection2Part11: `يجوز للشركة الكشف عن بياناتك الشخصية عند اعتقادها بحسن نية أن هذا الإجراء ضروري من أجل: 

الامتثال لأي التزام قانوني 

حماية حقوق أو ممتلكات الشركة والدفاع عنها 

منع أو التحقيق في أي مخالفات محتملة فيما يتعلق بالخدمة 

حماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور 

الحماية من المسؤولية القانونية `,
  privacySection2SubTitle12: `أمن بياناتك الشخصية `,
  privacySection2Part12: `يعد أمن بياناتك الشخصية أمرًا مهمًا بالنسبة لنا، ولكن تذكر أنه لا توجد طريقة نقل عبر الإنترنت أو طريقة تخزين إلكترونية آمنة بنسبة 100%. بينما نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحماية بياناتك الشخصية، لا يمكننا ضمان أمانها المطلق. `,
  privacySection3Title: `معلومات تفصيلية عن معالجة بياناتك الشخصية `,
  privacySection3Part1: `قد يتمكن مقدمو الخدمة الذين نستخدمهم من الوصول إلى بياناتك الشخصية. ويقوم هؤلاء الموردون الخارجيون بجمع وتخزين واستخدام ومعالجة ونقل المعلومات حول نشاطك على خدمتنا وفقًا لسياسات الخصوصية الخاصة بهم. `,
  privacySection3SubTitle1: `المدفوعات`,
  privacySection3Part2: `يجوز أن نقدم منتجات و/أو خدمات مدفوعة ضمن الخدمة. وفي هذه الحالة، يجوز لنا أن نستخدم خدمات الغير في عمليات السداد (على سبيل المثال، معالجات الدفع). 

لن نخزن أو نجمع بيانات بطاقة الدفع الخاصة بك. ويتم تقديم تلك المعلومات مباشرة إلى معالجات الدفع من الغير الخاصة بنا، وتحكم سياسة الخصوصية استخدامها لمعلوماتك الشخصية. وتلتزم معالجات الدفع بالمعايير التي حددتها معايير أمن البيانات في صناعة بطاقات الدفع، التي يديرها مجلس معايير الأمن في صناعة بطاقات الدفع، وهو مجهود مشترك من العلامات التجارية فيزا، وماستر كارد، وأمريكان إكسبريس، وديسكفر. تساعد شروط معايير أمن البيانات في صناعة بطاقات الدفع على ضمان التعامل الآمن مع معلومات الدفع. 

عندما تستخدم خدمتنا لتدفع مقابل منتج و/أو خدمة عبر التحويل المصرفي، قد نطلب منك تقديم معلومات لتسهيل هذه المعاملة والتحقق من هويتك. 

تُسدد كل الرسوم بالدرهم الإماراتي. `,
  privacySection4Title: `حقوقك والمزيد من المعلومات `,
  privacySection4Part1: `أنت تتمتع بالحقوق التالية بموجب قانون حماية البيانات: 

يمكنك طلب نسخة من بياناتك الشخصية التي نخزنها عنك. 

يحق لك طلب حذف البيانات الشخصية التي نجمعها عنك، ولكن هناك حدود واستثناءات بشأن هذا الحق مما يسمح لنا بموجبها رفض طلبك. 

يحق لك وضع قيود على معالجة بياناتك الشخصية، أو الاعتراض على معالجة بياناتك الشخصية، في حالات معينة. 

يحق لك طلب نقل بياناتك الشخصية إليك أو إلى أي مؤسسة أخرى، في حالات معينة. 

يمكنك تقديم الطلبات بشأن المذكور أعلاه عن طريق صفحة/نموذج اتصل بنا.`,
  privacySection5Title: `خصوصية القُصر `,
  privacySection5Part1: `الخدمة غير موجَهة لأي شخص يقل عمره عن 18 عامًا. ولا نجمع عن عمد معلومات تحديد الهوية الشخصية من أي شخص يقل عمره عن 18 عامًا. وإذا كنت أحد الأبوين أو وصيًا، وعلى علم بأن طفلك قدَّم لنا سابقًا بيانات شخصية، فيُرجى التواصل معنا. وإذا أصبحنا على علم بأننا قد جمعنا بيانات من شخص يقل عمره عن 18 عامًا دون التحقق من الموافقة الأبوية، نتخذ الخطوات لحذف تلك المعلومات من الخوادم الخاصة بنا. 

إذا كنا نريد الاستناد إلى موافقة على أنها أساس قانوني لمعالجة بياناتك وتشترط بلدك الحصول على موافقة أحد الأبوين، يجوز أن نطلب موافقة أحد أبويك قبل جمع تلك المعلومات واستخدامها. `,
  privacySection6Title: `روابط لمواقع أخرى `,
  privacySection6Part1: `قد تحتوي خدمتنا على روابط لمواقع أخرى لا نقوم بإدارتها. إذا نقرت على رابط طرف خارجي، فسيتم توجيهك إلى موقع الطرف الخارجي. وننصحك بشدة بمراجعة سياسة الخصوصية لكل موقع تزوره. 

ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية أو الممارسات الخاصة بأي مواقع أو خدمات تابعة لجهات خارجية. `,
  privacySection7Title: `التغييرات على سياسة الخصوصية `,
  privacySection7Part1: `يجوز لنا تحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. وسوف نقوم بإعلامك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة. 

سنخبرك عبر البريد الإلكتروني و/أو إشعار بارز على خدمتنا، قبل أن يصبح التغيير ساري المفعول ونقوم بتحديث تاريخ "آخر تحديث" في الجزء العلوي من سياسة الخصوصية الماثلة. 

ننصحك بمراجعة سياسة الخصوصية الماثلة بشكل دوري لمعرفة أي تغييرات. تصبح التغييرات التي يتم إجراؤها على سياسة الخصوصية الماثلة فعالة بمجرد نشرها على هذه الصفحة. `,
  privacySection8Title: `اتصل بنا `,
  privacySection8Part1: `إذا كانت لديك أي أسئلة حول سياسة الخصوصية الماثلة، يمكنك الاتصال بنا: 

عبر البريد الإلكتروني: <a class="terms-link" href="mailto:Legal@admn.ae" target="_blank">Legal@admn.ae</a> `,
};
