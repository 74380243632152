export const PAGES_IDENTIFIER = {
  SPORTS: "sports",
  GENRES: "genres",
  DIRECTORY: "directory",
  SEARCH: "search",
  SONGS: "songs",
};

export const TOAST_TYPE = {
  NORMAL: "normal",
  ERROR: "error",
  LOADING: "loading",
};

export const PLAYERS = {
  // passives
  RADIO_PLAYER: "player-radio",
  HERO_PLAYER: "player-hero",
  DETAIL_PLAYER: "player-detail",
  FLOATER_PLAYER: "player-floater",

  // actives
  SONG_PLAYER: "player-song",
  LIVE_PLAYER: "player-live",
  VOD_PLAYER: "player-vod",
  TRAILER_PLAYER: "player-trailer",
};

export const AUTH_PATHS = ["settings", "profiles", "login/code", "my-list"];

export const DEFAULT_REG_INFO = {
  COUNTRY: {
    isoNation: "AE",
    nation: "UAE",
    dialCode: "+971",
    nameAr: "(+971) الإمارات العربية المتحدة",
    nameEn: "United Arab Emirates (+971)",
    noCodeNameAr: "الإمارات العربية المتحدة",
    noCodeNameEn: "United Arab Emirates",
  },
  //male 1 female 2
  GENDER: 1,
};

export const SEASON_SIZE = 31;

export const LANG = {
  ar: "ar",
  arabic: "ar",
  en: "en",
  english: "en",
};

export const RESPONSE_STATUS = {
  //Token is expired
  expired: 1202001,
  //Household is not activated
  unActivated: 7006,
  //User profile removed
  profileRemoved: 4017,
};

export const DEFAULT_PAGE_SIZE = 20;
export const SCRUB_AMOUNT = 10;

export const PLAYERICON_SIZES = { lg: 40, md: 32, sm: 24 };

export const SESSION_TYPES = {
  LIVE: "LIVE",
  VOD: "VOD",
};

export const ERROR_CODES = {
  PARAM_MISSED: 1201001,
  PARAM_INVALID: 1201002,
  NOT_AUTHENTICATED: 1202001,
  FORBIDDEN_ACCESS: 1202002,
  CONTENT_NOT_FOUND: 1204004,
  GEO_BLOCKING: 1203001,
  EXTERNAL_ERROR: 1204001,
  INTERNAL_ERROR: 1205001,
};

export const PAGE_IDS = {
  LANDING__HOME: "LANDING__HOME",
  LANDING__SHOWS: "LANDING__SHOWS",
  LANDING__MOVIES: "LANDING__MOVIES",
  LANDING__SPORTS: "LANDING__SPORTS",
  LANDING__RADIO: "LANDING__RADIO",
  LANDING__LIVE: "LANDING__LIVE",
  LANDING__SEARCH: "LANDING__SEARCH",
  LANDING__MY_PROFILE: "LANDING__MY_PROFILE",
  LANDING__GUIDE: "LANDING__GUIDE",
  LANDING__WATCH_VOD: "LANDING__WATCH_VOD",
  LANDING__WATCH_EXTERNAL: "LANDING__WATCH_EXTERNAL",
  LANDING__WATCH_LIVE: "LANDING__WATCH_LIVE",
  LANDING_KIDS__HOME: "LANDING_KIDS__HOME",
  LANDING_KIDS__PROGRAMS: "LANDING_KIDS__PROGRAMS",
  LANDING_KIDS__CHARACTERS: "LANDING_KIDS__CHARACTERS",
  LANDING_KIDS__SONGS: "LANDING_KIDS__SONGS",
  LANDING_KIDS__LIVE: "LANDING_KIDS__LIVE",
  LANDING_KIDS__MY_LIST: "LANDING_KIDS__MY_LIST",
  LANDING_KIDS__SEARCH: "LANDING_KIDS__SEARCH",
  LANDING_KIDS__WATCH_VOD: "LANDING_KIDS__WATCH_VOD",
  LANDING_KIDS__WATCH_SONGS: "LANDING_KIDS__WATCH_SONGS",
};

export const GTM_LOGIN = {
  LOGGED_IN: "logged_in",
};

export const SEO_PAGE_CATEGORY_ENUM = {
  SHOWS: "adtv_on_demand",
  SPORTS: "sports_on_demand",
  KIDS_PROGRAMS: "kids_on_demand",
  RADIO: "radio_on_demand",
  NEWS: "news_on_demand",
};

export const SEO_DB_FETCH_LIST = {
  SERIES_FETCH_URL: "/api/biz/config/seo/series",
  MOVIES_FETCH_URL: "/api/biz/config/seo/movies",
  LIVE_CHANNELS_FETCH_URL: "/api/biz/live/home/channels",
  KIDS_CHARACTERS_FETCH_URL: "/api/biz/program/characters",
};

/**
 * Override SEO title
 * These programs are listed in shows in the CMS
 * There is no way in the CMS currently to add 'program' flag for these and are returned as shows.
 * The IDs are listed here which are checked in shows page to generate correct SEO title
 */
export const SERIES_WITH_PROGRAM_SEO_TITLE = ["212638", "229173", "229153"];
