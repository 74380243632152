import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAvatar, LazyIconAddUser, LazyIconArrow, LazyIconArrowLeft, LazyIconBack, LazyIconBrandPlus, LazyIconCheck, LazyIconCheckList, LazyIconCheckboxOff, LazyIconCheckboxOn, LazyIconChecked, LazyIconChevronDown, LazyIconChevronLeft, LazyIconChevronRight, LazyIconChevronUp, LazyIconCircleStop, LazyIconClose, LazyIconCog, LazyIconDot, LazyIconEdit, LazyIconExpand, LazyIconEyeOff, LazyIconEyeOn, LazyIconFire, LazyIconFullscreenEnter, LazyIconFullscreenExit, LazyIconGrid, LazyIconInfo, LazyIconInfoCircle, LazyIconKidLine, LazyIconKidsbar, LazyIconList, LazyIconMinus, LazyIconPause, LazyIconPip, LazyIconPlay, LazyIconPlayNext, LazyIconPlaybackSpeed, LazyIconPlus, LazyIconProfileKids, LazyIconReel, LazyIconReplay, LazyIconScrollToTop, LazyIconSearch, LazyIconShare, LazyIconSignout, LazyIconSkipBackTen, LazyIconSkipForwardTen, LazyIconSkipNext, LazyIconSkipPrev, LazyIconSocialApple, LazyIconSocialFacebook, LazyIconSocialFacebookF, LazyIconSocialFacebookFilled, LazyIconSocialGoogle, LazyIconSocialInstagram, LazyIconSocialTwitter, LazyIconSocialWhatsapp, LazyIconSocialYoutube, LazyIconSort, LazyIconSpinner, LazyIconStopSquare, LazyIconSubtitles, LazyIconThumbs, LazyIconThumbsDown, LazyIconThumbsDownFilled, LazyIconThumbsUp, LazyIconThumbsUpFilled, LazyIconTimes, LazyIconTimesWhite, LazyIconTrailer, LazyIconVideoLibrary, LazyIconVideoSettings, LazyIconVolumeHigh, LazyIconVolumeLow, LazyIconVolumeOff, LazyIconschedule, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["Avatar", LazyAvatar],
["IconAddUser", LazyIconAddUser],
["IconArrow", LazyIconArrow],
["IconArrowLeft", LazyIconArrowLeft],
["IconBack", LazyIconBack],
["IconBrandPlus", LazyIconBrandPlus],
["IconCheck", LazyIconCheck],
["IconCheckList", LazyIconCheckList],
["IconCheckboxOff", LazyIconCheckboxOff],
["IconCheckboxOn", LazyIconCheckboxOn],
["IconChecked", LazyIconChecked],
["IconChevronDown", LazyIconChevronDown],
["IconChevronLeft", LazyIconChevronLeft],
["IconChevronRight", LazyIconChevronRight],
["IconChevronUp", LazyIconChevronUp],
["IconCircleStop", LazyIconCircleStop],
["IconClose", LazyIconClose],
["IconCog", LazyIconCog],
["IconDot", LazyIconDot],
["IconEdit", LazyIconEdit],
["IconExpand", LazyIconExpand],
["IconEyeOff", LazyIconEyeOff],
["IconEyeOn", LazyIconEyeOn],
["IconFire", LazyIconFire],
["IconFullscreenEnter", LazyIconFullscreenEnter],
["IconFullscreenExit", LazyIconFullscreenExit],
["IconGrid", LazyIconGrid],
["IconInfo", LazyIconInfo],
["IconInfoCircle", LazyIconInfoCircle],
["IconKidLine", LazyIconKidLine],
["IconKidsbar", LazyIconKidsbar],
["IconList", LazyIconList],
["IconMinus", LazyIconMinus],
["IconPause", LazyIconPause],
["IconPip", LazyIconPip],
["IconPlay", LazyIconPlay],
["IconPlayNext", LazyIconPlayNext],
["IconPlaybackSpeed", LazyIconPlaybackSpeed],
["IconPlus", LazyIconPlus],
["IconProfileKids", LazyIconProfileKids],
["IconReel", LazyIconReel],
["IconReplay", LazyIconReplay],
["IconScrollToTop", LazyIconScrollToTop],
["IconSearch", LazyIconSearch],
["IconShare", LazyIconShare],
["IconSignout", LazyIconSignout],
["IconSkipBackTen", LazyIconSkipBackTen],
["IconSkipForwardTen", LazyIconSkipForwardTen],
["IconSkipNext", LazyIconSkipNext],
["IconSkipPrev", LazyIconSkipPrev],
["IconSocialApple", LazyIconSocialApple],
["IconSocialFacebook", LazyIconSocialFacebook],
["IconSocialFacebookF", LazyIconSocialFacebookF],
["IconSocialFacebookFilled", LazyIconSocialFacebookFilled],
["IconSocialGoogle", LazyIconSocialGoogle],
["IconSocialInstagram", LazyIconSocialInstagram],
["IconSocialTwitter", LazyIconSocialTwitter],
["IconSocialWhatsapp", LazyIconSocialWhatsapp],
["IconSocialYoutube", LazyIconSocialYoutube],
["IconSort", LazyIconSort],
["IconSpinner", LazyIconSpinner],
["IconStopSquare", LazyIconStopSquare],
["IconSubtitles", LazyIconSubtitles],
["IconThumbs", LazyIconThumbs],
["IconThumbsDown", LazyIconThumbsDown],
["IconThumbsDownFilled", LazyIconThumbsDownFilled],
["IconThumbsUp", LazyIconThumbsUp],
["IconThumbsUpFilled", LazyIconThumbsUpFilled],
["IconTimes", LazyIconTimes],
["IconTimesWhite", LazyIconTimesWhite],
["IconTrailer", LazyIconTrailer],
["IconVideoLibrary", LazyIconVideoLibrary],
["IconVideoSettings", LazyIconVideoSettings],
["IconVolumeHigh", LazyIconVolumeHigh],
["IconVolumeLow", LazyIconVolumeLow],
["IconVolumeOff", LazyIconVolumeOff],
["Iconschedule", LazyIconschedule],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
