export default {
    credentialMismatch:"اسم المستخدم وكلمة المرور غير متطابقين، يرجى التحقق من بيانات الاعتماد الخاصة بك.",
    accountNotExisting:"يرجى التأكد من أنك قد أدخلت البريد الإلكتروني الصحيح أو قم بالتسجيل لإنشاء حساب جديد",
    emailSentToVerify: "تم إرسال رسالة التحقق عبر البريد الإلكتروني",
    resetPassEmailSent: "تم إرسال رسالة إعادة تعيين كلمة المرور",
    profileRemoved: "تمت إزالة ملف تعريف المستخدم بنجاح",
    userProfileRemoved: "تمت إزالة ملف تعريف المستخدم",
    "User profile removed":"تم تحديث صورة الملف الشخصي",
    "Profile picture updated":"تم تحديث صورة الملف الشخصي",
    "New password verified":"تم التحقق من كلمة المرور الجديدة",
    "New mobile number updated":"تم تحديث رقم الجوال الجديد",
    "Parental code changed":"تم تغيير رمز الوالدين",
    "Parental code added":"تمت إضافة رمز الوالدين",
    geoblocking:`
    المحتوى الذي تبحث عنه غير متاح في منطقتك.
    لا داعي للقلق، هناك الكثير لاستكشافه. تفضل بزيارة منصتنا للحصول على أحدث وأكثر المحتويات إثارة.
    `,
    somethingWentWrong:`
    عذرًا! حدث خطأ ما
    استمر في الانتظار! فريقنا يعمل على إصلاح الأمور. يرجى التحقق من اتصالك أو إعادة التحديث. استكشف محتوى جديد بينما نعالج المشكلة.
    !شكرًا لصبرك - سنعود قريبًا
    `,
    limitAge:"يجب ان يكون هناك قيد عمري لتحديد ملفات الأطفال بحيث لا يزيد العمر عن  {age}سنة."
  };
  