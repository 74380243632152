import termsEn from './terms/en';
import privacyEn from './privacy/en';
import whoweareEn from './whoweare/en';
import availableonEn from './availableon/en';
import faqsEn from './faqs/en';

export default {
    ...termsEn,
    ...privacyEn,
    ...whoweareEn,
    ...availableonEn,
    ...faqsEn,
}