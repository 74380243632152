export default {
  codeError: "الرمز غير صحيح",
  smartScreenApp: "تسجيل الدخول عبر تطبيق الشاشة الذكية",
  Email: "البريد الالكتروني",
  Password: "كلمة المرور",
  Continue: "متابعة",
  continue: "متابعة",
  "Sign in": "تسجيل الدخول",
  "Sign In": "تسجيل الدخول",
  "Create an account": "إنشاء حساب",
  continueAsGuest: "المتابعة كزائر",
  "Forgot your password?": "هل نسيت كلمة المرور؟",
  enterCodeContinue: "أدخل الرمز للمتابعة",
  inputPlaceholder: "اختار بلدك",
  whereWatchFrom: "من أين تشاهد؟",
  confirmPassword: "تأكيد كلمة المرور",
  resendCode: "إعادة إرسال الرابط",
  yourPassword: "كلمة المرور",
  yourEmail: "أدخل بريدك الالكتروني",
  endless: "ترفيه لا نهاية له",
  andMore: "أفلام، برامج تلفزيونية، راديو، رياضة، والمزيد",
  existWith: "يمكن للمستخدمين الحاليين تسجيل الدخول بتفاصيل حساباتهم الحالية",
  "First name": "الاسم الأول",
  "Your first name": "أدخل اسمك الأول",
  "Last name": "إسم العائلة",
  "Your last name": "أدخل إسم العائلة",
  "Mobile number": "رقم الهاتف",
  "Provide Your Details": "أدخل التفاصيل أدناه",

  "Confirm email": "تأكيد البريد الالكتروني",
  "Your email": "بريدك الالكتروني",

  greeTo: "أوافق على شروط وأحكام",
  terms: "وسياسة الخصوصية ",
  privacyPolicy: "تعريف الارتباط",
  cookiePolicy: "وسياسة ملفات",
  agreeMarketing:
    "أوافق على تلقي رسائل البريد الإلكتروني التسويقية و عروض خاصة",
  resetPassword:
    "أدخل بريدك الإلكتروني وسنرسل لك رابطاً لإعادة تعيين كلمة المرور الخاصة بك",
  orContinueWith: "أو سجل دخول عبر",
  sendResetLink: "Send reset link",
  "Get Started": "البدء",
  languagePopupTitle: "Would you like to switch language?",
  cancelBtn: "إلغاء",
  exitBtn: "مخرج",
  phoneNumber: "أدخل الرقم",
  setPassword: "تعيين كلمة المرور",
  "We’ve sent a verification link to": "لقد أرسلنا رابط التحقق إلى",
  "We’ve sent a verification code to": "لقد أرسلنا رمز التحقق إلى",
  "Reset Password": "إعادة تعيين كلمة المرور",
  signinTitle: "تسجيل الدخول",
  gender: "الجنس",
  male: "ذكر",
  female: "أنثى",
  Save: "حفظ",
  Logout: "تسجيل خروج",
  "Confirm password": "تأكيد كلمة المرور",
  "Your password": "أدخل كلمة المرور",
  "Your Password": "أدخل كلمة المرور",
  "Failed to update your password": "فشل تحديث كلمة المرور الخاصة بك",
  "Create an Account": "إنشاء حساب",
  "Link Sent": "إعادة إرسال الرابط",
  "Code Sent": "تم ارسال الرمز",
  "Enter code to verify": "أدخل الرمز للتحقق",
  willExpireIn: "ستنتهي صلاحية الرابط خلال {hours} ساعات",
  codeWillExpireIn: "ستنتهي صلاحية الرمز خلال {hours} ساعة",
  resendIn:
    "إعادة إرسال الرابط | إعادة إرسال الرابط في 1 ثانية | إعادة إرسال الرابط في {count} ثواني",
  resendCodeIn:
    "إرسال الرمز مجدداً | إعادة إرسال الرمز بعد 1 ثانية | إعادة إرسال الرمز بعد { count } ثانية",
  "Set Your Password": "تعيين كلمة المرور",
  "The password should include a minimum of:":
    "يجب أن تتضمن كلمة المرور ما لا يقل عن:",
  "Welcome back": "مرحبًا بعودتك",

  "8 characters": "8 أحرف",
  "1 upper case alphabet": "يجب استخدام حرف كبير على الأقل",
  "1 lower case alphabet": "يجب استخدام حرف صغير على الأقل",
  "1 number": "رقم واحد",
  "1 symbol": "رمز واحد",
  "Sign out": "تسجيل الخروج",
  emailSend: "تم إرسال رسالة التحقق عبر البريد الالكتروني",
  "This field is required": "هذا الحقل هو حقل مطلوب",
  "This field cannot exceed 50 characters.":
    "هذا الحقل لا يمكن أن يكون أكثر من 50 حرفا .",
  "Please make sure the passwords match!":
    "الرجاء التأكد من تطابق كلمة المرور !",
  and: "",
  FBSignInButAccountHasNoEmail:'لا يمكننا متابعة عملية التسجيل/تسجيل الدخول باستخدام فيسبوك لأن عنوان البريد الإلكتروني مفقود.'
};
