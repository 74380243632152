import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexNHpGcIYxXCMeta } from "/app/pages/[category]/index.vue?macro=true";
import { default as about_45usWjawxlkv4gMeta } from "/app/pages/about-us.vue?macro=true";
import { default as apple_45auth_45callbackVlxPGrKvS9Meta } from "/app/pages/apple-auth-callback.vue?macro=true";
import { default as available_45on9iaFQGc5K0Meta } from "/app/pages/available-on.vue?macro=true";
import { default as cookie_45policy2bZRk7g5I8Meta } from "/app/pages/cookie-policy.vue?macro=true";
import { default as indexUAMGQig0OAMeta } from "/app/pages/create-account/index.vue?macro=true";
import { default as indexthYsYeqH4nMeta } from "/app/pages/customize-recommendations/index.vue?macro=true";
import { default as _91slug_93zeFsql0QOQMeta } from "/app/pages/directory/[slug].vue?macro=true";
import { default as indexlf1MJmqZz4Meta } from "/app/pages/empty/index.vue?macro=true";
import { default as indexnol22AXlVtMeta } from "/app/pages/forgot-password/index.vue?macro=true";
import { default as _91genre_936k1SyX9Eb9Meta } from "/app/pages/genres/[genre].vue?macro=true";
import { default as _91genre_93ELXpYbi5jzMeta } from "/app/pages/genres/programs/[genre].vue?macro=true";
import { default as _91category_9347VkXvlbAEMeta } from "/app/pages/kids/[category].vue?macro=true";
import { default as _91specific_93AltvB2dLEeMeta } from "/app/pages/kids/characters/[specific].vue?macro=true";
import { default as index2pvzlVg3MKMeta } from "/app/pages/kids/characters/index.vue?macro=true";
import { default as indexkjTLcvAO5aMeta } from "/app/pages/kids/index.vue?macro=true";
import { default as searchqJXpayBFGYMeta } from "/app/pages/kids/search.vue?macro=true";
import { default as songslrOJrIMMJsMeta } from "/app/pages/kids/songs.vue?macro=true";
import { default as indexIf7CFQYCebMeta } from "/app/pages/link-tv/index.vue?macro=true";
import { default as _91channel_93LShsme7k7hMeta } from "/app/pages/live/[channel].vue?macro=true";
import { default as index4SOLgZssGlMeta } from "/app/pages/live/index.vue?macro=true";
import { default as codeuegJusolFcMeta } from "/app/pages/login/code.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as _91code_93G0uRFSuz9LMeta } from "/app/pages/login/m/[code].vue?macro=true";
import { default as _91media_93eal1tiUQidMeta } from "/app/pages/media/[media].vue?macro=true";
import { default as indexUDRirzcz0HMeta } from "/app/pages/movies/[movieId]/[slug]/index.vue?macro=true";
import { default as watchXcehkaRQ9KMeta } from "/app/pages/movies/[movieId]/[slug]/watch.vue?macro=true";
import { default as indexHmkOTh6ZqWMeta } from "/app/pages/my-list/index.vue?macro=true";
import { default as set6s6sMpS2zYMeta } from "/app/pages/parental-code/set.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as indexBqiuZpk0bsMeta } from "/app/pages/profiles/index.vue?macro=true";
import { default as indexeV3TMC4AIXMeta } from "/app/pages/radio/index.vue?macro=true";
import { default as _91blend_93zfYCIZ9IxDMeta } from "/app/pages/schedule/[blend].vue?macro=true";
import { default as indexP0q8KYV1WMMeta } from "/app/pages/search/index.vue?macro=true";
import { default as songs_45playerwAthZvfkenMeta } from "/app/pages/search/songs-player.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as indexbxuCleBA4aMeta } from "/app/pages/show/[id]/[name]/index.vue?macro=true";
import { default as indexNB8pX1Gns9Meta } from "/app/pages/shows/[seriesId]/[slug]/[seasonNumber]/[episodeNumber]/index.vue?macro=true";
import { default as index66F3lEFOtzMeta } from "/app/pages/shows/[seriesId]/index.vue?macro=true";
import { default as indexUxXYbvqp06Meta } from "/app/pages/sign-in/index.vue?macro=true";
import { default as index920Gn3sCkQMeta } from "/app/pages/sports/categories/index.vue?macro=true";
import { default as indexRFa2z0Ga1bMeta } from "/app/pages/sports/index.vue?macro=true";
import { default as statusAyOFU8xai2Meta } from "/app/pages/status.vue?macro=true";
import { default as termsy18ZESLqu6Meta } from "/app/pages/terms.vue?macro=true";
import { default as _91theme_936oi2RU3tXRMeta } from "/app/pages/theme/[theme].vue?macro=true";
import { default as indexXWzoKrzl7TMeta } from "/app/pages/watch/external/index.vue?macro=true";
import { default as indexTYYLLz16yeMeta } from "/app/pages/watch/index.vue?macro=true";
import { default as _91channel_93STOk3oQEbyMeta } from "/app/pages/watch/live/[channel].vue?macro=true";
import { default as _91channel_93UkElexmNXdMeta } from "@/pages/live/[channel].vue?macro=true";
import { default as _91theme_93UX6rTu0AAAMeta } from "@/pages/theme/[theme].vue?macro=true";
import { default as _91slug_93lkCIggjiATMeta } from "@/pages/directory/[slug].vue?macro=true";
import { default as indexPsJtbitOOPMeta } from "@/pages/my-list/index.vue?macro=true";
import { default as _91blend_93offzd5Va8rMeta } from "@/pages/schedule/[blend].vue?macro=true";
import { default as songs_45player4ggZ0DKNRcMeta } from "@/pages/search/songs-player.vue?macro=true";
import { default as songsUMo5k3lmgUMeta } from "@/pages/kids/songs.vue?macro=true";
import { default as termsEdpqwMtXY6Meta } from "@/pages/terms.vue?macro=true";
import { default as privacyaHMMFDBYv5Meta } from "@/pages/privacy.vue?macro=true";
import { default as about_45us8C5NHjacxmMeta } from "@/pages/about-us.vue?macro=true";
import { default as available_45onS5r9a1HjmGMeta } from "@/pages/available-on.vue?macro=true";
import { default as cookie_45policy5wsDLuG5BWMeta } from "@/pages/cookie-policy.vue?macro=true";
import { default as indexW7ZZRJA0dXMeta } from "@/pages/watch/index.vue?macro=true";
import { default as indexROpAgbIi1fMeta } from "@/pages/shows/[seriesId]/index.vue?macro=true";
import { default as indexRltcMR3hfXMeta } from "@/pages/shows/[seriesId]/[slug]/[seasonNumber]/[episodeNumber]/index.vue?macro=true";
import { default as indexHnrzASXykvMeta } from "@/pages/show/[id]/[name]/index.vue?macro=true";
import { default as index5cgARK3OC1Meta } from "@/pages/create-account/index.vue?macro=true";
import { default as index3JQzKs4byXMeta } from "@/pages/forgot-password/index.vue?macro=true";
export default [
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHpGcIYxXCMeta?.name ?? "category___en",
    path: indexNHpGcIYxXCMeta?.path ?? "/en/:category()",
    meta: indexNHpGcIYxXCMeta || {},
    alias: indexNHpGcIYxXCMeta?.alias || [],
    redirect: indexNHpGcIYxXCMeta?.redirect || undefined,
    component: () => import("/app/pages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHpGcIYxXCMeta?.name ?? "category___ar",
    path: indexNHpGcIYxXCMeta?.path ?? "/ar/:category()",
    meta: indexNHpGcIYxXCMeta || {},
    alias: indexNHpGcIYxXCMeta?.alias || [],
    redirect: indexNHpGcIYxXCMeta?.redirect || undefined,
    component: () => import("/app/pages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: about_45usWjawxlkv4gMeta?.name ?? "about-us___en",
    path: about_45usWjawxlkv4gMeta?.path ?? "/en/about-us",
    meta: about_45usWjawxlkv4gMeta || {},
    alias: about_45usWjawxlkv4gMeta?.alias || [],
    redirect: about_45usWjawxlkv4gMeta?.redirect || undefined,
    component: () => import("/app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usWjawxlkv4gMeta?.name ?? "about-us___ar",
    path: about_45usWjawxlkv4gMeta?.path ?? "/ar/about-us",
    meta: about_45usWjawxlkv4gMeta || {},
    alias: about_45usWjawxlkv4gMeta?.alias || [],
    redirect: about_45usWjawxlkv4gMeta?.redirect || undefined,
    component: () => import("/app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: apple_45auth_45callbackVlxPGrKvS9Meta?.name ?? "apple-auth-callback___en",
    path: apple_45auth_45callbackVlxPGrKvS9Meta?.path ?? "/en/apple-auth-callback",
    meta: apple_45auth_45callbackVlxPGrKvS9Meta || {},
    alias: apple_45auth_45callbackVlxPGrKvS9Meta?.alias || [],
    redirect: apple_45auth_45callbackVlxPGrKvS9Meta?.redirect || undefined,
    component: () => import("/app/pages/apple-auth-callback.vue").then(m => m.default || m)
  },
  {
    name: apple_45auth_45callbackVlxPGrKvS9Meta?.name ?? "apple-auth-callback___ar",
    path: apple_45auth_45callbackVlxPGrKvS9Meta?.path ?? "/ar/apple-auth-callback",
    meta: apple_45auth_45callbackVlxPGrKvS9Meta || {},
    alias: apple_45auth_45callbackVlxPGrKvS9Meta?.alias || [],
    redirect: apple_45auth_45callbackVlxPGrKvS9Meta?.redirect || undefined,
    component: () => import("/app/pages/apple-auth-callback.vue").then(m => m.default || m)
  },
  {
    name: available_45on9iaFQGc5K0Meta?.name ?? "available-on___en",
    path: available_45on9iaFQGc5K0Meta?.path ?? "/en/available-on",
    meta: available_45on9iaFQGc5K0Meta || {},
    alias: available_45on9iaFQGc5K0Meta?.alias || [],
    redirect: available_45on9iaFQGc5K0Meta?.redirect || undefined,
    component: () => import("/app/pages/available-on.vue").then(m => m.default || m)
  },
  {
    name: available_45on9iaFQGc5K0Meta?.name ?? "available-on___ar",
    path: available_45on9iaFQGc5K0Meta?.path ?? "/ar/available-on",
    meta: available_45on9iaFQGc5K0Meta || {},
    alias: available_45on9iaFQGc5K0Meta?.alias || [],
    redirect: available_45on9iaFQGc5K0Meta?.redirect || undefined,
    component: () => import("/app/pages/available-on.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___en",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/en/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect || undefined,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___ar",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/ar/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect || undefined,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexUAMGQig0OAMeta?.name ?? "create-account___en",
    path: indexUAMGQig0OAMeta?.path ?? "/en/create-account",
    meta: indexUAMGQig0OAMeta || {},
    alias: indexUAMGQig0OAMeta?.alias || [],
    redirect: indexUAMGQig0OAMeta?.redirect || undefined,
    component: () => import("/app/pages/create-account/index.vue").then(m => m.default || m)
  },
  {
    name: indexUAMGQig0OAMeta?.name ?? "create-account___ar",
    path: indexUAMGQig0OAMeta?.path ?? "/ar/create-account",
    meta: indexUAMGQig0OAMeta || {},
    alias: indexUAMGQig0OAMeta?.alias || [],
    redirect: indexUAMGQig0OAMeta?.redirect || undefined,
    component: () => import("/app/pages/create-account/index.vue").then(m => m.default || m)
  },
  {
    name: indexthYsYeqH4nMeta?.name ?? "customize-recommendations___en",
    path: indexthYsYeqH4nMeta?.path ?? "/en/customize-recommendations",
    meta: indexthYsYeqH4nMeta || {},
    alias: indexthYsYeqH4nMeta?.alias || [],
    redirect: indexthYsYeqH4nMeta?.redirect || undefined,
    component: () => import("/app/pages/customize-recommendations/index.vue").then(m => m.default || m)
  },
  {
    name: indexthYsYeqH4nMeta?.name ?? "customize-recommendations___ar",
    path: indexthYsYeqH4nMeta?.path ?? "/ar/customize-recommendations",
    meta: indexthYsYeqH4nMeta || {},
    alias: indexthYsYeqH4nMeta?.alias || [],
    redirect: indexthYsYeqH4nMeta?.redirect || undefined,
    component: () => import("/app/pages/customize-recommendations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zeFsql0QOQMeta?.name ?? "directory-slug___en",
    path: _91slug_93zeFsql0QOQMeta?.path ?? "/en/directory/:slug()",
    meta: _91slug_93zeFsql0QOQMeta || {},
    alias: _91slug_93zeFsql0QOQMeta?.alias || [],
    redirect: _91slug_93zeFsql0QOQMeta?.redirect || undefined,
    component: () => import("/app/pages/directory/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zeFsql0QOQMeta?.name ?? "directory-slug___ar",
    path: _91slug_93zeFsql0QOQMeta?.path ?? "/ar/directory/:slug()",
    meta: _91slug_93zeFsql0QOQMeta || {},
    alias: _91slug_93zeFsql0QOQMeta?.alias || [],
    redirect: _91slug_93zeFsql0QOQMeta?.redirect || undefined,
    component: () => import("/app/pages/directory/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlf1MJmqZz4Meta?.name ?? "empty___en",
    path: indexlf1MJmqZz4Meta?.path ?? "/en/empty",
    meta: indexlf1MJmqZz4Meta || {},
    alias: indexlf1MJmqZz4Meta?.alias || [],
    redirect: indexlf1MJmqZz4Meta?.redirect || undefined,
    component: () => import("/app/pages/empty/index.vue").then(m => m.default || m)
  },
  {
    name: indexlf1MJmqZz4Meta?.name ?? "empty___ar",
    path: indexlf1MJmqZz4Meta?.path ?? "/ar/empty",
    meta: indexlf1MJmqZz4Meta || {},
    alias: indexlf1MJmqZz4Meta?.alias || [],
    redirect: indexlf1MJmqZz4Meta?.redirect || undefined,
    component: () => import("/app/pages/empty/index.vue").then(m => m.default || m)
  },
  {
    name: indexnol22AXlVtMeta?.name ?? "forgot-password___en",
    path: indexnol22AXlVtMeta?.path ?? "/en/forgot-password",
    meta: indexnol22AXlVtMeta || {},
    alias: indexnol22AXlVtMeta?.alias || [],
    redirect: indexnol22AXlVtMeta?.redirect || undefined,
    component: () => import("/app/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexnol22AXlVtMeta?.name ?? "forgot-password___ar",
    path: indexnol22AXlVtMeta?.path ?? "/ar/forgot-password",
    meta: indexnol22AXlVtMeta || {},
    alias: indexnol22AXlVtMeta?.alias || [],
    redirect: indexnol22AXlVtMeta?.redirect || undefined,
    component: () => import("/app/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: _91genre_936k1SyX9Eb9Meta?.name ?? "genres-genre___en",
    path: _91genre_936k1SyX9Eb9Meta?.path ?? "/en/genres/:genre()",
    meta: _91genre_936k1SyX9Eb9Meta || {},
    alias: _91genre_936k1SyX9Eb9Meta?.alias || [],
    redirect: _91genre_936k1SyX9Eb9Meta?.redirect || undefined,
    component: () => import("/app/pages/genres/[genre].vue").then(m => m.default || m)
  },
  {
    name: _91genre_936k1SyX9Eb9Meta?.name ?? "genres-genre___ar",
    path: _91genre_936k1SyX9Eb9Meta?.path ?? "/ar/genres/:genre()",
    meta: _91genre_936k1SyX9Eb9Meta || {},
    alias: _91genre_936k1SyX9Eb9Meta?.alias || [],
    redirect: _91genre_936k1SyX9Eb9Meta?.redirect || undefined,
    component: () => import("/app/pages/genres/[genre].vue").then(m => m.default || m)
  },
  {
    name: _91genre_93ELXpYbi5jzMeta?.name ?? "genres-programs-genre___en",
    path: _91genre_93ELXpYbi5jzMeta?.path ?? "/en/genres/programs/:genre()",
    meta: _91genre_93ELXpYbi5jzMeta || {},
    alias: _91genre_93ELXpYbi5jzMeta?.alias || [],
    redirect: _91genre_93ELXpYbi5jzMeta?.redirect || undefined,
    component: () => import("/app/pages/genres/programs/[genre].vue").then(m => m.default || m)
  },
  {
    name: _91genre_93ELXpYbi5jzMeta?.name ?? "genres-programs-genre___ar",
    path: _91genre_93ELXpYbi5jzMeta?.path ?? "/ar/genres/programs/:genre()",
    meta: _91genre_93ELXpYbi5jzMeta || {},
    alias: _91genre_93ELXpYbi5jzMeta?.alias || [],
    redirect: _91genre_93ELXpYbi5jzMeta?.redirect || undefined,
    component: () => import("/app/pages/genres/programs/[genre].vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___ar",
    path: indexN6pT4Un8hYMeta?.path ?? "/ar",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91category_9347VkXvlbAEMeta?.name ?? "kids-category___en",
    path: _91category_9347VkXvlbAEMeta?.path ?? "/en/kids/:category()",
    meta: _91category_9347VkXvlbAEMeta || {},
    alias: _91category_9347VkXvlbAEMeta?.alias || [],
    redirect: _91category_9347VkXvlbAEMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_9347VkXvlbAEMeta?.name ?? "kids-category___ar",
    path: _91category_9347VkXvlbAEMeta?.path ?? "/ar/kids/:category()",
    meta: _91category_9347VkXvlbAEMeta || {},
    alias: _91category_9347VkXvlbAEMeta?.alias || [],
    redirect: _91category_9347VkXvlbAEMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/[category].vue").then(m => m.default || m)
  },
  {
    name: _91specific_93AltvB2dLEeMeta?.name ?? "kids-characters-specific___en",
    path: _91specific_93AltvB2dLEeMeta?.path ?? "/en/kids/characters/:specific()",
    meta: _91specific_93AltvB2dLEeMeta || {},
    alias: _91specific_93AltvB2dLEeMeta?.alias || [],
    redirect: _91specific_93AltvB2dLEeMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/characters/[specific].vue").then(m => m.default || m)
  },
  {
    name: _91specific_93AltvB2dLEeMeta?.name ?? "kids-characters-specific___ar",
    path: _91specific_93AltvB2dLEeMeta?.path ?? "/ar/kids/characters/:specific()",
    meta: _91specific_93AltvB2dLEeMeta || {},
    alias: _91specific_93AltvB2dLEeMeta?.alias || [],
    redirect: _91specific_93AltvB2dLEeMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/characters/[specific].vue").then(m => m.default || m)
  },
  {
    name: index2pvzlVg3MKMeta?.name ?? "kids-characters___en",
    path: index2pvzlVg3MKMeta?.path ?? "/en/kids/characters",
    meta: index2pvzlVg3MKMeta || {},
    alias: index2pvzlVg3MKMeta?.alias || [],
    redirect: index2pvzlVg3MKMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/characters/index.vue").then(m => m.default || m)
  },
  {
    name: index2pvzlVg3MKMeta?.name ?? "kids-characters___ar",
    path: index2pvzlVg3MKMeta?.path ?? "/ar/kids/characters",
    meta: index2pvzlVg3MKMeta || {},
    alias: index2pvzlVg3MKMeta?.alias || [],
    redirect: index2pvzlVg3MKMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/characters/index.vue").then(m => m.default || m)
  },
  {
    name: indexkjTLcvAO5aMeta?.name ?? "kids___en",
    path: indexkjTLcvAO5aMeta?.path ?? "/en/kids",
    meta: indexkjTLcvAO5aMeta || {},
    alias: indexkjTLcvAO5aMeta?.alias || [],
    redirect: indexkjTLcvAO5aMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/index.vue").then(m => m.default || m)
  },
  {
    name: indexkjTLcvAO5aMeta?.name ?? "kids___ar",
    path: indexkjTLcvAO5aMeta?.path ?? "/ar/kids",
    meta: indexkjTLcvAO5aMeta || {},
    alias: indexkjTLcvAO5aMeta?.alias || [],
    redirect: indexkjTLcvAO5aMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/index.vue").then(m => m.default || m)
  },
  {
    name: searchqJXpayBFGYMeta?.name ?? "kids-search___en",
    path: searchqJXpayBFGYMeta?.path ?? "/en/kids/search",
    meta: searchqJXpayBFGYMeta || {},
    alias: searchqJXpayBFGYMeta?.alias || [],
    redirect: searchqJXpayBFGYMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/search.vue").then(m => m.default || m)
  },
  {
    name: searchqJXpayBFGYMeta?.name ?? "kids-search___ar",
    path: searchqJXpayBFGYMeta?.path ?? "/ar/kids/search",
    meta: searchqJXpayBFGYMeta || {},
    alias: searchqJXpayBFGYMeta?.alias || [],
    redirect: searchqJXpayBFGYMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/search.vue").then(m => m.default || m)
  },
  {
    name: songslrOJrIMMJsMeta?.name ?? "kids-songs___en",
    path: songslrOJrIMMJsMeta?.path ?? "/en/kids/songs",
    meta: songslrOJrIMMJsMeta || {},
    alias: songslrOJrIMMJsMeta?.alias || [],
    redirect: songslrOJrIMMJsMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/songs.vue").then(m => m.default || m)
  },
  {
    name: songslrOJrIMMJsMeta?.name ?? "kids-songs___ar",
    path: songslrOJrIMMJsMeta?.path ?? "/ar/kids/songs",
    meta: songslrOJrIMMJsMeta || {},
    alias: songslrOJrIMMJsMeta?.alias || [],
    redirect: songslrOJrIMMJsMeta?.redirect || undefined,
    component: () => import("/app/pages/kids/songs.vue").then(m => m.default || m)
  },
  {
    name: indexIf7CFQYCebMeta?.name ?? "link-tv___en",
    path: indexIf7CFQYCebMeta?.path ?? "/en/link-tv",
    meta: indexIf7CFQYCebMeta || {},
    alias: indexIf7CFQYCebMeta?.alias || [],
    redirect: indexIf7CFQYCebMeta?.redirect || undefined,
    component: () => import("/app/pages/link-tv/index.vue").then(m => m.default || m)
  },
  {
    name: indexIf7CFQYCebMeta?.name ?? "link-tv___ar",
    path: indexIf7CFQYCebMeta?.path ?? "/ar/link-tv",
    meta: indexIf7CFQYCebMeta || {},
    alias: indexIf7CFQYCebMeta?.alias || [],
    redirect: indexIf7CFQYCebMeta?.redirect || undefined,
    component: () => import("/app/pages/link-tv/index.vue").then(m => m.default || m)
  },
  {
    name: _91channel_93LShsme7k7hMeta?.name ?? "live-channel___en",
    path: _91channel_93LShsme7k7hMeta?.path ?? "/en/live/:channel()",
    meta: _91channel_93LShsme7k7hMeta || {},
    alias: _91channel_93LShsme7k7hMeta?.alias || [],
    redirect: _91channel_93LShsme7k7hMeta?.redirect || undefined,
    component: () => import("/app/pages/live/[channel].vue").then(m => m.default || m)
  },
  {
    name: _91channel_93LShsme7k7hMeta?.name ?? "live-channel___ar",
    path: _91channel_93LShsme7k7hMeta?.path ?? "/ar/live/:channel()",
    meta: _91channel_93LShsme7k7hMeta || {},
    alias: _91channel_93LShsme7k7hMeta?.alias || [],
    redirect: _91channel_93LShsme7k7hMeta?.redirect || undefined,
    component: () => import("/app/pages/live/[channel].vue").then(m => m.default || m)
  },
  {
    name: index4SOLgZssGlMeta?.name ?? "live___en",
    path: index4SOLgZssGlMeta?.path ?? "/en/live",
    meta: index4SOLgZssGlMeta || {},
    alias: index4SOLgZssGlMeta?.alias || [],
    redirect: index4SOLgZssGlMeta?.redirect || undefined,
    component: () => import("/app/pages/live/index.vue").then(m => m.default || m)
  },
  {
    name: index4SOLgZssGlMeta?.name ?? "live___ar",
    path: index4SOLgZssGlMeta?.path ?? "/ar/live",
    meta: index4SOLgZssGlMeta || {},
    alias: index4SOLgZssGlMeta?.alias || [],
    redirect: index4SOLgZssGlMeta?.redirect || undefined,
    component: () => import("/app/pages/live/index.vue").then(m => m.default || m)
  },
  {
    name: codeuegJusolFcMeta?.name ?? "login-code___en",
    path: codeuegJusolFcMeta?.path ?? "/en/login/code",
    meta: codeuegJusolFcMeta || {},
    alias: codeuegJusolFcMeta?.alias || [],
    redirect: codeuegJusolFcMeta?.redirect || undefined,
    component: () => import("/app/pages/login/code.vue").then(m => m.default || m)
  },
  {
    name: codeuegJusolFcMeta?.name ?? "login-code___ar",
    path: codeuegJusolFcMeta?.path ?? "/ar/login/code",
    meta: codeuegJusolFcMeta || {},
    alias: codeuegJusolFcMeta?.alias || [],
    redirect: codeuegJusolFcMeta?.redirect || undefined,
    component: () => import("/app/pages/login/code.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login___en",
    path: indexgK5VicK7y2Meta?.path ?? "/en/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect || undefined,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login___ar",
    path: indexgK5VicK7y2Meta?.path ?? "/ar/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect || undefined,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93G0uRFSuz9LMeta?.name ?? "login-m-code___en",
    path: _91code_93G0uRFSuz9LMeta?.path ?? "/en/login/m/:code()",
    meta: _91code_93G0uRFSuz9LMeta || {},
    alias: _91code_93G0uRFSuz9LMeta?.alias || [],
    redirect: _91code_93G0uRFSuz9LMeta?.redirect || undefined,
    component: () => import("/app/pages/login/m/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93G0uRFSuz9LMeta?.name ?? "login-m-code___ar",
    path: _91code_93G0uRFSuz9LMeta?.path ?? "/ar/login/m/:code()",
    meta: _91code_93G0uRFSuz9LMeta || {},
    alias: _91code_93G0uRFSuz9LMeta?.alias || [],
    redirect: _91code_93G0uRFSuz9LMeta?.redirect || undefined,
    component: () => import("/app/pages/login/m/[code].vue").then(m => m.default || m)
  },
  {
    name: _91media_93eal1tiUQidMeta?.name ?? "media-media___en",
    path: _91media_93eal1tiUQidMeta?.path ?? "/en/media/:media()",
    meta: _91media_93eal1tiUQidMeta || {},
    alias: _91media_93eal1tiUQidMeta?.alias || [],
    redirect: _91media_93eal1tiUQidMeta?.redirect || undefined,
    component: () => import("/app/pages/media/[media].vue").then(m => m.default || m)
  },
  {
    name: _91media_93eal1tiUQidMeta?.name ?? "media-media___ar",
    path: _91media_93eal1tiUQidMeta?.path ?? "/ar/media/:media()",
    meta: _91media_93eal1tiUQidMeta || {},
    alias: _91media_93eal1tiUQidMeta?.alias || [],
    redirect: _91media_93eal1tiUQidMeta?.redirect || undefined,
    component: () => import("/app/pages/media/[media].vue").then(m => m.default || m)
  },
  {
    name: indexUDRirzcz0HMeta?.name ?? "movies-movieId-slug___en",
    path: indexUDRirzcz0HMeta?.path ?? "/en/movies/:movieId()/:slug()",
    meta: indexUDRirzcz0HMeta || {},
    alias: indexUDRirzcz0HMeta?.alias || [],
    redirect: indexUDRirzcz0HMeta?.redirect || undefined,
    component: () => import("/app/pages/movies/[movieId]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUDRirzcz0HMeta?.name ?? "movies-movieId-slug___ar",
    path: indexUDRirzcz0HMeta?.path ?? "/ar/movies/:movieId()/:slug()",
    meta: indexUDRirzcz0HMeta || {},
    alias: indexUDRirzcz0HMeta?.alias || [],
    redirect: indexUDRirzcz0HMeta?.redirect || undefined,
    component: () => import("/app/pages/movies/[movieId]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: watchXcehkaRQ9KMeta?.name ?? "movies-movieId-slug-watch___en",
    path: watchXcehkaRQ9KMeta?.path ?? "/en/movies/:movieId()/:slug()/watch",
    meta: watchXcehkaRQ9KMeta || {},
    alias: watchXcehkaRQ9KMeta?.alias || [],
    redirect: watchXcehkaRQ9KMeta?.redirect || undefined,
    component: () => import("/app/pages/movies/[movieId]/[slug]/watch.vue").then(m => m.default || m)
  },
  {
    name: watchXcehkaRQ9KMeta?.name ?? "movies-movieId-slug-watch___ar",
    path: watchXcehkaRQ9KMeta?.path ?? "/ar/movies/:movieId()/:slug()/watch",
    meta: watchXcehkaRQ9KMeta || {},
    alias: watchXcehkaRQ9KMeta?.alias || [],
    redirect: watchXcehkaRQ9KMeta?.redirect || undefined,
    component: () => import("/app/pages/movies/[movieId]/[slug]/watch.vue").then(m => m.default || m)
  },
  {
    name: indexHmkOTh6ZqWMeta?.name ?? "my-list___en",
    path: indexHmkOTh6ZqWMeta?.path ?? "/en/my-list",
    meta: indexHmkOTh6ZqWMeta || {},
    alias: indexHmkOTh6ZqWMeta?.alias || [],
    redirect: indexHmkOTh6ZqWMeta?.redirect || undefined,
    component: () => import("/app/pages/my-list/index.vue").then(m => m.default || m)
  },
  {
    name: indexHmkOTh6ZqWMeta?.name ?? "my-list___ar",
    path: indexHmkOTh6ZqWMeta?.path ?? "/ar/my-list",
    meta: indexHmkOTh6ZqWMeta || {},
    alias: indexHmkOTh6ZqWMeta?.alias || [],
    redirect: indexHmkOTh6ZqWMeta?.redirect || undefined,
    component: () => import("/app/pages/my-list/index.vue").then(m => m.default || m)
  },
  {
    name: set6s6sMpS2zYMeta?.name ?? "parental-code-set___en",
    path: set6s6sMpS2zYMeta?.path ?? "/en/parental-code/set",
    meta: set6s6sMpS2zYMeta || {},
    alias: set6s6sMpS2zYMeta?.alias || [],
    redirect: set6s6sMpS2zYMeta?.redirect || undefined,
    component: () => import("/app/pages/parental-code/set.vue").then(m => m.default || m)
  },
  {
    name: set6s6sMpS2zYMeta?.name ?? "parental-code-set___ar",
    path: set6s6sMpS2zYMeta?.path ?? "/ar/parental-code/set",
    meta: set6s6sMpS2zYMeta || {},
    alias: set6s6sMpS2zYMeta?.alias || [],
    redirect: set6s6sMpS2zYMeta?.redirect || undefined,
    component: () => import("/app/pages/parental-code/set.vue").then(m => m.default || m)
  },
  {
    name: privacyg3gwBRGXatMeta?.name ?? "privacy___en",
    path: privacyg3gwBRGXatMeta?.path ?? "/en/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    alias: privacyg3gwBRGXatMeta?.alias || [],
    redirect: privacyg3gwBRGXatMeta?.redirect || undefined,
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyg3gwBRGXatMeta?.name ?? "privacy___ar",
    path: privacyg3gwBRGXatMeta?.path ?? "/ar/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    alias: privacyg3gwBRGXatMeta?.alias || [],
    redirect: privacyg3gwBRGXatMeta?.redirect || undefined,
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexBqiuZpk0bsMeta?.name ?? "profiles___en",
    path: indexBqiuZpk0bsMeta?.path ?? "/en/profiles",
    meta: indexBqiuZpk0bsMeta || {},
    alias: indexBqiuZpk0bsMeta?.alias || [],
    redirect: indexBqiuZpk0bsMeta?.redirect || undefined,
    component: () => import("/app/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: indexBqiuZpk0bsMeta?.name ?? "profiles___ar",
    path: indexBqiuZpk0bsMeta?.path ?? "/ar/profiles",
    meta: indexBqiuZpk0bsMeta || {},
    alias: indexBqiuZpk0bsMeta?.alias || [],
    redirect: indexBqiuZpk0bsMeta?.redirect || undefined,
    component: () => import("/app/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: indexeV3TMC4AIXMeta?.name ?? "radio___en",
    path: indexeV3TMC4AIXMeta?.path ?? "/en/radio",
    meta: indexeV3TMC4AIXMeta || {},
    alias: indexeV3TMC4AIXMeta?.alias || [],
    redirect: indexeV3TMC4AIXMeta?.redirect || undefined,
    component: () => import("/app/pages/radio/index.vue").then(m => m.default || m)
  },
  {
    name: indexeV3TMC4AIXMeta?.name ?? "radio___ar",
    path: indexeV3TMC4AIXMeta?.path ?? "/ar/radio",
    meta: indexeV3TMC4AIXMeta || {},
    alias: indexeV3TMC4AIXMeta?.alias || [],
    redirect: indexeV3TMC4AIXMeta?.redirect || undefined,
    component: () => import("/app/pages/radio/index.vue").then(m => m.default || m)
  },
  {
    name: _91blend_93zfYCIZ9IxDMeta?.name ?? "schedule-blend___en",
    path: _91blend_93zfYCIZ9IxDMeta?.path ?? "/en/schedule/:blend()",
    meta: _91blend_93zfYCIZ9IxDMeta || {},
    alias: _91blend_93zfYCIZ9IxDMeta?.alias || [],
    redirect: _91blend_93zfYCIZ9IxDMeta?.redirect || undefined,
    component: () => import("/app/pages/schedule/[blend].vue").then(m => m.default || m)
  },
  {
    name: _91blend_93zfYCIZ9IxDMeta?.name ?? "schedule-blend___ar",
    path: _91blend_93zfYCIZ9IxDMeta?.path ?? "/ar/schedule/:blend()",
    meta: _91blend_93zfYCIZ9IxDMeta || {},
    alias: _91blend_93zfYCIZ9IxDMeta?.alias || [],
    redirect: _91blend_93zfYCIZ9IxDMeta?.redirect || undefined,
    component: () => import("/app/pages/schedule/[blend].vue").then(m => m.default || m)
  },
  {
    name: indexP0q8KYV1WMMeta?.name ?? "search___en",
    path: indexP0q8KYV1WMMeta?.path ?? "/en/search",
    meta: indexP0q8KYV1WMMeta || {},
    alias: indexP0q8KYV1WMMeta?.alias || [],
    redirect: indexP0q8KYV1WMMeta?.redirect || undefined,
    component: () => import("/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexP0q8KYV1WMMeta?.name ?? "search___ar",
    path: indexP0q8KYV1WMMeta?.path ?? "/ar/search",
    meta: indexP0q8KYV1WMMeta || {},
    alias: indexP0q8KYV1WMMeta?.alias || [],
    redirect: indexP0q8KYV1WMMeta?.redirect || undefined,
    component: () => import("/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: songs_45playerwAthZvfkenMeta?.name ?? "search-songs-player___en",
    path: songs_45playerwAthZvfkenMeta?.path ?? "/en/search/songs-player",
    meta: songs_45playerwAthZvfkenMeta || {},
    alias: songs_45playerwAthZvfkenMeta?.alias || [],
    redirect: songs_45playerwAthZvfkenMeta?.redirect || undefined,
    component: () => import("/app/pages/search/songs-player.vue").then(m => m.default || m)
  },
  {
    name: songs_45playerwAthZvfkenMeta?.name ?? "search-songs-player___ar",
    path: songs_45playerwAthZvfkenMeta?.path ?? "/ar/search/songs-player",
    meta: songs_45playerwAthZvfkenMeta || {},
    alias: songs_45playerwAthZvfkenMeta?.alias || [],
    redirect: songs_45playerwAthZvfkenMeta?.redirect || undefined,
    component: () => import("/app/pages/search/songs-player.vue").then(m => m.default || m)
  },
  {
    name: index11eXGkSJBcMeta?.name ?? "settings___en",
    path: index11eXGkSJBcMeta?.path ?? "/en/settings",
    meta: index11eXGkSJBcMeta || {},
    alias: index11eXGkSJBcMeta?.alias || [],
    redirect: index11eXGkSJBcMeta?.redirect || undefined,
    component: () => import("/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: index11eXGkSJBcMeta?.name ?? "settings___ar",
    path: index11eXGkSJBcMeta?.path ?? "/ar/settings",
    meta: index11eXGkSJBcMeta || {},
    alias: index11eXGkSJBcMeta?.alias || [],
    redirect: index11eXGkSJBcMeta?.redirect || undefined,
    component: () => import("/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexbxuCleBA4aMeta?.name ?? "show-id-name___en",
    path: indexbxuCleBA4aMeta?.path ?? "/en/show/:id()/:name()",
    meta: indexbxuCleBA4aMeta || {},
    alias: indexbxuCleBA4aMeta?.alias || [],
    redirect: indexbxuCleBA4aMeta?.redirect || undefined,
    component: () => import("/app/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbxuCleBA4aMeta?.name ?? "show-id-name___ar",
    path: indexbxuCleBA4aMeta?.path ?? "/ar/show/:id()/:name()",
    meta: indexbxuCleBA4aMeta || {},
    alias: indexbxuCleBA4aMeta?.alias || [],
    redirect: indexbxuCleBA4aMeta?.redirect || undefined,
    component: () => import("/app/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNB8pX1Gns9Meta?.name ?? "shows-seriesId-slug-seasonNumber-episodeNumber___en",
    path: indexNB8pX1Gns9Meta?.path ?? "/en/shows/:seriesId()/:slug()/:seasonNumber()/:episodeNumber()",
    meta: indexNB8pX1Gns9Meta || {},
    alias: indexNB8pX1Gns9Meta?.alias || [],
    redirect: indexNB8pX1Gns9Meta?.redirect || undefined,
    component: () => import("/app/pages/shows/[seriesId]/[slug]/[seasonNumber]/[episodeNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNB8pX1Gns9Meta?.name ?? "shows-seriesId-slug-seasonNumber-episodeNumber___ar",
    path: indexNB8pX1Gns9Meta?.path ?? "/ar/shows/:seriesId()/:slug()/:seasonNumber()/:episodeNumber()",
    meta: indexNB8pX1Gns9Meta || {},
    alias: indexNB8pX1Gns9Meta?.alias || [],
    redirect: indexNB8pX1Gns9Meta?.redirect || undefined,
    component: () => import("/app/pages/shows/[seriesId]/[slug]/[seasonNumber]/[episodeNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: index66F3lEFOtzMeta?.name ?? "shows-seriesId___en",
    path: index66F3lEFOtzMeta?.path ?? "/en/shows/:seriesId()",
    meta: index66F3lEFOtzMeta || {},
    alias: index66F3lEFOtzMeta?.alias || [],
    redirect: index66F3lEFOtzMeta?.redirect || undefined,
    component: () => import("/app/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: index66F3lEFOtzMeta?.name ?? "shows-seriesId___ar",
    path: index66F3lEFOtzMeta?.path ?? "/ar/shows/:seriesId()",
    meta: index66F3lEFOtzMeta || {},
    alias: index66F3lEFOtzMeta?.alias || [],
    redirect: index66F3lEFOtzMeta?.redirect || undefined,
    component: () => import("/app/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUxXYbvqp06Meta?.name ?? "sign-in___en",
    path: indexUxXYbvqp06Meta?.path ?? "/en/sign-in",
    meta: indexUxXYbvqp06Meta || {},
    alias: indexUxXYbvqp06Meta?.alias || [],
    redirect: indexUxXYbvqp06Meta?.redirect || undefined,
    component: () => import("/app/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexUxXYbvqp06Meta?.name ?? "sign-in___ar",
    path: indexUxXYbvqp06Meta?.path ?? "/ar/sign-in",
    meta: indexUxXYbvqp06Meta || {},
    alias: indexUxXYbvqp06Meta?.alias || [],
    redirect: indexUxXYbvqp06Meta?.redirect || undefined,
    component: () => import("/app/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: index920Gn3sCkQMeta?.name ?? "sports-categories___en",
    path: index920Gn3sCkQMeta?.path ?? "/en/sports/categories",
    meta: index920Gn3sCkQMeta || {},
    alias: index920Gn3sCkQMeta?.alias || [],
    redirect: index920Gn3sCkQMeta?.redirect || undefined,
    component: () => import("/app/pages/sports/categories/index.vue").then(m => m.default || m)
  },
  {
    name: index920Gn3sCkQMeta?.name ?? "sports-categories___ar",
    path: index920Gn3sCkQMeta?.path ?? "/ar/sports/categories",
    meta: index920Gn3sCkQMeta || {},
    alias: index920Gn3sCkQMeta?.alias || [],
    redirect: index920Gn3sCkQMeta?.redirect || undefined,
    component: () => import("/app/pages/sports/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexRFa2z0Ga1bMeta?.name ?? "sports___en",
    path: indexRFa2z0Ga1bMeta?.path ?? "/en/sports",
    meta: indexRFa2z0Ga1bMeta || {},
    alias: indexRFa2z0Ga1bMeta?.alias || [],
    redirect: indexRFa2z0Ga1bMeta?.redirect || undefined,
    component: () => import("/app/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: indexRFa2z0Ga1bMeta?.name ?? "sports___ar",
    path: indexRFa2z0Ga1bMeta?.path ?? "/ar/sports",
    meta: indexRFa2z0Ga1bMeta || {},
    alias: indexRFa2z0Ga1bMeta?.alias || [],
    redirect: indexRFa2z0Ga1bMeta?.redirect || undefined,
    component: () => import("/app/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: statusAyOFU8xai2Meta?.name ?? "status___en",
    path: statusAyOFU8xai2Meta?.path ?? "/en/status",
    meta: statusAyOFU8xai2Meta || {},
    alias: statusAyOFU8xai2Meta?.alias || [],
    redirect: statusAyOFU8xai2Meta?.redirect || undefined,
    component: () => import("/app/pages/status.vue").then(m => m.default || m)
  },
  {
    name: statusAyOFU8xai2Meta?.name ?? "status___ar",
    path: statusAyOFU8xai2Meta?.path ?? "/ar/status",
    meta: statusAyOFU8xai2Meta || {},
    alias: statusAyOFU8xai2Meta?.alias || [],
    redirect: statusAyOFU8xai2Meta?.redirect || undefined,
    component: () => import("/app/pages/status.vue").then(m => m.default || m)
  },
  {
    name: termsy18ZESLqu6Meta?.name ?? "terms___en",
    path: termsy18ZESLqu6Meta?.path ?? "/en/terms",
    meta: termsy18ZESLqu6Meta || {},
    alias: termsy18ZESLqu6Meta?.alias || [],
    redirect: termsy18ZESLqu6Meta?.redirect || undefined,
    component: () => import("/app/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: termsy18ZESLqu6Meta?.name ?? "terms___ar",
    path: termsy18ZESLqu6Meta?.path ?? "/ar/terms",
    meta: termsy18ZESLqu6Meta || {},
    alias: termsy18ZESLqu6Meta?.alias || [],
    redirect: termsy18ZESLqu6Meta?.redirect || undefined,
    component: () => import("/app/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: _91theme_936oi2RU3tXRMeta?.name ?? "theme-theme___en",
    path: _91theme_936oi2RU3tXRMeta?.path ?? "/en/theme/:theme()",
    meta: _91theme_936oi2RU3tXRMeta || {},
    alias: _91theme_936oi2RU3tXRMeta?.alias || [],
    redirect: _91theme_936oi2RU3tXRMeta?.redirect || undefined,
    component: () => import("/app/pages/theme/[theme].vue").then(m => m.default || m)
  },
  {
    name: _91theme_936oi2RU3tXRMeta?.name ?? "theme-theme___ar",
    path: _91theme_936oi2RU3tXRMeta?.path ?? "/ar/theme/:theme()",
    meta: _91theme_936oi2RU3tXRMeta || {},
    alias: _91theme_936oi2RU3tXRMeta?.alias || [],
    redirect: _91theme_936oi2RU3tXRMeta?.redirect || undefined,
    component: () => import("/app/pages/theme/[theme].vue").then(m => m.default || m)
  },
  {
    name: indexXWzoKrzl7TMeta?.name ?? "watch-external___en",
    path: indexXWzoKrzl7TMeta?.path ?? "/en/watch/external",
    meta: indexXWzoKrzl7TMeta || {},
    alias: indexXWzoKrzl7TMeta?.alias || [],
    redirect: indexXWzoKrzl7TMeta?.redirect || undefined,
    component: () => import("/app/pages/watch/external/index.vue").then(m => m.default || m)
  },
  {
    name: indexXWzoKrzl7TMeta?.name ?? "watch-external___ar",
    path: indexXWzoKrzl7TMeta?.path ?? "/ar/watch/external",
    meta: indexXWzoKrzl7TMeta || {},
    alias: indexXWzoKrzl7TMeta?.alias || [],
    redirect: indexXWzoKrzl7TMeta?.redirect || undefined,
    component: () => import("/app/pages/watch/external/index.vue").then(m => m.default || m)
  },
  {
    name: indexTYYLLz16yeMeta?.name ?? "watch___en",
    path: indexTYYLLz16yeMeta?.path ?? "/en/watch",
    meta: indexTYYLLz16yeMeta || {},
    alias: indexTYYLLz16yeMeta?.alias || [],
    redirect: indexTYYLLz16yeMeta?.redirect || undefined,
    component: () => import("/app/pages/watch/index.vue").then(m => m.default || m)
  },
  {
    name: indexTYYLLz16yeMeta?.name ?? "watch___ar",
    path: indexTYYLLz16yeMeta?.path ?? "/ar/watch",
    meta: indexTYYLLz16yeMeta || {},
    alias: indexTYYLLz16yeMeta?.alias || [],
    redirect: indexTYYLLz16yeMeta?.redirect || undefined,
    component: () => import("/app/pages/watch/index.vue").then(m => m.default || m)
  },
  {
    name: _91channel_93STOk3oQEbyMeta?.name ?? "watch-live-channel___en",
    path: _91channel_93STOk3oQEbyMeta?.path ?? "/en/watch/live/:channel()",
    meta: _91channel_93STOk3oQEbyMeta || {},
    alias: _91channel_93STOk3oQEbyMeta?.alias || [],
    redirect: _91channel_93STOk3oQEbyMeta?.redirect || undefined,
    component: () => import("/app/pages/watch/live/[channel].vue").then(m => m.default || m)
  },
  {
    name: _91channel_93STOk3oQEbyMeta?.name ?? "watch-live-channel___ar",
    path: _91channel_93STOk3oQEbyMeta?.path ?? "/ar/watch/live/:channel()",
    meta: _91channel_93STOk3oQEbyMeta || {},
    alias: _91channel_93STOk3oQEbyMeta?.alias || [],
    redirect: _91channel_93STOk3oQEbyMeta?.redirect || undefined,
    component: () => import("/app/pages/watch/live/[channel].vue").then(m => m.default || m)
  },
  {
    name: _91channel_93UkElexmNXdMeta?.name ?? "Majid-tv___en",
    path: _91channel_93UkElexmNXdMeta?.path ?? "/en/kids/majid-tv",
    meta: _91channel_93UkElexmNXdMeta || {},
    alias: _91channel_93UkElexmNXdMeta?.alias || [],
    redirect: _91channel_93UkElexmNXdMeta?.redirect || undefined,
    component: () => import("@/pages/live/[channel].vue").then(m => m.default || m)
  },
  {
    name: _91channel_93UkElexmNXdMeta?.name ?? "Majid-tv___ar",
    path: _91channel_93UkElexmNXdMeta?.path ?? "/ar/kids/majid-tv",
    meta: _91channel_93UkElexmNXdMeta || {},
    alias: _91channel_93UkElexmNXdMeta?.alias || [],
    redirect: _91channel_93UkElexmNXdMeta?.redirect || undefined,
    component: () => import("@/pages/live/[channel].vue").then(m => m.default || m)
  },
  {
    name: _91theme_93UX6rTu0AAAMeta?.name ?? "Sports___en",
    path: _91theme_93UX6rTu0AAAMeta?.path ?? "/en/sports/categories/:theme",
    meta: _91theme_93UX6rTu0AAAMeta || {},
    alias: _91theme_93UX6rTu0AAAMeta?.alias || [],
    redirect: _91theme_93UX6rTu0AAAMeta?.redirect || undefined,
    component: () => import("@/pages/theme/[theme].vue").then(m => m.default || m)
  },
  {
    name: _91theme_93UX6rTu0AAAMeta?.name ?? "Sports___ar",
    path: _91theme_93UX6rTu0AAAMeta?.path ?? "/ar/sports/categories/:theme",
    meta: _91theme_93UX6rTu0AAAMeta || {},
    alias: _91theme_93UX6rTu0AAAMeta?.alias || [],
    redirect: _91theme_93UX6rTu0AAAMeta?.redirect || undefined,
    component: () => import("@/pages/theme/[theme].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lkCIggjiATMeta?.name ?? "Sports - carousel all items___en",
    path: _91slug_93lkCIggjiATMeta?.path ?? "/en/sports/all/:slug",
    meta: _91slug_93lkCIggjiATMeta || {},
    alias: _91slug_93lkCIggjiATMeta?.alias || [],
    redirect: _91slug_93lkCIggjiATMeta?.redirect || undefined,
    component: () => import("@/pages/directory/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lkCIggjiATMeta?.name ?? "Sports - carousel all items___ar",
    path: _91slug_93lkCIggjiATMeta?.path ?? "/ar/sports/all/:slug",
    meta: _91slug_93lkCIggjiATMeta || {},
    alias: _91slug_93lkCIggjiATMeta?.alias || [],
    redirect: _91slug_93lkCIggjiATMeta?.redirect || undefined,
    component: () => import("@/pages/directory/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexPsJtbitOOPMeta?.name ?? "Kids - wishlist___en",
    path: indexPsJtbitOOPMeta?.path ?? "/en/kids/my-list",
    meta: indexPsJtbitOOPMeta || {},
    alias: indexPsJtbitOOPMeta?.alias || [],
    redirect: indexPsJtbitOOPMeta?.redirect || undefined,
    component: () => import("@/pages/my-list/index.vue").then(m => m.default || m)
  },
  {
    name: indexPsJtbitOOPMeta?.name ?? "Kids - wishlist___ar",
    path: indexPsJtbitOOPMeta?.path ?? "/ar/kids/my-list",
    meta: indexPsJtbitOOPMeta || {},
    alias: indexPsJtbitOOPMeta?.alias || [],
    redirect: indexPsJtbitOOPMeta?.redirect || undefined,
    component: () => import("@/pages/my-list/index.vue").then(m => m.default || m)
  },
  {
    name: _91blend_93offzd5Va8rMeta?.name ?? "Guide___en",
    path: _91blend_93offzd5Va8rMeta?.path ?? "/en/all-channels",
    meta: _91blend_93offzd5Va8rMeta || {},
    alias: _91blend_93offzd5Va8rMeta?.alias || [],
    redirect: _91blend_93offzd5Va8rMeta?.redirect || undefined,
    component: () => import("@/pages/schedule/[blend].vue").then(m => m.default || m)
  },
  {
    name: _91blend_93offzd5Va8rMeta?.name ?? "Guide___ar",
    path: _91blend_93offzd5Va8rMeta?.path ?? "/ar/all-channels",
    meta: _91blend_93offzd5Va8rMeta || {},
    alias: _91blend_93offzd5Va8rMeta?.alias || [],
    redirect: _91blend_93offzd5Va8rMeta?.redirect || undefined,
    component: () => import("@/pages/schedule/[blend].vue").then(m => m.default || m)
  },
  {
    name: songs_45player4ggZ0DKNRcMeta?.name ?? "Kids - Search - Song player___en",
    path: songs_45player4ggZ0DKNRcMeta?.path ?? "/en/kids/search/songs-player",
    meta: songs_45player4ggZ0DKNRcMeta || {},
    alias: songs_45player4ggZ0DKNRcMeta?.alias || [],
    redirect: songs_45player4ggZ0DKNRcMeta?.redirect || undefined,
    component: () => import("@/pages/search/songs-player.vue").then(m => m.default || m)
  },
  {
    name: songs_45player4ggZ0DKNRcMeta?.name ?? "Kids - Search - Song player___ar",
    path: songs_45player4ggZ0DKNRcMeta?.path ?? "/ar/kids/search/songs-player",
    meta: songs_45player4ggZ0DKNRcMeta || {},
    alias: songs_45player4ggZ0DKNRcMeta?.alias || [],
    redirect: songs_45player4ggZ0DKNRcMeta?.redirect || undefined,
    component: () => import("@/pages/search/songs-player.vue").then(m => m.default || m)
  },
  {
    name: songsUMo5k3lmgUMeta?.name ?? "Kids - Songs - Song___en",
    path: songsUMo5k3lmgUMeta?.path ?? "/en/kids/songs/:songID/:songName",
    meta: songsUMo5k3lmgUMeta || {},
    alias: songsUMo5k3lmgUMeta?.alias || [],
    redirect: songsUMo5k3lmgUMeta?.redirect || undefined,
    component: () => import("@/pages/kids/songs.vue").then(m => m.default || m)
  },
  {
    name: songsUMo5k3lmgUMeta?.name ?? "Kids - Songs - Song___ar",
    path: songsUMo5k3lmgUMeta?.path ?? "/ar/kids/songs/:songID/:songName",
    meta: songsUMo5k3lmgUMeta || {},
    alias: songsUMo5k3lmgUMeta?.alias || [],
    redirect: songsUMo5k3lmgUMeta?.redirect || undefined,
    component: () => import("@/pages/kids/songs.vue").then(m => m.default || m)
  },
  {
    name: _91blend_93offzd5Va8rMeta?.name ?? "Kids - Majid-tv - Guide___en",
    path: _91blend_93offzd5Va8rMeta?.path ?? "/en/kids/schedule/:blend",
    meta: _91blend_93offzd5Va8rMeta || {},
    alias: _91blend_93offzd5Va8rMeta?.alias || [],
    redirect: _91blend_93offzd5Va8rMeta?.redirect || undefined,
    component: () => import("@/pages/schedule/[blend].vue").then(m => m.default || m)
  },
  {
    name: _91blend_93offzd5Va8rMeta?.name ?? "Kids - Majid-tv - Guide___ar",
    path: _91blend_93offzd5Va8rMeta?.path ?? "/ar/kids/schedule/:blend",
    meta: _91blend_93offzd5Va8rMeta || {},
    alias: _91blend_93offzd5Va8rMeta?.alias || [],
    redirect: _91blend_93offzd5Va8rMeta?.redirect || undefined,
    component: () => import("@/pages/schedule/[blend].vue").then(m => m.default || m)
  },
  {
    name: termsEdpqwMtXY6Meta?.name ?? "Terms___en",
    path: termsEdpqwMtXY6Meta?.path ?? "/en/static/Terms",
    meta: termsEdpqwMtXY6Meta || {},
    alias: termsEdpqwMtXY6Meta?.alias || [],
    redirect: termsEdpqwMtXY6Meta?.redirect || undefined,
    component: () => import("@/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: termsEdpqwMtXY6Meta?.name ?? "Terms___ar",
    path: termsEdpqwMtXY6Meta?.path ?? "/ar/static/Terms",
    meta: termsEdpqwMtXY6Meta || {},
    alias: termsEdpqwMtXY6Meta?.alias || [],
    redirect: termsEdpqwMtXY6Meta?.redirect || undefined,
    component: () => import("@/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: privacyaHMMFDBYv5Meta?.name ?? "Privacy___en",
    path: privacyaHMMFDBYv5Meta?.path ?? "/en/static/Privacy",
    meta: privacyaHMMFDBYv5Meta || {},
    alias: privacyaHMMFDBYv5Meta?.alias || [],
    redirect: privacyaHMMFDBYv5Meta?.redirect || undefined,
    component: () => import("@/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaHMMFDBYv5Meta?.name ?? "Privacy___ar",
    path: privacyaHMMFDBYv5Meta?.path ?? "/ar/static/Privacy",
    meta: privacyaHMMFDBYv5Meta || {},
    alias: privacyaHMMFDBYv5Meta?.alias || [],
    redirect: privacyaHMMFDBYv5Meta?.redirect || undefined,
    component: () => import("@/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: about_45us8C5NHjacxmMeta?.name ?? "WhoWeAre___en",
    path: about_45us8C5NHjacxmMeta?.path ?? "/en/static/Whoweare",
    meta: about_45us8C5NHjacxmMeta || {},
    alias: about_45us8C5NHjacxmMeta?.alias || [],
    redirect: about_45us8C5NHjacxmMeta?.redirect || undefined,
    component: () => import("@/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45us8C5NHjacxmMeta?.name ?? "WhoWeAre___ar",
    path: about_45us8C5NHjacxmMeta?.path ?? "/ar/static/Whoweare",
    meta: about_45us8C5NHjacxmMeta || {},
    alias: about_45us8C5NHjacxmMeta?.alias || [],
    redirect: about_45us8C5NHjacxmMeta?.redirect || undefined,
    component: () => import("@/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: available_45onS5r9a1HjmGMeta?.name ?? "Available on___en",
    path: available_45onS5r9a1HjmGMeta?.path ?? "/en/static/Availableon",
    meta: available_45onS5r9a1HjmGMeta || {},
    alias: available_45onS5r9a1HjmGMeta?.alias || [],
    redirect: available_45onS5r9a1HjmGMeta?.redirect || undefined,
    component: () => import("@/pages/available-on.vue").then(m => m.default || m)
  },
  {
    name: available_45onS5r9a1HjmGMeta?.name ?? "Available on___ar",
    path: available_45onS5r9a1HjmGMeta?.path ?? "/ar/static/Availableon",
    meta: available_45onS5r9a1HjmGMeta || {},
    alias: available_45onS5r9a1HjmGMeta?.alias || [],
    redirect: available_45onS5r9a1HjmGMeta?.redirect || undefined,
    component: () => import("@/pages/available-on.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy5wsDLuG5BWMeta?.name ?? "CookiePolicy___en",
    path: cookie_45policy5wsDLuG5BWMeta?.path ?? "/en/static/cookie-policy",
    meta: cookie_45policy5wsDLuG5BWMeta || {},
    alias: cookie_45policy5wsDLuG5BWMeta?.alias || [],
    redirect: cookie_45policy5wsDLuG5BWMeta?.redirect || undefined,
    component: () => import("@/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy5wsDLuG5BWMeta?.name ?? "CookiePolicy___ar",
    path: cookie_45policy5wsDLuG5BWMeta?.path ?? "/ar/static/cookie-policy",
    meta: cookie_45policy5wsDLuG5BWMeta || {},
    alias: cookie_45policy5wsDLuG5BWMeta?.alias || [],
    redirect: cookie_45policy5wsDLuG5BWMeta?.redirect || undefined,
    component: () => import("@/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexW7ZZRJA0dXMeta?.name ?? "KidsWatch___en",
    path: indexW7ZZRJA0dXMeta?.path ?? "/en/kids/watch",
    meta: indexW7ZZRJA0dXMeta || {},
    alias: indexW7ZZRJA0dXMeta?.alias || [],
    redirect: indexW7ZZRJA0dXMeta?.redirect || undefined,
    component: () => import("@/pages/watch/index.vue").then(m => m.default || m)
  },
  {
    name: indexW7ZZRJA0dXMeta?.name ?? "KidsWatch___ar",
    path: indexW7ZZRJA0dXMeta?.path ?? "/ar/kids/watch",
    meta: indexW7ZZRJA0dXMeta || {},
    alias: indexW7ZZRJA0dXMeta?.alias || [],
    redirect: indexW7ZZRJA0dXMeta?.redirect || undefined,
    component: () => import("@/pages/watch/index.vue").then(m => m.default || m)
  },
  {
    name: indexROpAgbIi1fMeta?.name ?? "shows- show___en",
    path: indexROpAgbIi1fMeta?.path ?? "/en/shows/:seriesId/:slug",
    meta: indexROpAgbIi1fMeta || {},
    alias: indexROpAgbIi1fMeta?.alias || [],
    redirect: indexROpAgbIi1fMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexROpAgbIi1fMeta?.name ?? "shows- show___ar",
    path: indexROpAgbIi1fMeta?.path ?? "/ar/shows/:seriesId/:slug",
    meta: indexROpAgbIi1fMeta || {},
    alias: indexROpAgbIi1fMeta?.alias || [],
    redirect: indexROpAgbIi1fMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexROpAgbIi1fMeta?.name ?? "shows- show - season___en",
    path: indexROpAgbIi1fMeta?.path ?? "/en/shows/:seriesId/:slug/:seasonNumber",
    meta: indexROpAgbIi1fMeta || {},
    alias: indexROpAgbIi1fMeta?.alias || [],
    redirect: indexROpAgbIi1fMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexROpAgbIi1fMeta?.name ?? "shows- show - season___ar",
    path: indexROpAgbIi1fMeta?.path ?? "/ar/shows/:seriesId/:slug/:seasonNumber",
    meta: indexROpAgbIi1fMeta || {},
    alias: indexROpAgbIi1fMeta?.alias || [],
    redirect: indexROpAgbIi1fMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexROpAgbIi1fMeta?.name ?? "sports- show - season___en",
    path: indexROpAgbIi1fMeta?.path ?? "/en/sports/:seriesId/:slug/:seasonNumber",
    meta: indexROpAgbIi1fMeta || {},
    alias: indexROpAgbIi1fMeta?.alias || [],
    redirect: indexROpAgbIi1fMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexROpAgbIi1fMeta?.name ?? "sports- show - season___ar",
    path: indexROpAgbIi1fMeta?.path ?? "/ar/sports/:seriesId/:slug/:seasonNumber",
    meta: indexROpAgbIi1fMeta || {},
    alias: indexROpAgbIi1fMeta?.alias || [],
    redirect: indexROpAgbIi1fMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRltcMR3hfXMeta?.name ?? "sports- show - season - episode___en",
    path: indexRltcMR3hfXMeta?.path ?? "/en/sports/:seriesId/:slug/:seasonNumber/:episodeNumber",
    meta: indexRltcMR3hfXMeta || {},
    alias: indexRltcMR3hfXMeta?.alias || [],
    redirect: indexRltcMR3hfXMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/[slug]/[seasonNumber]/[episodeNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRltcMR3hfXMeta?.name ?? "sports- show - season - episode___ar",
    path: indexRltcMR3hfXMeta?.path ?? "/ar/sports/:seriesId/:slug/:seasonNumber/:episodeNumber",
    meta: indexRltcMR3hfXMeta || {},
    alias: indexRltcMR3hfXMeta?.alias || [],
    redirect: indexRltcMR3hfXMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/[slug]/[seasonNumber]/[episodeNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexROpAgbIi1fMeta?.name ?? "news- show - season___en",
    path: indexROpAgbIi1fMeta?.path ?? "/en/news/:seriesId/:slug/:seasonNumber",
    meta: indexROpAgbIi1fMeta || {},
    alias: indexROpAgbIi1fMeta?.alias || [],
    redirect: indexROpAgbIi1fMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexROpAgbIi1fMeta?.name ?? "news- show - season___ar",
    path: indexROpAgbIi1fMeta?.path ?? "/ar/news/:seriesId/:slug/:seasonNumber",
    meta: indexROpAgbIi1fMeta || {},
    alias: indexROpAgbIi1fMeta?.alias || [],
    redirect: indexROpAgbIi1fMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRltcMR3hfXMeta?.name ?? "news- show - season - episode___en",
    path: indexRltcMR3hfXMeta?.path ?? "/en/news/:seriesId/:slug/:seasonNumber/:episodeNumber",
    meta: indexRltcMR3hfXMeta || {},
    alias: indexRltcMR3hfXMeta?.alias || [],
    redirect: indexRltcMR3hfXMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/[slug]/[seasonNumber]/[episodeNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRltcMR3hfXMeta?.name ?? "news- show - season - episode___ar",
    path: indexRltcMR3hfXMeta?.path ?? "/ar/news/:seriesId/:slug/:seasonNumber/:episodeNumber",
    meta: indexRltcMR3hfXMeta || {},
    alias: indexRltcMR3hfXMeta?.alias || [],
    redirect: indexRltcMR3hfXMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/[slug]/[seasonNumber]/[episodeNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexROpAgbIi1fMeta?.name ?? "kids- program - season___en",
    path: indexROpAgbIi1fMeta?.path ?? "/en/kids/programs/:seriesId/:slug/:seasonNumber",
    meta: indexROpAgbIi1fMeta || {},
    alias: indexROpAgbIi1fMeta?.alias || [],
    redirect: indexROpAgbIi1fMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexROpAgbIi1fMeta?.name ?? "kids- program - season___ar",
    path: indexROpAgbIi1fMeta?.path ?? "/ar/kids/programs/:seriesId/:slug/:seasonNumber",
    meta: indexROpAgbIi1fMeta || {},
    alias: indexROpAgbIi1fMeta?.alias || [],
    redirect: indexROpAgbIi1fMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRltcMR3hfXMeta?.name ?? "kids- program - season - episode___en",
    path: indexRltcMR3hfXMeta?.path ?? "/en/kids/programs/:seriesId/:slug/:seasonNumber/:episodeNumber",
    meta: indexRltcMR3hfXMeta || {},
    alias: indexRltcMR3hfXMeta?.alias || [],
    redirect: indexRltcMR3hfXMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/[slug]/[seasonNumber]/[episodeNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRltcMR3hfXMeta?.name ?? "kids- program - season - episode___ar",
    path: indexRltcMR3hfXMeta?.path ?? "/ar/kids/programs/:seriesId/:slug/:seasonNumber/:episodeNumber",
    meta: indexRltcMR3hfXMeta || {},
    alias: indexRltcMR3hfXMeta?.alias || [],
    redirect: indexRltcMR3hfXMeta?.redirect || undefined,
    component: () => import("@/pages/shows/[seriesId]/[slug]/[seasonNumber]/[episodeNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "VideoIdName___en",
    path: indexHnrzASXykvMeta?.path ?? "/en/video/:id/:name",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "VideoIdName___ar",
    path: indexHnrzASXykvMeta?.path ?? "/ar/video/:id/:name",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "VideoIndexPHPIdName___en",
    path: indexHnrzASXykvMeta?.path ?? "/en/index.php/video/:id/:name",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "VideoIndexPHPIdName___ar",
    path: indexHnrzASXykvMeta?.path ?? "/ar/index.php/video/:id/:name",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "VideoId___en",
    path: indexHnrzASXykvMeta?.path ?? "/en/video/:id",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "VideoId___ar",
    path: indexHnrzASXykvMeta?.path ?? "/ar/video/:id",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "VideoIndexPHPId___en",
    path: indexHnrzASXykvMeta?.path ?? "/en/index.php/video/:id",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "VideoIndexPHPId___ar",
    path: indexHnrzASXykvMeta?.path ?? "/ar/index.php/video/:id",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "Live___en",
    path: indexHnrzASXykvMeta?.path ?? "/en/live/:id/:name",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "Live___ar",
    path: indexHnrzASXykvMeta?.path ?? "/ar/live/:id/:name",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "NamedCarousel___en",
    path: indexHnrzASXykvMeta?.path ?? "/en/carousel/:id",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "NamedCarousel___ar",
    path: indexHnrzASXykvMeta?.path ?? "/ar/carousel/:id",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "NamedCarouselAlternateURL___en",
    path: indexHnrzASXykvMeta?.path ?? "/en/carousel/:id/:name",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "NamedCarouselAlternateURL___ar",
    path: indexHnrzASXykvMeta?.path ?? "/ar/carousel/:id/:name",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "ShowAlternateURL___en",
    path: indexHnrzASXykvMeta?.path ?? "/en/show/:id/:id2/:name",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnrzASXykvMeta?.name ?? "ShowAlternateURL___ar",
    path: indexHnrzASXykvMeta?.path ?? "/ar/show/:id/:id2/:name",
    meta: indexHnrzASXykvMeta || {},
    alias: indexHnrzASXykvMeta?.alias || [],
    redirect: indexHnrzASXykvMeta?.redirect || undefined,
    component: () => import("@/pages/show/[id]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: index5cgARK3OC1Meta?.name ?? "CreateAccountAlias___en",
    path: index5cgARK3OC1Meta?.path ?? "/en/hi",
    meta: index5cgARK3OC1Meta || {},
    alias: index5cgARK3OC1Meta?.alias || [],
    redirect: index5cgARK3OC1Meta?.redirect || undefined,
    component: () => import("@/pages/create-account/index.vue").then(m => m.default || m)
  },
  {
    name: index5cgARK3OC1Meta?.name ?? "CreateAccountAlias___ar",
    path: index5cgARK3OC1Meta?.path ?? "/ar/hi",
    meta: index5cgARK3OC1Meta || {},
    alias: index5cgARK3OC1Meta?.alias || [],
    redirect: index5cgARK3OC1Meta?.redirect || undefined,
    component: () => import("@/pages/create-account/index.vue").then(m => m.default || m)
  },
  {
    name: index3JQzKs4byXMeta?.name ?? "ForgotPasswordAlias___en",
    path: index3JQzKs4byXMeta?.path ?? "/en/reset",
    meta: index3JQzKs4byXMeta || {},
    alias: index3JQzKs4byXMeta?.alias || [],
    redirect: index3JQzKs4byXMeta?.redirect || undefined,
    component: () => import("@/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: index3JQzKs4byXMeta?.name ?? "ForgotPasswordAlias___ar",
    path: index3JQzKs4byXMeta?.path ?? "/ar/reset",
    meta: index3JQzKs4byXMeta || {},
    alias: index3JQzKs4byXMeta?.alias || [],
    redirect: index3JQzKs4byXMeta?.redirect || undefined,
    component: () => import("@/pages/forgot-password/index.vue").then(m => m.default || m)
  }
]