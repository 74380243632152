/**
 * @docs https://www.npmjs.com/package/@matiasfic/device-detector-js
 */
import DeviceDetector from "device-detector-js";

/**
 * Interfaces to Provide
 * "Device-Type":"AndroidSmartphone",//[AndroidSmartphone,AndroidTablet,Androidtv,PC,iPad,IPhone,Apple TV]
 * "Serial-Number":"92195016-9903-4846-8b79-f443deaed135",//an uuid generate by client
 * "Terminal-Name": "Android-WEB-9219"
 * "Device-Locale":"ar",//en ar others
 * "Os":"ios",//[ios,android,windows,mac,linux]
 */

const useUserAgent = () => {
  if (process.server) return null;

  const deviceDetector = new DeviceDetector();
  const userAgent = navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  return device;
};

/**
 *
 * @returns This function returns AndroidSmartphone | AndroidTablet | PC | Mac | iPad | iPhone
 */
const useGetDeviceType = () => {
  /**
   * persistence:
   * if device type is already in the localStorage,
   * just return that. also we dont need this in ssr
   */
  // if (process.client) {
  //   const deviceTypeFromStorage = localStorage.getItem("DeviceType");
  //   if (deviceTypeFromStorage) {
  //     return deviceTypeFromStorage;
  //   }
  // }

  const userAgent = useUserAgent();
  if (!userAgent) return "";
  let deviceType = "";

  /**
   * Findings on iPad:
   *
   * when using Safari:
   * the useragent is emulated as a PC, which means the deviceType will be Mac
   *
   * when using Chrome:
   * the useragent is NOT emulated as PC. which means the condition below does not
   * evaluate to true.
   *
   * ergo, when the os name is iOS and the device model is iPad, the device type
   * to return should be PC. it sounds counter-intuitive.
   */

  if (userAgent?.device?.type?.toLowerCase() === "desktop") {
    // only set mac on Safari MacOS to get DASH manifests
    if (
      userAgent?.os?.name?.includes("Mac") &&
      userAgent?.client?.name == "Safari"
    ) {
      deviceType = "Mac";
    } else {
      deviceType = "PC";
    }
  } else {
    const requiredValues = [userAgent?.device?.model, userAgent?.device?.type];
    if (requiredValues.some((i) => i != null && i !== "")) {
      switch (userAgent?.os?.name) {
        case "iOS": {
          if (userAgent?.device?.model == "iPad") {
            deviceType = "iPad";
          } else {
            deviceType = userAgent?.device?.model;
          }
          break;
        }
        case "Android": {
          deviceType =
            userAgent?.device?.type?.charAt(0)?.toUpperCase() +
            userAgent?.device?.type?.slice(1);

          // Attach device brand to device type
          deviceType = `Android${deviceType}`;

          break;
        }
        default: {
          deviceType = "PC";
        }
      }
    }

    /**
     * if you reach here, it means device type and model are empty.
     * rely on device.brand and client.name to give the device type.
     * only happens on iPad OS 16
     */
    if (!deviceType && userAgent?.device?.brand == "Apple") {
      deviceType = "iPad";
    }
  }
  return deviceType;
};

/**
 * gets device type and simply stores it directly on the localStorage.
 * no need to call useGetDeviceType on every API call
 */
// export const persistAgent = () => {
//   const ua = useGetDeviceType();
//   localStorage.setItem("DeviceType", ua);
// };

export default useUserAgent;

export { useGetDeviceType };
