import validate from "/app/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.0.0_rollup@3.27.0_sass@1.64.1/node_modules/nuxt/dist/pages/runtime/validate.js";
import router_45global from "/app/middleware/router.global.js";
export const globalMiddleware = [
  validate,
  router_45global
]
export const namedMiddleware = {
  "category-guard": () => import("/app/middleware/category-guard.js"),
  "legacy-redirect": () => import("/app/middleware/legacy-redirect.js"),
  "no-season-num-redirect": () => import("/app/middleware/no-season-num-redirect.js"),
  "slug-redirect": () => import("/app/middleware/slug-redirect.js")
}