/**
 * determines the layout to be rendered considering the next route
 *
 * as of 24 July, this is the logic:
 *
 * if the subdomain is TV, set "tv" as the layout
 *
 * if the next path is /watch, or /login
 * which does not need a layout, we set it to false.
 *
 * @param {*} route: the next route to navigate to
 * @returns layout name
 */
export default function (route) {
  const noLayoutRoutes = [
    "/watch",
    "/login",
    "/login/create",
    "/login/signin",
    "/login/code",
    "/status",
    "/episode-",
  ];

  const profileRoutes = [
    "/profiles",
    "/profiles/",
    "/profiles/new",
    "/parental-code/set",
    "/customize-recommendations",
    "/profiles/verify",
  ];

  const settingsRoute = ["/settings"];

  const standaloneRoutes = [
    "/sign-in",
    "/link-tv",
    "/forgot-password",
    "/reset",
    "/hi",
    "/create-account",
  ];

  const isNoLayoutRoute = noLayoutRoutes.some((routeName) => {
    return route.path?.includes(routeName);
  });

  const isProfileRoute = profileRoutes.some((routeName) => {
    const inList = route.path?.includes(routeName);
    const isEdit = route.name?.includes("profiles-profile-edit");
    const isUpdatePfp = route.name?.includes("profiles-profile-update-pfp");
    return inList || isEdit || isUpdatePfp;
  });

  const isSettingsRoute = settingsRoute.some((routeName) => {
    const inList = route.path?.includes(routeName);
    return inList;
  });

  const isStandalone = standaloneRoutes.some((routeName) => {
    const inList = route.path?.includes(routeName);
    return inList;
  });

  let layout = "default";
  if (isProfileRoute) {
    layout = "profile";
  } else if (isSettingsRoute) {
    layout = "settings";
  } else if (isNoLayoutRoute) {
    layout = "blank";
  } else if (isStandalone) {
    layout = "standalone";
  }

  return layout;
}
