export default {
  website_title: "شاهد اجدد الأفلام، المسلسلات والبرامج المباشرة على",
  website_description:
    "اكتشف أحدث الأفلام، العروض و المسلسلات الجديدة لعام ٢٠٢٤. شاهد برامجك المفضلة المحلية و العالمية الآن على ADtv.",
  movies_main_category_page_title: "شاهد جميع افلامك المفضلة أونلاين",
  movies_main_category_page_description:
    "تابع أحدث أعمال عالم السينما وشاهد أحدث الأفلام العربية والأجنبية. تمتع بمشاهدة الأفلام المفضلة لديك في أي وقت يناسبك حصرياً على ADtv.",
  movie_detail_page_title: "استمتع بمتابعة فيلم {name}",
  movie_watch_page_title: "شاهد فيلم {name}",
  movie_watch_page_description:
    "تابع أحداث فيلم {name} و استمتع بتجربة سينمائية فريدة تأسرك بأحداثها المشوقة والشخصيات المثيرة. اشترك الآن وشاهد الافلام المفضلة لديك.",
  shows_main_category_page_title: "شاهد جميع برامجك ومسلسلاتك المفضلة أونلاين",
  shows_main_category_page_description:
    "تابع أحدث أعمال عالم الفن وشاهد أجدد البرامج و المسلسلات العربية والأجنبية. تمتع بمشاهدة البرامج المفضلة لديك في أي وقت يناسبك حصرياً على ADtv.",
  sports_main_category_page_title:
    "تابع البث المباشر للألعاب والبطولات الرياضية",
  sports_main_category_page_description:
    "تابع البث المباشر لأخر الأحداث الرياضية على القناة الرئيسية. اشترك الآن وتمتع بمشاهدة الألعاب والبطولات مباشرةً حصرياً على قناة ياس، أبوظبي الرياضية 1 او 2.",
  kids_main_category_page_title:
    "شاهد عالم ماجد و برامج الاطفال التعليمية و الكرتونية مجانا",
  kids_main_category_page_description:
    "تابع أحدث برامج الأطفال التعليمية والكرتونية بالإضافة إلى برامج ماجد. استمع لاجدد أغاني الاطفال الممتعة و دع أولادكَ يستمتعون بمحتوى ترفيهي فريد و مضحك.",
  programs_main_subcategory_page_title:
    "شاهد جميع برامج الاطفال التعليمية و الكرتونية مجانا",
  programs_main_subcategory_page_description:
    "دع أطفالك يستمتعون بمشاهدة برامج الأطفال التعليمية والكرتونية الممتعة. اشترك الآن واستمتع بترفيه حصري عالي الجودة على موقع ADtv.",
  songs_main_subcategory_page_title: "أجمل وأفضل اغاني الاطفال",
  songs_main_subcategory_page_description:
    "دع أطفالك يستمتعون بأروع أغاني الأطفال التي تجلب البهجة والتسلية. اجعلهم يكتشفون عالم الموسيقى مع تشكيلة مميزة حصرياً على موقع ADtv.",
  songs_player_page_title: "{songName}",
  songs_player_page_description:
    "دع أطفالك يستمتعون بأروع أغاني الأطفال التي تجلب البهجة والتسلية. استمع ل{songName} حصرياً على موقع ADtv.",
  characters_main_subcategory_page_title:
    "الشخصيات الكرتونية {'|'} برامج الاطفال الكرتونية",
  characters_main_subcategory_page_description:
    "دع أطفالك يستمتعون بمشاهدة برامج الشخصيات الكرتونية المفضلة لديهم بمحتوى ترفيهي تعليمي ممتع. اجعلهم يكتشفون عوالم مليئة بالمغامرات حصرياً على موقع ADtv.",
  character_page_title: "شاهد جميع برامج الاطفال المتعلقة ب {name}",
  character_page_description:
    " دع أطفالك يستمتعون بمشاهدة جميع البرامج والحلقات المتعلقة ب{name}. اشترك الآن واستمتع بترفيه حصري عالي الجودة من ضمن مجموعة العروض المفضلة لديك.",
  majid_tv_title: "تابع البث المباشر لقناة ماجد {'|'} برامج الاطفال الكرتونية",
  majid_tv_description:
    "دع أطفالك يستمتعون بمشاهدة البث المباشر لقناة ماجد فالمتعة والتعلم في انتظارهم. زر موقع ADtv للاستمتاع ببرامج ترفيهية وتفاعلية تناسب جميع أفراد العائلة.",
  live_main_category_page_title: "قناة البث المباشر",
  live_main_category_page_description:
    "تابع البث المباشر حيث يقدم لك محتوى متنوع يشمل آخر البرامج والأحداث الرياضية، مع تغطية شاملة لأحدث الأخبار والأحداث الهامة والبرامج الوثائقية.",
  live_channel_page_title: "تابع البث المباشر لقناة {channelName}",
  live_channel_page_description:
    "شاهد البث المباشر لقناة {channelName} و تابع جميع الأحداث في الوقت الحقيقي. استمتع بأحدث المحتويات والبرامج مجانا فقط على موقع ADtv.",
  sports_channel_page_title: "تابع البث المباشر لقناة {channelName}",
  sports_channel_page_description:
    "تابع البث المباشر لقناة {channelName} واستمتع بمشاهدة المباريات، التغطية الحصرية للبطولات بالإضافة إلى البث المباشر للأحداث الرياضية فقط على موقع ADtv.",
  show_season_page_title: "شاهد {seriesName} - الموسم {seasonNum}",
  show_season_page_description:
    "اكتشف احداث مسلسل {seriesName} في الموسم {seasonNum} و شاهد أحداث مشوقة تعكس فخامة أعمال عالم الفن حصرياً على ADtv.",
  show_episode_watch_page_title:
    "شاهد {seriesName} - الموسم {seasonNum} - الحلقة {episodeNum}",
  show_episode_watch_page_description:
    "شاهد الحلقة {episodeNum} من الموسم {seasonNum} لمسلسل {seriesName} و اكتشف أحداث مشوقة في كل حلقة تعكس فخامة أعمال عالم الفن حصرياً على ADtv.",
  show_season_page_title_to_program: "شاهد {seriesName} - الموسم {seasonNum}",
  show_episode_watch_page_title_to_program:
    "شاهد {seriesName} - الموسم {seasonNum} - الحلقة {episodeNum}",
  sports_show_detail_season_page_title:
    "شاهد برنامج {seriesName} {'|'} البرامج الرياضية",
  sports_show_detail_season_page_description:
    "شاهد برنامج {seriesName} وانغمس في معرفة المزيد في عالم الرياضة. اشترك الآن واستمتع بترفيه حصري عالي الجودة من ضمن مجموعة البرامج الرياضية المفضلة لديك.",
  sports_show_episode_page_title:
    "شاهد برنامج {seriesName} - الموسم {seasonNum} - الحلقة {episodeNum}",
  sports_show_episode_page_description:
    "استمتع بمتابعة برنامج مميز يعكس عالم الرياضة. شاهد الحلقة {episodeNum} من الموسم {seasonNum} لمسلسل {seriesName} و اكتشف معلومات مشوقة في كل حلقة.",
  program_page_title: "شاهد برنامج {seriesName} - الموسم {seasonNum}",
  program_page_description:
    "شاهد الموسم {seasonNum} من برنامج {seriesName} و دع أطفالك يستمتعون بمشاهدة برامج الأطفال التعليمية والكرتونية الممتعة حصرياً على ADtv.",
  program_episode_watch_page_title:
    "شاهد برنامج {seriesName} - الموسم {seasonNum} - الحلقة {episodeNum}",
  program_episode_watch_page_description:
    "دع أطفالك يستمتعون بمشاهدة افضل برامج الأطفال التعليمية والكرتونية الممتعة. شاهد الحلقة {episodeNum} من الموسم {seasonNum} لبرنامج {seriesName} حصرياً على ADtv.",
  news_main_category_page_title: "شاهد جميع البرامج الإخبارية أونلاين",
  news_main_category_page_description:
    "شاهد آخر الأخبار داخل دولة الإمارات والعالم من أحداث ومستجدات في الشأن السياسي، الاقتصادي، الرياضي والفني. اطلع على كافة ما يهم الأسرة من جوانب حياتية على موقع ADtv.",
  news_show_detail_season_page_title:
    "شاهد برنامج {seriesName} - الموسم {seasonNum}",
  news_show_detail_season_page_description:
    "شاهد الموسم {seasonNum} من برنامج {seriesName} واستمتع بمحتوى شيّق ومفيد. اكتشف الحلقات الجديدة واستمتع بفقرات متنوعة ترتكز على كافة ما يهمك من مواضيع، جوانب حياتية، أخبار ونصائح.",
  news_show_episode_page_title:
    "شاهد برنامج {seriesName} - الموسم {seasonNum} - الحلقة {episodeNum}",
  news_show_episode_page_description:
    "اكتشف أحدث المواضيع في الموسم {seasonNum} من {seriesName} من خلال مشاهدة الحلقة {episodeNum}. اكتشف آخر الأخبار داخل دولة الإمارات والعالم وكافة ما يهم الأسرة من جوانب حياتية على ADtv.",
  episode_structured_name:
    "{seriesName} - الموسم {seasonNum} - الحلقة {episodeNum}",
};
