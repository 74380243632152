export default {
  termTitle: "الشروط والأحكام",
  termSubTitle: "برجاء قراءة هذه الشروط والأحكام بعناية قبل استخدام خدمتنا. ",
  termUpdate: "آخر تحديث - 16 أغسطس 2024 ",
  termSection1Title: "التفسير والتعريفات ",
  termSection1SubTitle1: "التفسير ",
  termSection1Part1: `
تكون للكلمات المكتوبة بخط عريض فيما يلي المعاني الموضحة وفقًا للشروط التالية، ويكون للتعريفات التالية نفس المعنى سواء كانت بصيغة المفرد أو الجمع. 
    `,
  termSection1SubTitle2: "التعريفات",
  termSection1Part2: `لأغراض هذه الشروط والأحكام: 

التطبيق يُقصد به البرنامج، الذي يحمل حاليًا اسم "ADtv"، الذي تقدمه الشركة، وتقوم أنت بتحميله على أي جهاز إلكتروني.  

متجر التطبيقات يُقصد به خدمة التوزيع الرقمي التي تشغلها وتديرها شركة آبل "Apple App Store" (متجر تطبيقات آبل)، أو شركة جوجل "Google Play Store" (متجر جوجل بلاي) التي يمكن من خلالها تحميل التطبيق. 

الشركة التابعة يُقصد بها أي كيان يتحكم أو يخضع لسيطرة مباشرة أو مشتركة مع طرف ما، ويُقصد بكلمة "السيطرة" ملكية 50% أو أكثر من الأسهم أو حصص الملكية أو الأوراق المالية الأخرى التي يحق لها التصويت لانتخاب أعضاء مجلس الإدارة أو أي سلطة إدارة أخرى.الحساب يُقصد به حساب خاص تم إنشاؤه لك للوصول إلى خدمتنا أو أجزاء من خدمتنا. 

البلد تشير إلى: الإمارات العربية المتحدة 

الشركة (يشار إليها إما بـ "الشركة" أو "نحن" أو "نا" أو "خاصتنا" في هذه الاتفاقية) وتشير إلى شركة أبوظبي للإعلام، شركة مساهمة عامة، الكائنة في شارع محمد بن خليفة، منطقة المرور، ص.ب. 63، أبوظبي، الإمارات العربية المتحدة. 

الجهاز يُقصد به أي جهاز يمكنه الوصول إلى الخدمة مثل جهاز كمبيوتر أو هاتف محمول أو جهاز لوحي رقمي. 

التعليقات يُقصد بها التعليقات، أو الابتكارات، أو الاقتراحات التي ترسلها إلينا بشأن خواص خدمتنا أو أدائها، أو ميزاتها. 

الشراء من داخل التطبيق يشير إلى شراء منتج، أو سلعة، أو خدمة، أو اشتراك من خلال التطبيق، ويخضع لهذه الشروط والأحكام و/أو الشروط والأحكام الخاصة بمتجر التطبيقات. 

المنصات السابقة تشير إلى ما يخص الشركة سواء تطبيقا ماجد وADTV، وموقعا ماجد وADTV الموجودان في <a class="terms-link" href="https://www.majid.ae" target="_blank">www.majid.ae</a>، و <a class="terms-link" href="https://www.adtv.ae" target="_blank">www.adtv.ae</a>. 

الخدمة تشير إلى التطبيق أو الموقع الإلكتروني أو كليهما. 

الاشتراكات تشير إلى الخدمات أو الوصول إلى الخدمة التي تقدمها الشركة لك بناءً على الاشتراك. 

الشروط والأحكام (يُشار إليها أيضًا "بالشروط") يُقصد بها هذه الشروط والأحكام التي تشكل مجمل الاتفاق بينك وبين الشركة بشأن استخدام الخدمة. 

خدمة التواصل الاجتماعي من الغير يُقصد بها أي خدمات أو محتوى (بما في ذلك البيانات، أو المعلومات، أو المنتجات، أو الخدمات) التي يقدمها الغير ويجوز أن تعرضها الخدمات، أو تشملها، أو تتاح عن طريقها. 

الموقع الإلكتروني يشير إلى الموقع الإلكتروني المملوك للشركة الذي يمكن الدخول إليه عن طريق <a class="terms-link" href="https://www.adtv.ae" target="_blank">https://www.adtv.ae</a>. 

أنت يُقصد به الفرد الذي يصل إلى الخدمة أو يستخدمها أو الشركة أو أي كيان قانوني آخر يقوم هذا الفرد بالنيابة عنه بالوصول إلى الخدمة أو استخدامها، حسب الاقتضاء. 

    `,

  termSection2Title: "إقرار  ",
  termSection2Part1: `
تحكم هذه الشروط والأحكام استخدام الخدمة، وهي الاتفاق المعمول به بينك وبين الشركة. وتحدد هذه الشروط والأحكام حقوق والتزامات كل المستخدمين بشأن استخدام الخدمة. 

يتوقف وصولك للخدمة واستخدامها على موافقتك على هذه الشروط والأحكام والتزامك بها. وتنطبق هذه الشروط والأحكام على كل الزوار، والمستخدمين، وغيرهم من الأشخاص الذين يصلون إلى الخدمة أو يستخدمونها. كما توافق بمجرد وصولك إلى الخدمة واستخدامها على الالتزام بهذه الشروط والأحكام. 

تقر أن عمرك لا يقل عن 18 عامًا. ولا تسمح الشركة للأشخاص الذين تقل أعمارهم عن 18 عامًا باستخدام الخدمة. 

يتوقف وصولك إلى الخدمة واستخدامها أيضًا على موافقتك والتزامك بسياسة خصوصية الشركة. تحدد سياسية الخصوصية لدينا سياسات وإجراءات جمع بياناتك الشخصية، واستخدامها، والإفصاح عنها عند استخدام التطبيق أو الموقع الإلكتروني، وتعلمك بحقوق الخصوصية الممنوحة لك وكيف يحميك القانون. برجاء قراءة سياسة الخصوصية الخاصة بنا بعناية قبل استخدام الخدمة. 

حسب الاقتضاء، بمجرد وصولك إلى الخدمة أو استخدامها، فأنت توافق على أن تنقل إلى الخدمة أي بيانات شخصية تقدمها للاستفادة منها في المنصات السابقة، وكذلك على أن تستخدم الشركة تلك المعلومات والبيانات الشخصية وتفصح عنها وفقًا لسياسة الخصوصية. 

المنصات السابقة غير موجهة لأي شخص يقل عمره عن 18 عامًا. ولا نجمع عن عمد معلومات تحديد الهوية الشخصية من أي شخص يقل عمره عن 18 عامًا. وإذا كنت أحد الأبوين أو وصيًا، وعلى علم بأن طفلك قدم لنا سابقًا بيانات شخصية ليتم استعمالها في المنصات السابقة، فيُرجى التواصل معنا، وسنتخذ الخطوات لحذف المعلومات من خوادمنا. 
    `,
  termSection3Title: `الاشتراكات `,
  termSection3SubTitle1: `مدة الاشتراك `,
  termSection3Part1: `لا تتاح الخدمة أو جزء منها إلا باشتراك مدفوع، على أن تصدر لك الفاتورة مقدمًا على أساس متكرر ودوري (يوميًا، أو أسبوعيًا، أو شهريًا، أو سنويًا) حسب نوع خطة الاشتراك التي تختارها عند شراء الاشتراك. 

في نهاية تلك المدة، يُجدد اشتراكك تلقائيًا بنفس الشروط، ما لم يتم إلغاؤه من قِبلك أو من قِبل الشركة. `,
  termSection3SubTitle2: `إلغاء الاشتراك `,
  termSection3Part2: `يجوز لك إلغاء تجديد اشتراكك أما عن طريقة صفحة حسابك، أو التواصل مع الشركة. لن تُرد إليك الرسوم التي سددتها بالفعل عن مدة الاشتراك الحالية، ويمكنك الوصول إلى الخدمة حتى نهاية مدة اشتراكك الحالي. 

إذا قمت بتفعيل الاشتراك من خلال الشراء من داخل التطبيق، يمكنك إلغاء تجديد الاشتراك من متجر التطبيقات. `,
  termSection3SubTitle3: `إصدار الفواتير `,
  termSection3Part3: `تقدم للشركة معلومات دقيقة وكاملة عن إصدار الفواتير، وتشمل الاسم بالكامل، والعنوان، والبلد، والرقم البريدي، ورقم الهاتف، ومعلومات سارية عن طريقة الدفع. 

إذا لم تصدر الفواتير التلقائية لأي سبب، تصدر الشركة فاتورة إلكترونية تنص على أنه يجب عليك أن تكمل عملية سداد الفواتير يدويًا، في تاريخ موعد نهائي محدد، عن طريق السداد الكامل الذي يتوافق مع المدة التي تصدر عنها الفاتورة كما تحدد فيها. 

إذا اشتركت عن طريق الشراء من داخل التطبيق، يتعامل متجر التطبيقات مع عملية إصدار الفواتير، وتحكم تلك العملية شروط وأحكام متجر التطبيقات. `,
  termSection3SubTitle4: `تغيير الرسوم `,
  termSection3Part4: `يجوز للشركة، في أي وقت وفقًا لتقديرها المطلق، تغيير رسوم الاشتراك. ويصبح أي تغيير في رسوم الاشتراك ساريًا في نهاية مدة الاشتراك السارية حينها. 

ترسل الشركة إليك إخطارًا مسبقًا مدته معقولة بأي تغيير في رسوم الاشتراك لتعطيك الفرصة لإنهاء الاشتراك قبل أن يدخل التغيير حيز التنفيذ. 

يشكل استمرارك في استخدام الخدمة بعد سريان التغيير في رسوم الاشتراك موافقتك على دفع مبلغ الرسوم الذي تم تعديله. `,
  termSection3SubTitle5: `عمليات الاسترداد `,
  termSection3Part5: `رسوم الاشتراك المدفوعة غير قابلة للاسترداد، باستثناء ما يشترطه القانون. 

يجوز أن تنظر الشركة في طلبات استرداد معينة تخص الاشتراكات وفقًا لكل حالة على حدة، ويجوز أن توافق عليها الشركة وفقًا لتقديرها المطلق. 

إذا كان الاشتراك عن طريق الشراء من داخل التطبيق، تنطبق سياسة الاسترداد الخاصة بمتجر التطبيقات. إذا كنت ترغب في تقديم طلب استرداد، يجوز لك ذلك عن طريق التواصل مع متجر التطبيقات مباشرةً. `,
  termSection4Title: `عمليات الشراء من داخل التطبيق `,
  termSection4Part1: `قد يحتوي التطبيق على عمليات شراء من داخل التطبيق مما يسمح لك بشراء المنتجات، أو الخدمات، أو الاشتراكات. 

قد تنص الشروط والأحكام الخاصة بمتجر التطبيقات أو إعدادات المساعدة على جهازك على المزيد من المعلومات عن كيف يمكنك إدارة عمليات الشراء من داخل التطبيق باستخدام جهازك. 

لا يمكن إجراء عمليات الشراء من داخل التطبيق إلا داخل التطبيق. إذا كنت تجري عملية شراء من داخل التطبيق، لا يمكن إلغاء تلك العملية بعد بدء تحميلها. لا يمكن التعويض عن عمليات الشراء من داخل التطبيق نقدًا أو غيره، أو التحويل بأي طريقة أخرى. 

في حالة عدم تحميل عملية الشراء من داخل التطبيق بنجاح، أو عدم تفعيلها بمجرد تحميلها بنجاح، سنحقق، بعد علمنا بالخطأ أو بعد أن تخطرنا به، في سبب هذا الخطأ. وسنعمل بصورة معقولة في اتخاذ القرار إما بمنحك عملية شراء من داخل التطبيق بديلة، أو إصدار تصحيح لك لإصلاح الخطأ. ولن نحملك في أي حال من الأحوال تكلفة تبديل عملية الشراء من داخل التطبيق أو إصلاحها. وفي حال من غير المحتمل أننا لم نتمكن من تبديل عملية الشراء من داخل التطبيق أو إصلاحها، أو لم نتمكن من تنفيذ ذلك خلال مدة زمنية معقولة ودون إزعاج حقيقي لك، سنصرح لمتجر التطبيقات برد المبلغ تكلفة عملية الشراء من داخل التطبيق لك. وبدلًا من ذلك، إذا كنت ترغب في تقديم طلب استرداد، يمكنك ذلك عن طريق التواصل مع متجر التطبيقات مباشرةً. 

تقر وتوافق على أن يتعامل متجر التطبيقات الذي قمت بتحميل التطبيق منه مع كل عمليات إصدار الفواتير والمعاملات، وأن تحكم شروط وأحكام متجر التطبيقات تلك العمليات. 

إذا كان لديك أي مشكلة بشأن عمليات الشراء من داخل التطبيق، يجب عليك التواصل مع متجر التطبيقات مباشرةً. `,
  termSection5Title: `حسابات المستخدمين `,
  termSection5Part1: `عندما تنشئ حسابًا لدينا، يجب أن تقدم لنا دائمًا معلومات دقيقة، وكاملة، وسارية، ويشكل عدم تنفيذ ذلك خرقًا للشروط، مما قد ينتج عنه وقف فوري لحسابك على خدمتنا. 

تتحمل مسؤولية حماية كلمة السر التي تستخدمها للوصول إلى الخدمة، وأي أنشطة أو أعمال تصل إليها باستخدام كلمة السر الخاصة بك، سواء كلمة السر الخاصة بخدمتنا، أو بخدمة التواصل الاجتماعي من الغير. 

توافق على عدم الإفصاح عن كلمة السر الخاصة بك للغير. ويجب أن تخطرها فورًا بمجرد معرفتك بوقوع أي خرق لأمن حسابك أو الاستخدام غير المصرح به له. 

لا يجوز لك أن تضع اسم مستخدم يخص اسم شخص أو كيان آخر، أو اسمًا غير متاح للاستخدام قانونًا، نقل اسم أو علامة تجارية تخضع لأي حقوق لشخص أو كيان آخر غيرك دون تصريح ملائم، أو اسم مسيء، أو مبتذل، أو بذيء بأي شكل آخر. `,
  termSection6Title: `الملكية الفكرية `,
  termSection6Part1: `الخدمة ومحتواها (باستثناء المحتوى الذي تقدمه أنت أو مستخدم آخر)، وميزاتها، ووظيفتها هي ملكية حصرية للشركة وجهاتها المرخص لها، وتظل كذلك. 

تحمي الخدمة قوانين حقوق النشر، والعلامات التجارية، وقوانين أخرى داخل البلد وفي البلاد الأجنبية. 

لا يجوز استخدام علاماتنا التجارية ومظهرنا التجاري الخاص بأي منتج أو خدمة دون موافقة خطية مسبقة من الشركة.`,
  termSection7Title: `تعليقاتك المقدَّمة لنا `,
  termSection7Part1: `تتنازل عن كل حقوقك، وملكيتك، ومصلحتك في أي تعليق تقدمه للشركة. في حالة عدم سريان هذا التنازل لأي سبب، توافق على أن تمنح الشركة الحق والترخيص غير الحصري، الدائم، غير القابل للإلغاء، المجاني، العالمي لاستخدام ذلك التعليق، وإعادة إنتاجه، والإفصاح عنه، وإصدار الترخيص الفرعي له، وتوزيعه، وتعديله، واستغلاله دون قيود.`,
  termSection8Title: `روابط لمواقع أخرى `,
  termSection8Part1: `يجوز أن تحتوي خدمتنا على روابط لمواقع إلكترونية أو خدمات تخص الغير ولا تملكها الشركة أو تسيطر عليها. 

ليس للشركة أي سيطرة على المحتوى، أو سياسات الخصوصية، أو الممارسات الخاصة بالمواقع الإلكترونية أو الخدمات الخاصة بالغير، ولا تتحمل مسؤوليتها. وتقر أنت وتوافق على ألا تتحمل الشركة المسؤولية ولا تخضع للمسألة، بشكل مباشر أو غير مباشر، عن أي ضرر أو خسارة تنتج عن، أو من المزعوم أنها نتجت عن، أو تتعلق باستخدام أو الاعتماد على ذلك المحتوى، أو البضائع، أو الخدمات المتاحة على تلك المواقع الإلكترونية أو الخدمات، أو عن طريقتها. 

ننصحك بشدة بقراءة الشروط والأحكام، وسياسة الخصوصية للمواقع الإلكترونية أو الخدمات التي تزورها الخاصة بالغير.`,
  termSection9Title: `الإنهاء `,
  termSection9Part1: `يجوز لنا إغلاق حسابك أو إيقافه فورًا، دون إخطار مسبق أو مسؤولية، لأي سبب يكون، على سبيل المثال لا الحصر، إذا وقع خرق من جانبك لهذه الشروط والأحكام. 

عند الإنهاء، يتوقف حقك في استخدام الخدمة فورًا. إذا كنت ترغب في إغلاق حسابك، يجوز لك إيقافه بسهولة باستخدام الخدمة. `,
  termSection10Title: `حدود المسؤولية `,
  termSection10Part1: `بغض النظر عن أي أضرار قد تتحملها، تُقيد المسؤولية الكاملة للشركة، وأي من مورديها بموجب أي حكم من أحكام هذه الشروط، وكذلك تعويضك الحصري عما سبق، بالمبلغ الذي دفعته أنت بالفعل عن طريق الخدمة أو 100 درهم إماراتي في حالة عدم شرائك أي شيء عن طريق الخدمة.  

وفقًا للحد الأقصى الذي يسمح به القانون المعمول به، لا تتحمل الشركة أو أي من مورديها بأي حال من الأحوال المسؤولية عن أي أضرار خاصة، أو عرضية، أو غير مباشرة، أو لاحقة أيًا ما تكون (على سبيل المثال لا الحصر أضرار خسارة الأرباح، أو فقدان البيانات أو المعلومات الأخرى، أو تعطيل الأعمال، أو الضرر الشخصي، أو فقدان الخصوصية بسبب استخدام الخدمة أو عدم التمكن من استخدامها أو ما يتعلق بذلك بأي شكل، أو برامج الغير و/أو أجهزة الغير المستخدمة في الخدمة، أو غير ذلك فيما يتعلق بأي حكم من أحكام هذه الشروط)، حتى وإن أبلغت الشركة بإمكانية وقوع تلك الأضرار، وحتى وإن لم يحقق التعويض الغرض الأساسي منه. 

لا تسمح بعض الدول باستثناءات الضمانات المذكورة أو القيود على المسؤولية عن الأضرار العرضية أو اللاحقة، مما يعني عدم تطبيق بعض القيود المذكورة أعلاه. في هذه الدول، تُقيد مسؤولية كل طرف بالحد الأقصى الذي يسمح به القانون. `,
  termSection11Title: `إخلاء الطرف بشأن الخدمة "كما هي" "وحسب المتاح" `,
  termSection11Part1: `تُقدم لك الخدمة "كما هي" "وحسب المتاح"، وبكل الأخطاء والعيوب دون ضمان من أي نوع. ووفقًا للحد الأقصى الذي يسمح به القانون، يجوز للشركة، نيابة عن نفسها، وعن شركاتها التابعة، وجهاتهما المرخص لهما ومقدمي خدماتهما، إخلاء الطرف صراحةً من كل الضمانات، سواء الصريحة، أو الضمنية، أو القانونية، أو غيرها، وذلك فيما يخص الخدمة، بما في ذلك كل الضمانات الضمنية الخاصة بالقابلية للتسويق، واللياقة لغرض معين، والملكية، وعدم التعدي، وكل الضمانات التي قد تنشأ عن سير التعامل، أو سير الأداء، أو الاستخدام، أو الممارسة التجارية. ودون تقييد ما سبق، لا تقدم الشركة أي ضمان أو تعهد، ولا تقدم أي إعلانات مع أي نوع بأن تحقق الخدمة شروطًا، أو تحقق أي نتائج مرجوة، أو التوافق مع أي برامج، أو تطبيقات، أو أنظمة، أو خدمات أخرى، أو العمل معها، أو التشغيل دون انقطاع، أو تحقيق أي معايير أداء أو موثوقية، أو الخلو من الأخطاء، أو إمكانية إصلاح أي أخطاء أو عيوب، أو الوعد بإصلاحها في المستقبل. 

دون تقييد ما سبق، لا تقدم الشركة أو أي من مقدميها أي إعلان أو ضمان، صريح أو ضمني،: (أولًا) بشأن تشغيل أو إتاحة الخدمة، أو المعلومات، والمحتوى، والمواد، أو المنتجات التي تشملها؛ أو (ثانيًا) بعدم انقطاع الخدمة أو خلوها من الأخطاء؛ أو (ثالثًا) بشأن دقة، أو موثوقية، أو سريان أي معلومات أو محتوى يُقدم عن طريق الخدمة؛ أو (رابعًا) بأن الخدمة، أو خوادمها، أو محتواها، أو رسائل البريد الإلكترونية الخاصة بها المرسلة من الشركة أو نيابة عنها خالية من الفيروسات، أو النصوص، أو أحصنة طروادة، أو الديدان، أو البرامج الضارة، أو القنابل الموقوتة، أو المكونات الضارة الأخرى. 

لا تسمح بعض الولايات القضائية باستثناء أنواع معينة من الضمانات والقيود على الحقوق القانونية المعمول بها لصالح العميل، فقد لا تنطبق عليك بعض الاستثناءات والقيود الواردة أعلاه، أو كلها. ولكن، في هذه الحالة، تنطبق الاستثناءات والقيود الواردة أعلاه في هذا القسم بالحد الأقصى الساري بموجب القانون المعمول به. `,
  termSection12Title: `القانون الحاكم `,
  termSection12Part1: `تحكم قوانين البلد، باستثناء أوجه تعارضها من قواعد القانون، هذه الشروط واستخدامك للخدمة. ويجوز أيضًا أن يخضع استخدامك للتطبيق إلى القوانين المحلية، أو الوطنية، أو العالمية، أو القوانين الخاصة بالدولة. `,
  termSection13Title: `تسوية المنازعات `,
  termSection13Part1: `إذا كان لديك أي قلق أو منازعة بشأن الخدمة، فأنت توافق على أن تحاول حل المنازعة وديًا أولًا بشكل غير رسمي عن طريق التواصل مع الشركة. `,
  termSection14Title: `إمكانية الفصل والتنازل عن الحقوق `,
  termSection14SubTitle1: `إمكانية الفصل`,
  termSection14Part1: `إذا كان أي حكم من الأحكام الواردة في هذه الشروط غير قابل للتنفيذ أو غير صالح، يتغير ذلك الحكم ويُفسر على النحو الذي يحقق الأهداف المرجوة منه بالحد الأقصى الممكن بموجب القانون المعمول به، ويستمر سريان بقية الأحكام.`,
  termSection14SubTitle2: `التنازل عن الحقوق `,
  termSection14Part2: `باستثناء ما ورد في هذه الشروط والأحكام، لا يؤثر عدم ممارسة حق أو عدم المطالبة بأداء واجب بموجب هذه الشروط في قدرة أي شخص على ممارسة ذلك الحق أو المطالبة بذلك الأداء في أي وقت بعد ذلك، ولا يكون ذلك إعفاءً من الخرق الذي يشكل إعفاءً من الخرق اللاحق. `,
  termSection15Title: `تفسير الترجمة `,
  termSection15Part1: `قد تكون هذه الشروط والأحكام مترجمة إذا أتحناها لك على خدمتنا. وتوافق على أن يسود النص الأصلي باللغة الإنجليزية في حالة المنازعة. `,
  termSection16Title: `تغييرات على هذه الشروط والأحكام `,
  termSection16Part1: `نحتفظ بالحق، وفقًا لتقديرنا المطلق، بتعديل أو تبديل هذه الشروط في أي وقت. وإذا كانت المراجعة أساسية، فنحن نبذل الجهود المعقولة لتقديم إخطار مسبق مدته 30 يومًا على الأقل بشأن أي شروط جديدة سارية. ونحدد وفقًا لتقديرنا المطلق ما يشكل التغيير الأساسي. 

باستمرار الوصول إلى الخدمة أو استخدامها بعد أن تصبح تلك المراجعات سارية، فأنت توافق على أن تلتزم بالشروط التي خضعت للمراجعة. وإذا كنت لا توافق على الشروط الجديدة، كليًا أو جزئيًا، فبرجاء التوقف عن استخدام الموقع الإلكتروني والخدمة. `,
  termSection17Title: `اتصل بنا `,
  termSection17Part1: `إذا كانت لديك أي أسئلة حول هذه الشروط والأحكام، يمكنك الاتصال بنا: 

عبر البريد الإلكتروني: <a class="terms-link" href="mailto:Legal@admn.ae" target="_blank">Legal@admn.ae</a>`,
};
