export default {
    faqTitle: "الأسئلة الشائعة",
    faqTitle0: `
    Maecenas sodales imperdiet ante, 
    vel elementum dui vehicula ut luctus ante a semper facilisis?
    `,
    faqAnwser0: `
    Quisque scelerisque neque lorem, eu posuere est laoreet vitae. 
    Etiam mattis vitae eros non tempus. Sed lacinia mauris eleifend ipsum vulputate, 
    sed malesuada enim blandit. Vivamus in convallis nibh. 
    Aliquam euismod sodales dictum. Nunc consectetur finibus purus, 
    mollis lobortis nisi tempus non. Nullam vitae magna cursus, aliquam dui eu, 
    suscipit purus. Duis lobortis congue dolor, a pulvinar ante aliquam non. 
    In sodales mi tellus, ut vulputate mauris auctor id. 
    In est orci, mattis non porta vel, mattis at mi. Phasellus convallis erat 
    at arcu eleifend sagittis. Aenean lobortis nulla eget ultrices ornare. 
    Sed scelerisque diam vitae tincidunt porta.
    `,
    faqTitle1: `
    Maecenas sodales imperdiet ante, 
    vel elementum dui vehicula ut luctus ante a semper facilisis?
    `,
    faqAnwser1: `
    Quisque scelerisque neque lorem, eu posuere est laoreet vitae. 
    Etiam mattis vitae eros non tempus. Sed lacinia mauris eleifend ipsum vulputate, 
    sed malesuada enim blandit. Vivamus in convallis nibh. 
    Aliquam euismod sodales dictum. Nunc consectetur finibus purus, 
    mollis lobortis nisi tempus non. Nullam vitae magna cursus, aliquam dui eu, 
    suscipit purus. Duis lobortis congue dolor, a pulvinar ante aliquam non. 
    In sodales mi tellus, ut vulputate mauris auctor id. 
    In est orci, mattis non porta vel, mattis at mi. Phasellus convallis erat 
    at arcu eleifend sagittis. Aenean lobortis nulla eget ultrices ornare. 
    Sed scelerisque diam vitae tincidunt porta.
    `,
    faqTitle2: `
    Maecenas sodales imperdiet ante, 
    vel elementum dui vehicula ut luctus ante a semper facilisis?
    `,
    faqAnwser2: `
    Quisque scelerisque neque lorem, eu posuere est laoreet vitae. 
    Etiam mattis vitae eros non tempus. Sed lacinia mauris eleifend ipsum vulputate, 
    sed malesuada enim blandit. Vivamus in convallis nibh. 
    Aliquam euismod sodales dictum. Nunc consectetur finibus purus, 
    mollis lobortis nisi tempus non. Nullam vitae magna cursus, aliquam dui eu, 
    suscipit purus. Duis lobortis congue dolor, a pulvinar ante aliquam non. 
    In sodales mi tellus, ut vulputate mauris auctor id. 
    In est orci, mattis non porta vel, mattis at mi. Phasellus convallis erat 
    at arcu eleifend sagittis. Aenean lobortis nulla eget ultrices ornare. 
    Sed scelerisque diam vitae tincidunt porta.
    `,
};
