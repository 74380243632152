<template>
  <div id="app" class="relative h-full">
    <NuxtLayout>
      <ClientOnly>
        <NuxtLoadingIndicator color="#7F9EC5" />
      </ClientOnly>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
import { useGTMStore } from "@/stores/gtm";
const runtimeConfig = useRuntimeConfig();
const route = useRoute();
const gtmStore = useGTMStore();
const { t } = useI18n();

useSeoMeta({
  title: () => t(`website_title`),
  ogTitle: () => t(`website_title`),
  description: () => t(`website_description`),
  ogDescription: () => t(`website_description`),
  twitterCard: "summary_large_image",
});

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} | ${runtimeConfig.public.mdaApplicationName}`
      : runtimeConfig.public.mdaApplicationName;
  },
});

/**
 * ultimately, we need this in the app.vue
 * for client-side requests image requests to work.
 */
if (process.client) {
  await useConfig().fetchConfig();
}

watch(
  () => route.fullPath,
  (newRoute) => {
    const landingPage = [
      {
        path: "/shows?",
        name: "series",
      },
      {
        path: "/movies",
        name: "movies",
      },
      {
        path: "/sports?",
        name: "sports",
      },
      {
        path: "/radio?",
        name: "radio",
      },
      {
        path: "/news?",
        name: "news",
      },
      {
        path: "/live?",
        name: "live",
      },
      {
        path: "/kids/programs?",
        name: "programs",
      },
      {
        path: "/kids/characters",
        name: "characters",
      },
      {
        path: "/kids/songs",
        name: "songs",
      },
      {
        path: "/kids/majid-tv",
        name: "majid-tv",
      },
      {
        path: "/en?",
        name: "home",
      },
      {
        path: "/ar?",
        name: "home",
      },
    ];
    const current = landingPage.find((obj) => {
      return newRoute.indexOf(obj.path) >= 0;
    });

    if (current) {
      gtmStore.setMediaType(current.name);
    } else if (["/en", "/ar", "/en/kids", "/ar/kids"].includes(newRoute)) {
      gtmStore.setMediaType("home");
    }
  }
);

// override logs
// var console = {};
// console.log = function () {};
// console.info = function () {};
// console.warn = function () {};
</script>

<style>
.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}
</style>
