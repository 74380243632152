function updateProfileColor(res) {
  const colors = ["blue", "red", "teal", "purple", "yellow"];
  const profiles = Array.isArray(res)
    ? res.map((r) => {
        return {
          ...r,
          isKid: r.type.toUpperCase() == "KIDS",
        };
      })
    : [];
  profiles.forEach((_, index) => {
    profiles[index].color = colors[index];
  });
  return profiles;
}

async function addProfile(body) {
  const res = await usePostMethod("/api/uc/user/profile/add", {
    body,
  });

  return res;
}

async function updateProfile(body) {
  const res = await usePostMethod("/api/uc/user/profile/update", {
    body,
  });

  return res;
}

async function fetchProfileList() {
  const res = await useGetMethod("/api/uc/user/profile/list");
  return updateProfileColor(res);
}

async function selectProfile(body) {
  const res = await usePostMethod("/api/uc/user/profile/select", {
    body,
  });
  const { status, code, response } = res;
  if (status == 200 && code == "SUCCESS") {
    res.response.user_profiles = updateProfileColor(response.user_profiles);
  }
  return res;
}

async function deleteProfile(profile) {
  return await useGetMethod(
    "/api/uc/user/profile/delete",
    {
      profileID: profile.external_id,
    },
    { raw: true }
  );
}

async function fetchAccount() {
  return await useGetMethod("/api/uc/user/account/detail");
}

async function fetchAvatars() {
  let mediaUrl;
  if (process.server) {
    mediaUrl = process.env.IMG_HOST;
  } else {
    mediaUrl = useAppConfig().mediaUrl;
  }

  const res = await useGetMethod("/api/biz/config/avatars");
  const parsedList = res.map((r) => {
    return {
      ...r,
      items: r.unifiedItems.map((item) => {
        return {
          ...item,
          url: `${mediaUrl}${item.attachments[0].value}`,
        };
      }),
    };
  });

  return parsedList;
}

async function setParentalCode(code) {
  return await useGetMethod(
    "/api/uc/sec/parentalCode/add",
    { code },
    { raw: true }
  );
}

async function changeParentalCode(code) {
  return await useGetMethod(
    "/api/uc/sec/parentalCode/change",
    { code },
    { raw: true }
  );
}

/**
 * @param {*} body
 * {
 *   profile_id
 *   like: 1 | 0
 *   content_ids: comma-separated string of content ids
 * }
 */
async function setContentRatings(query) {
  return await usePutMethod("/api/biz/profile/contents/like", { query });
}

async function compareParentalCode(code) {
  return await useGetMethod(
    "/api/uc/user/account/comparePincode",
    { pincode: code },
    { raw: true }
  );
}

export default function () {
  return {
    addProfile,
    fetchProfileList,
    selectProfile,
    updateProfile,
    deleteProfile,
    fetchAccount,
    fetchAvatars,
    setParentalCode,
    setContentRatings,
    updateProfileColor,
    compareParentalCode,
    changeParentalCode,
  };
}
