import { useSessionStore } from "@/stores/session";
import { AUTH_PATHS } from "@/constants";
export default defineNuxtRouteMiddleware((to, from) => {
  const sessionStore = useSessionStore();
  const nuxtApp = useNuxtApp();
  const localeCookie = useCookie("locale", {
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  });

  // Check Browser language against available
  const reqLocale = nuxtApp.ssrContext?.event.node.req.headers[
    "accept-language"
  ]
    ?.split(",")[0]
    .split("-")[0];

  // const [baseName, pageLocale] = to.name.split("___");
  const [baseName, pageLocale] = to?.name?.split("___") ?? [];

  const findBrowserLanguageInAvailableLocales =
    nuxtApp.$i18n.locales.value.find((locale) => locale.code === reqLocale);

  // Check Query Param language against available
  const findQueryLanguageInAvailableLocales = nuxtApp.$i18n.locales.value.find(
    (locale) => locale.code === to.query["lang"]
  );

  // Check cookie locale against available locales
  const findCookieLocaleInAvailableLocales = nuxtApp.$i18n.locales.value.find(
    (locale) => locale.code === localeCookie.value
  );

  if (process.client) {
    // Assign the found locale or default
    // Priority Order |> Query param > Cookie > Browser > Default set in nuxt config
    const localeCookieDefaulted =
      findQueryLanguageInAvailableLocales?.code ||
      findCookieLocaleInAvailableLocales?.code;
    localeCookie.value = localeCookieDefaulted;

    if (
      !sessionStore.isAuthenticated &&
      AUTH_PATHS.some((it) => to.path.includes(it))
    ) {
      return navigateTo(nuxtApp.$localePath("/", localeCookieDefaulted));
    }

    /**
     * navigate to the new locale only if:
     * the cookie locale is not undefined and has valid value
     * the user is visiting a page with a locale that's different
     * from what their current locale is
     */
    if (localeCookieDefaulted && localeCookieDefaulted != pageLocale) {
      const newPageName = `${baseName}___${localeCookieDefaulted}`;
      return navigateTo({ name: newPageName, query: to.query });
    }

    /**
     * route guards related to authenticated user
     */
    if (sessionStore.isAuthenticated) {
      /**
       * special case:
       * if the path is going to /link-tv, prevent from going to profiles
       * as we need to show this first before profiles.
       */
      if (to.path.includes("link-tv")) {
        setPageLayout(useLayout(to));
        return;
      }

      /**
       * if user tries to access any other page aside from /profiles
       * but doesnt have an active profile,
       * redirect to /profiles
       */
      if (
        !sessionStore.activeProfile &&
        to.path != nuxtApp.$localePath("/profiles", localeCookieDefaulted)
      ) {
        return navigateTo(
          nuxtApp.$localePath("/profiles", localeCookieDefaulted)
        );
      }

      /**
       * If the current user is logged into a kid account,
       * all returns to the kids homepage
       */
      if (
        sessionStore.activeProfile?.isKid &&
        ![...AUTH_PATHS, "/kids"].some((it) => to.path.includes(it))
      ) {
        return navigateTo(nuxtApp.$localePath("/kids", localeCookieDefaulted));
      }
    }
  }

  setPageLayout(useLayout(to));

  return;
});
