import { defineStore } from "pinia";

export const useGTMStore = defineStore("gtm", {
  state: () => ({
    mediaType: "home",
    isKid: false,
    listName: "",
  }),
  getters: {},
  actions: {
    setMediaType(type) {
      this.mediaType = type;
    },
    setIsKid(type) {
      this.isKid = type;
    },
    setListName(listName) {
      this.listName = listName;
    },
  },
  persist: [
    {
      key: "mediaType",
      storage: persistedState.sessionStorage,
      paths: ["mediaType"],
    },
  ],
});
