import { getActivePinia } from "pinia";
import { PLAYERS } from "@/constants";

const pauseAll = () => {
  const players = Object.values(PLAYERS);
  getActivePinia()._s?.forEach((p) => {
    if (players.includes(p.$id) === false) {
      return;
    }

    p.pause();
  });
};

const resume = (playerId) => {
  getActivePinia()._s.get(playerId)?.play();
};

const priorityResume = (playerId) => {
  pauseAll();
  resume(playerId);
};

const pause = (playerId) => {
  getActivePinia()._s.get(playerId)?.pause();
};

const getStore = (playerId) => {
  return getActivePinia()._s.get(playerId);
};

const resetPassives = async () => {
  const passives = [
    PLAYERS.HERO_PLAYER,
    PLAYERS.DETAIL_PLAYER,
    PLAYERS.FLOATER_PLAYER,
  ];

  await Promise.all(
    passives.map(async (p) => {
      const store = getStore(p);
      await store?.player?.destroy();
      store?.$reset();
    })
  );
};

export default function () {
  return {
    resume,
    pause,
    pauseAll,
    priorityResume,
    getStore,
    resetPassives,
  };
}
