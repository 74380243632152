export default {
  codeError:"The code is incorrect",
  smartScreenApp:'Log in via the smart screen application',
  next:"Next",
  signinPhone:"Sign in from your phone",
  signinTv:"Sign in on TV",
  haveAccount:"Don't have an account",
  back:"Back",
  Email: 'Email',
  "Password": 'Password',
  continue: 'Continue',
  'Sign In': 'Sign in',
  signinWithQrcode: 'Sign in with QR CODE',
  'Create an Account': 'Create an Account',
  continueAsGuest: 'Continue as a guest',
  forgetPassword: 'Forgotten password',
  visit: 'visit',
  enterCodeContinue: 'Enter code to continue',
  inputPlaceholder: 'Select country',
  whereWatchFrom:'Where are you watching from?',
  confirmEmail: 'You will need to confirm your email address.',
  confirmPassword:'Confirm password',
  expireHours: ' The link will expire in 24 hours',
  resendCode: 'Resend link',
  openSystemApp: 'Open system mail app',
  chooseEmailApp: 'Choose your mail app',
  welcomeBack: 'Welcome back',
  yourPassword: 'Your password',
  yourEmail: 'Your email',
  forgetYourPass: 'Forgot your password?',
  apple: 'Apple',
  faceBook: 'Facebook',
  google: 'Google',
  endless: 'Endless Entertainment',
  andMore: 'Films, TV Shows, Radio, Sports, and More',
  existWith: 'Existing users can log in with their current account details', 
  firstName: 'Your first name',
  lastName: 'Your last name',
  firName:'First name',
  laName:'Last name',
  greeTo: "I agree to the",
  terms: 'Terms and Conditions',
  privacyPolicy: 'Privacy Policy',
  cookiePolicy: 'Cookie Policy',
  agreeMarketing: 'I agree to receive marketing emails and special offers',
  resetPassword:
    'Enter your email or mobile number and we will send you a link to reset your password',
  orContinueWith: 'or continue with',
  sendResetLink: 'Send reset link',
  getStarted: 'Get Started',
  languagePopupTitle: "هل ترغب في تبديل اللغة؟",
  languagePopupBtn: "التبديل إلى اللغة العربية",
  cancelBtn: 'Cancel',
  exitBtn: 'Exit',
  phoneNumber: 'Your number',
  mobileNumber:'Mobile number',
  provideDetail:'Provide Your Details',
  setPassword:'Set Your Password',       
  emailSend:'Verification email sent',
  verification:'Verification',
  "Reset Password":"Reset Password",
  signinTitle:'Sign In',
  requirePassword:'Please input your password!',
  passwordNoMatch:"The passwords don't match",
  emailNoMatch:"The email don't match",
  phoneNoMatch:"The mobile number don't match",
  sureExit:'Are you sure to exit?',
  registed:'The email has been registed',
  sureBack: 'Are you sure to go back?',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  Save: 'Save',
  Logout:'Logout',
  FBSignInButAccountHasNoEmail:'We are unable to proceed with the registration/login process using Facebook as the email address is missing.'
};
