import { useRadioStore } from "@/stores/radio";
import { PLAYERS } from "@/constants";

export default function () {
  const radioStore = useRadioStore();
  const widgetPlayer = useAllPlayers().getStore(PLAYERS.RADIO_PLAYER);

  return {
    dismiss: () => {
      radioStore.$patch({
        showWidget: false,
        widgetStation: null,
        episodes: null,
        seriesDetail: null,
      });
      widgetPlayer?.$reset();
    },
  };
}
