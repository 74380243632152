import { defineStore } from "pinia";

export const useWishlistStore = defineStore("wishlist", {
  state: () => ({
    wishlist: [],
    isFetchedOnce: false,
  }),
  getters: {
    getWishlist() {
      return this.wishlist;
    },
    getWishlistSet() {
      const newSet = new Set();
      if (!Array.isArray(this.wishlist)) {
        return newSet;
      }

      this.wishlist?.forEach((item) => {
        switch (item.type) {
          case "Series": {
            newSet.add(item.externalSeriesId);
            break;
          }
          case "Season": {
            newSet.add(item.externalSeriesId);
            break;
          }

          default: {
            newSet.add(item.externalContentId);
            break;
          }
        }
      });
      return newSet;
    },
  },
  actions: {
    async onAdd(mediaExternalID) {
      const payload = {
        body: { external_content_id: mediaExternalID, recommended: false },
      };
      const response = await usePostMethod(
        "/api/biz/profile/wish/add",
        payload
      );
      if (response.status === 200 && response.code === "SUCCESS") {
        this.refreshWishlist();
      }
      return response;
    },
    async onRemove(mediaExternalID) {
      const response = await usePostMethod(
        `/api/biz/profile/wish/remove?external_content_id=${mediaExternalID}`
      );
      if (response.status === 200 && response.code === "SUCCESS") {
        this.refreshWishlist();
      }
      return response;
    },
    async fetchWishlistOnce() {
      if (this.isFetchedOnce) return;
      await this.fetchWishlist();
      this.isFetchedOnce = true;
    },
    refreshWishlist() {
      this.fetchWishlist();
    },

    async fetchWishlist() {
      const response = await useGetAllMethod("/api/biz/profile/wishes");
      if (Array.isArray(response?.response)) {
        this.wishlist = response.response;
      } else {
        this.wishlist = [];
      }
    },
  },
});
