import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

export default function () {
  const breakpoints = useBreakpoints(breakpointsTailwind);

  return {
    breakpoints,
    current: breakpoints.current,
    xs: breakpoints.smaller("sm"),
    xse: breakpoints.smallerOrEqual("sm"),
    sm: breakpoints.between("sm", "md"),
    md: breakpoints.between("md", "lg"),
    lg: breakpoints.between("lg", "xl"),
    xl: breakpoints.between("xl", "2xl"),
    xxl: breakpoints["2xl"],
    isXs: breakpoints.smallerOrEqual("sm"),
    isMdAndUp: breakpoints.greaterOrEqual("md"),
    isLgAndUp: breakpoints.greaterOrEqual("lg"),
    isXlAndUp: breakpoints.greaterOrEqual("xl"),
    isXsAndDown: breakpoints.smaller("sm"),
  };
}
