import revive_payload_client_qyKFwaSCsc from "/app/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.0.0_rollup@3.27.0_sass@1.64.1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_yH29OFHzwU from "/app/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.0.0_rollup@3.27.0_sass@1.64.1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_k4AFllR7JC from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@3.27.0_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_oxdQC2Lt9t from "/app/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.0.0_rollup@3.27.0_sass@1.64.1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_k4J9OhJlMV from "/app/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.0.0_rollup@3.27.0_sass@1.64.1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_I55JPY1217 from "/app/node_modules/.pnpm/nuxt-vue3-google-signin@0.0.10_rollup@3.27.0_vue@3.3.4/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import plugin_zJmzSt0WDO from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.6.5_rollup@3.27.0_vue@3.3.4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_DsicqVklai from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@3.27.0_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_6aMwSj1F6G from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@3.27.0_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_Xz3nlniXfM from "/app/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.0.0_rollup@3.27.0_sass@1.64.1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import tippy_UYJccKXDFD from "/app/plugins/tippy.js";
import vue_tv_focusable_MWrVBDfPZp from "/app/plugins/vue-tv-focusable.js";
import voplayer_IHYj7ZMocE from "/app/plugins/voplayer.js";
import maska_r8BHBbSmHt from "/app/plugins/maska.js";
import plugin_xp6DVp8MMF from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.1.1_@pinia+nuxt@0.4.11_pinia@2.1.6_rollup@3.27.0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import luxon_s40zPPcmn5 from "/app/plugins/luxon.js";
export default [
  revive_payload_client_qyKFwaSCsc,
  router_yH29OFHzwU,
  plugin_vue3_k4AFllR7JC,
  components_plugin_KR1HBZs4kY,
  unhead_oxdQC2Lt9t,
  prefetch_client_k4J9OhJlMV,
  plugin_I55JPY1217,
  plugin_zJmzSt0WDO,
  switch_locale_path_ssr_DsicqVklai,
  i18n_6aMwSj1F6G,
  chunk_reload_client_Xz3nlniXfM,
  tippy_UYJccKXDFD,
  vue_tv_focusable_MWrVBDfPZp,
  voplayer_IHYj7ZMocE,
  maska_r8BHBbSmHt,
  plugin_xp6DVp8MMF,
  luxon_s40zPPcmn5
]