export default {
    credentialMismatch:"Username and password does not match, please check your credentials.",
    accountNotExisting:"Please make sure you've entered the correct email or sign up to create a new account.",
    emailSentToVerify: "Verification email sent",
    resetPassEmailSent: "Reset password email sent",
    profileRemoved: "User profile removed successfully",
    userProfileRemoved: "User profile removed",
    "User profile removed":"User profile removed",
    "Profile picture updated":"Profile picture updated",
    "New password verified":"New password verified",
    "New mobile number updated":"New mobile number updated",
    "Parental code changed":"Parental code changed",
    "Parental code added":"Parental code added",
    geoblocking:`
    The content you are looking for is not available in your region. 
    Don't worry, there's plenty more to explore. Check out our platform for the latest and exciting content.
    `,
    somethingWentWrong:`
        Oops! Something went wrong
        Hold tight! Our team is fixing things. Please check your connection or refresh. Explore new content while we sort it out. 
        Thanks for your patience - back soon!
    `,
    limitAge:"Kids' profiles should have an age limit of under {age} years."
};
  