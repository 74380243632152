export default {
    "January": "يناير", 
    "February": "فبراير", 
    "March": "مارس", 
    "April": "ابريل", 
    "May": "مايو", 
    "June": "يونيو", 
    "July": "يوليو", 
    "August": "اغسطس", 
    "September": "سبتمبر", 
    "October": "اكتوبر", 
    "November": "نوفمبر", 
    "December": "ديسمبر", 
};
  