import { defineStore } from "pinia";
import { useWishlistStore } from "@/stores/wishlist";
import { getActivePinia } from "pinia";
export const useSessionStore = defineStore("session", {
  state: () => ({
    token: null,
    key: null,
    profiles: [],
    activeProfile: null,
    account: null,
    //Verification code for TV scanning login
    code: null,
    /**
     * possible values:
     * whosWatching
     * addProfile
     * setParental
     * editProfile
     * deletingProfile
     * updateAvatar
     * recommendations
     */
    manageStep: "whosWatching",
    profileForm: null,
    househould_id: null,
    identifiers: {
      serialNumber: null,
      terminalName: null,
    },
  }),
  getters: {
    hasMainAccount() {
      return this.activeProfile?.property?.isPrimary;
    },
    activeProfileKey() {
      return this.profiles.findIndex(
        (p) => p.external_id == this.activeProfile?.external_id
      );
    },
    profilesList() {
      const list = [...this.profiles];
      if (list.length < 5) {
        list.push({ id: null, name: "Add New" });
      }

      return list;
    },
    withoutSelfProfiles() {
      return this.profiles.filter(
        (p) => p.external_id != this.activeProfile?.external_id
      );
    },
    isAuthenticated() {
      return this.token !== null;
    },

    hasParentalCode() {
      return this.profiles.some((p) => p.pin_code_exist === 1);
    },
    identifierHeaders() {
      return {
        "Terminal-Name": this.identifiers.terminalName,
        "Serial-Number": this.identifiers.serialNumber,
      };
    },
  },
  actions: {
    generateIdentifiers(email) {
      const uuid = useUuid(email);
      const userAgent = useUserAgent();
      const terminalName = `${userAgent?.os?.name}-WEB-${uuid.slice(0, 4)}`;
      Object.assign(this.identifiers, {
        serialNumber: uuid,
        terminalName: terminalName.toUpperCase(),
      });
    },
    async selectProfile(profileId) {
      const profilesApi = useProfilesApi();
      const body = {
        profile_id: profileId,
      };
      const res = await profilesApi.selectProfile(body);
      if (!res) {
        throw createError("Failed to fetch profile");
      }
      const { status, code, response } = res;
      if (status == 200 && code == "SUCCESS") {
        const { user_profiles: profiles } = response;
        const wishlistStore = useWishlistStore();

        /**
         * dismisses the radio widget when a profile
         * is successfully switched.
         *
         * in the event where there's no radio widget playing,
         * it wont cause anything (i hope)
         */
        useRadioWidget().dismiss();
        wishlistStore.fetchWishlistOnce();
        this.token = response.token;
        this.profiles = profiles;
        this.activeProfile = profiles?.find((p) => p.external_id == profileId);
        useFmaSdk().setProfile(profileId, this.activeProfile.isKid);
      }
    },

    async signOut() {
      await useGetMethod("/api/uc/user/logout");

      // Local storage items to persist after logout
      const itemsToStore = [];
      const keysToPersistAfterSignOut = [
        "Serial-Number",
        "adtv-guest-language",
      ];
      for (let i = 0; i < localStorage.length; i++) {
        const value = localStorage.getItem(localStorage.key(i));
        if (keysToPersistAfterSignOut.includes(localStorage.key(i))) {
          itemsToStore.push({ key: localStorage.key(i), value });
        }
      }

      // reset all stores
      getActivePinia()._s.forEach((store) => store.$reset());
      localStorage.clear();

      // Persist Serial-Number as discussed with Ming
      itemsToStore.forEach((item) => {
        localStorage.setItem(item.key, item.value);
      });

      try {
        useFmaSdk().logout();
        await useFmaSdk().forceInit({
          serialNumber: null,
          terminalId: null,
        });
      } catch (error) {}

      sessionStorage.clear();
      window.location.reload();
      this.$reset();
    },

    prepEditProfile(profile) {
      this.manageStep = "editProfile";

      this.profileForm = {
        ...profile,
        property: {
          ...profile.property,
          isPrimary: profile.property?.isPrimary ?? false,
        },
      };

      if (profile.property.birthday) {
        const [kidDobDay, kidDobMonth, kidDobYear] =
          profile.property.birthday?.split("-");

        this.profileForm.kidDobDay = kidDobDay || null;
        this.profileForm.kidDobMonth = kidDobMonth || null;
        this.profileForm.kidDobYear = kidDobYear || null;
      }
    },
  },
  persist: [
    {
      key: "token",
      storage: persistedState.localStorage,
      paths: ["token"],
    },
    {
      key: "profiles",
      storage: persistedState.localStorage,
      paths: ["profiles"],
    },
    {
      key: "activeProfile",
      storage: persistedState.localStorage,
      paths: ["activeProfile"],
    },
    {
      key: "code",
      storage: persistedState.sessionStorage,
      paths: ["code"],
    },
    {
      key: "identifiers",
      storage: persistedState.localStorage,
      paths: ["identifiers"],
    },
    {
      key: "househould_id",
      storage: persistedState.localStorage,
      paths: ["househould_id"],
    },
  ],
});
