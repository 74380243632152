export default {
  "Learn more": "المزيد",
  "Watch Now": "شاهد الأن",
  Schedule: "الجدول",
  "Load more": "المزيد",
  playMedia: "اعرض: {media}",
  Cast: "الممثلين",
  "Similar to this": "عروض مماثلة",
  "Delete profile": "حذف الملف الشخصي",
  "Next Episode": "الحلقة التالية",
  Quality: "الجودة",
  Speed: "السرعة",
  Audio: "الصوت",
  Subtitles: "الترجمة",
  Off: "إيقاف",
  "Listen Now": "إستمع الأن",
  "Next Song": "الاغنية التالية",
  Okay: "حسناً",
  watchErrorGeoBlock:
    "المحتوى الذي تبحث عنه غير متاح في منطقتك. لا داعي للقلق، هناك الكثير لاستكشافه. تفضل بزيارة منصتنا للحصول على أحدث واكثر المحتويات إثارة.",
  watchErrorGeneric:
    "نواجه حاليًا صعوبات في تشغيل هذا العنوان. يرجى محاولة التشغيل لاحقًا أو اختيار عنوان بديل",
  "Skip Intro": "تخطي المقدمة",
};
