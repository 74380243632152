<template>
  <div
    class="error-page bg-brand-greyblack-1020 w-full h-screen flex justify-center items-center bg-center bg-no-repeat bg-cover"
  >
    <div class="flex flex-col justify-center items-center gap-y-4">
      <h1 class="text-headline-big text-white opacity-[0.87] text-center">
        <slot name="h1">
          {{ $t("genericErrorTitle") }}
        </slot>
      </h1>
      <div
        class="w-full md:max-w-[668px] pb-10 text-center text-body-big md:text-title-big rtl:tracking-normal leading-[31.6px] tracking-[0.6px] text-white opacity-[0.87]"
      >
        <slot name="p">
          <div class="flex flex-col gap-y-2">
            <p
              class="line-1 text-title-big text-white opacity-[0.87] rtl:tracking-normal tracking-wider"
            >
              {{ $t("genericError1") }}
            </p>
            <p
              class="line-2 text-title-big text-white opacity-[0.87] rtl:tracking-normal tracking-wider"
            >
              {{ $t("genericError2") }}
            </p>
          </div>
        </slot>
      </div>
      <code v-if="showErr" class="w-full text-white">
        {{ JSON.stringify(error || {}) }}
      </code>

      <div class="w-full flex justify-center items-center">
        <NuxtLink :to="useLocalePath()('/')" class="text-center">
          <ButtonsCta
            class="variant-primary w-full min-w-[12rem] max-w-[24rem]"
          >
            {{ $t("backToHome") }}
          </ButtonsCta>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps(["error"]);

const showErr = ref(false);
if (process.client) {
  showErr.value = JSON.parse(localStorage.getItem("showErr"));
}
</script>

<style lang="scss" scoped>
.error-page {
  background-image: url("~/assets/pngs/drapery.webp");
  background-size: cover; /* Adjust fit of image on the page */
  background-position: center; /* Adjust image position */
}
</style>
