import { defineStore } from "pinia";

export const useRadioStore = defineStore("radio", {
  state: () => ({
    channels: null,
    activeChannel: null,
    //Radio Channel List
    radioChannelList: [],
    /**radio related states */
    player: null,
    channel: null,
    isPlaying: false,
    isMuted: false,
    isFullscreen: false,

    /**
     * ui related state
     */
    transitionState: "banner",
    preventHideUI: false,
    showHoveredContent: true,
    hideTimeout: null,
    showChannelList: true,
    transitionTimeout: null,
    showWidget: false,
    widgetStation: null,

    /**podcast related states */
    episodes: null,

    seriesDetail: null,
  }),
  getters: {
    sortedEpisodes() {
      /**
       * Sorts episodes by season number in ascending order to match index
       */
      const episodes = [...(this?.episodes ?? [])];

      episodes.sort(
        (a, b) =>
          a?.tvShowReference?.episodeNumber - b?.tvShowReference?.episodeNumber
      );
      return episodes;
    },
    currentWatchingIndex() {
      const currentIndex = this.sortedEpisodes.findIndex((episode) => {
        return (
          episode.assetExternalId == this.widgetStation.query.video_external_id
        );
      });
      return currentIndex;
    },
    nextToWatch() {
      if (this.currentWatchingIndex === -1) return null;
      const nextEpisode =
        this.sortedEpisodes?.[this.currentWatchingIndex + 1] ?? null;
      return nextEpisode;
    },
    prevToWatch() {
      if (this.currentWatchingIndex === -1) return null;
      const prevEpisode =
        this.sortedEpisodes?.[this.currentWatchingIndex - 1] ?? null;
      return prevEpisode;
    },
    isPodcast() {
      if (!this.widgetStation) return;
      return this.widgetStation.isPodcast || false;
    },

    isFirstEpisode() {
      if (!this.widgetStation || !this.isPodcast) return null;
      const index = this.episodes?.findIndex(
        (e) => e.assetExternalId == this.widgetStation.query.video_external_id
      );

      return this.prevToWatch === null;
    },
    isLastEpisode() {
      if (!this.widgetStation || !this.isPodcast) return null;
      const index = this.episodes?.findIndex(
        (e) => e.assetExternalId == this.widgetStation.query.video_external_id
      );

      return this.nextToWatch === null;
    },
  },
  actions: {
    togglePlayback() {
      if (this.player?.playing_) {
        this.player?.pause();
        this.isPlaying = false;

        this.onPause();

        return;
      }
      this.player?.play();
      this.isPlaying = true;
      clearTimeout(this.transitionTimeout); // Clear any existing timeout.
      clearTimeout(this.hideTimeout); // Clear any existing timeout.
    },
    toggleVolume() {
      this.isMuted = !this.isMuted;
      this.player.volume = this.isMuted ? 0 : 1;

      this.player.video_.muted = this.isMuted;
    },

    onPlay() {
      this.transitionState = "player";
      this.showChannelList = false;
      this.hideUi();
      clearTimeout(this.transitionTimeout);
    },

    onPause() {
      this.transitionTimeout = setTimeout(() => {
        clearTimeout(this.transitionTimeout);
        this.player?.destroy();
        this.showChannelList = true;
        this.transitionState = "banner";
      }, 2500);
    },

    hideUi() {
      if (this.preventHideUI) return;
      clearTimeout(this.hideTimeout); // Clear any existing timeout.
      this.hideTimeout = setTimeout(() => {
        clearTimeout(this.hideTimeout);
        this.showHoveredContent = false;
      }, 3000);
    },
  },
  persist: [
    {
      key: "radioChannelList",
      storage: persistedState.sessionStorage,
      paths: ["channels"],
    },
  ],
});
