async function init(forceReInit = false, overrideConfig = {}) {
  const {
    $voplayer: { VOFmaManager },
  } = useNuxtApp();

  // only calls the init if isInitialized is false and there's no force reinit
  if (VOFmaManager.Instance.isInitialized() && !forceReInit) {
    // console.info("[vo-fma] skipping initialization");
    return;
  }

  const userAgent = useUserAgent();

  const {
    public: { mdaApplicationName, mdaApplicationVersion, fmaFirmwareVersion },
  } = useRuntimeConfig();

  const config = {
    configurationUrl: "https://api-admc-prod.vo-player-qox.com/fma/1.0/config",
    applicationName: mdaApplicationName,
    applicationVersion: mdaApplicationVersion,
    deviceCategory: userAgent.device.type,
    firmwareVersion: fmaFirmwareVersion,
    ...overrideConfig,
  };

  await VOFmaManager.Instance.init(config);

  // console.info("[vo-fma] initialized", { config });
}
export default function () {
  const {
    $voplayer: { VOFmaManager, VoFmaUiElementInfo, FMA, VoFmaUiLogger },
  } = useNuxtApp();

  const nav = useState("nav", () => null);
  const searchSession = useState("search", () => null);

  return {
    forceInit: (overrideConfig) => init(true, overrideConfig),
    logPageNav: async (pageId, options) => {
      await init();
      // console.info("[vo-fma] log navigation to", pageId);

      const payload = {
        uiViewInfo: {
          uiElementId: pageId,
          uiNavigationTrigger: VoFmaUiElementInfo.UiNavigationTrigger.UI_BUTTON,
        },
      };
      if (options?.contentInfo) {
        payload.contentInfo = {
          ...options.contentInfo,
        };
      }

      const searchSessionId = searchSession.value?.getSessionId();

      if (searchSessionId) {
        payload.searchSessionId = searchSessionId;
      }

      console.log("[nav page fma]===>", payload);

      nav.value =
        VOFmaManager.Instance.getUiLogger().startLoadingUiView(payload);
    },
    async loaded() {
      try {
        if (!nav.value) throw "trying to call onLoaded";
        await nav.value.onLoaded(true);
      } catch (error) {}
      // console.info("[vo-fma] log completed navigation");
    },
    terminate() {
      // console.info("[vo-fma] log termination of ui view");
      searchSession.value?.close();
      VOFmaManager.Instance.getUiLogger().terminate();
    },

    startSearchSession: async (searchValue, resultCount) => {
      init().then(() => {
        searchSession.value = VOFmaManager.Instance.getUiLogger().searchTerm(
          searchValue,
          resultCount,
          VoFmaUiLogger.SearchType.UNDEFINED
        );
        console.log("search result count", searchValue, resultCount);
        // console.info("[vo-fma] started a search session with", {
        //   searchValue,
        //   resultCount,
        // });
      });
    },
    login: (householdID) => {
      console.log("login fma", householdID);
      VOFmaManager.Instance.getUiLogger().login(householdID);
    },
    logout: () => {
      console.log("login out fma");
      VOFmaManager.Instance.getUiLogger().logout();
    },
    setProfile: (profileCountId, isKid) => {
      VOFmaManager.Instance.getUiLogger().setProfile(
        profileCountId,
        isKid ? "kids" : ""
      );
      console.log("setProfile", profileCountId, isKid);
    },
    registerSearchClick: (content) => {
      let searchType;
      switch (content.type) {
        case "Movie":
          searchType = VoFmaUiLogger.SearchType.CONTENT_MOVIE;
          break;
        case "Series":
        case "Season":
          searchType = VoFmaUiLogger.SearchType.CONTENT_SEASON;
          break;
        default:
          searchType = VoFmaUiLogger.SearchType.UNDEFINED;
      }

      searchSession.value.registerClick(
        {
          contentId: content?.externalId,
          contentName: content?.name,
        },
        searchType
      );
      console.info("[vo-fma] click registered", {
        contentId: content?.externalId,
        contentName: content?.name,
        searchType,
      });
    },
  };
}
