async function fetchConfig() {
  const config = useState("config", () => {});
  const response = await useGetMethod("/api/biz/config/v1/config");
  config.value = {
    ...response,
    mediaUrl: `${response["host"]}${response["resource-path"]}`,
  };

  updateAppConfig({ ...config.value });
}

async function generateDrm(token, isLive) {
  const appendToken = (host, token) => {
    const url = `${host}${encodeURIComponent(token)}`;
    return url;
  };
  const appConfig = useAppConfig();

  const expectedDrmKeys = [
    "fairplay_certificate",
    "widevine_certificate",
    "fairplay_licenser_live",
    "fairplay_licenser_vod",
    "widevine_licenser_live",
    "widevine_licenser_vod",
    "playready_licenser_live",
    "playready_licenser_vod",
  ];

  const allKeysExist = expectedDrmKeys.every((key) =>
    appConfig.hasOwnProperty(key)
  );

  if (!allKeysExist) {
    await fetchConfig();
  }

  const drmKeys = {};

  if (isLive) {
    Object.assign(drmKeys, {
      "com.widevine.alpha": appendToken(
        appConfig["widevine_licenser_live"],
        token
      ),
      "com.microsoft.playready": appendToken(
        appConfig["playready_licenser_live"],
        token
      ),
      "com.apple.fps": appendToken(appConfig["fairplay_licenser_live"], token),
    });
  } else {
    Object.assign(drmKeys, {
      "com.widevine.alpha": appendToken(
        appConfig["widevine_licenser_vod"],
        token
      ),
      "com.microsoft.playready": appendToken(
        appConfig["playready_licenser_vod"],
        token
      ),
      "com.apple.fps": appendToken(appConfig["fairplay_licenser_vod"], token),
    });
  }

  return {
    drmAgentType: "widevine",
    servers: drmKeys,
    advanced: {
      "com.apple.fps": {
        serverCertificateUrl: appConfig["fairplay_certificate"],
      },
      "com.widevine.alpha": {
        serverCertificateUrl: appConfig["widevine_certificate"],
      },
    },
  };
}

export default function () {
  return {
    fetchConfig,
    generateDrm,
  };
}
