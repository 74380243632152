import termsAr from './terms/ar';
import privacyAr from './privacy/ar';
import whoweareAr from './whoweare/ar';
import availableonAr from './availableon/ar';
import faqsAr from './faqs/ar';

export default {
    ...termsAr,
    ...privacyAr,
    ...whoweareAr,
    ...availableonAr,
    ...faqsAr,
}