export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Stream your favorite movies, TV shows, and original content anytime, anywhere."},{"property":"og:site_name","content":"ADtv"},{"property":"og:image","content":"https://adtv.ae/static/seo-thumb.jpg"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:image","content":"https://adtv.ae/static/seo-thumb.jpg"},{"name":"theme-color","content":"#272727"}],"link":[{"rel":"icon","type":"image/svg","href":"/static/favi-144.svg"}],"style":[],"script":[{"src":"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"},{"src":"https://connect.facebook.net/en_US/sdk.js"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appCdnURL = ""

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null