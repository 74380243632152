import { ofetch } from "ofetch";
import { useSessionStore } from "@/stores/session";
import { useGetDeviceType } from "@/composables/useUserAgent";
import { RESPONSE_STATUS } from "@/constants";

async function call(path, extraOpts) {
  const nuxtApp = useNuxtApp();
  // get token here
  let clientHeaders = {};

  /**
   * all authenticated requests must be done on the client side
   * otherwise the token will not be appended to the header
   */
  if (process.client) {
    const sessionStore = useSessionStore();
    clientHeaders = { "api-authsign": 0, "api-timestamp": Date.now() };
    if (sessionStore.isAuthenticated) {
      const token = sessionStore.token;
      Object.assign(clientHeaders, { "Common-Token": token });
    }

    if (
      Object.values(sessionStore.identifiers).length > 0 &&
      sessionStore.identifiers.serialNumber
    ) {
      Object.assign(clientHeaders, { ...sessionStore.identifierHeaders });
    }
  }

  const deviceType = useGetDeviceType();

  try {
    let options = {
      baseURL: process.env.BASE_URL,
      headers: {
        Accept: "application/json",
        "App-Locale": nuxtApp.$i18n.locale.value || "ar",
        "Device-type": deviceType,
        ...clientHeaders,
        ...(extraOpts.headers || {}),
      },
      method: extraOpts.method || "GET",
    };

    const method = extraOpts.method || "GET";
    if (["POST", "PATCH", "PUT"].includes(method)) {
      options = {
        ...options,
        params: extraOpts.params || {},
        body: extraOpts.body || {},
        query: extraOpts.query || {},
      };
    }

    if (method == "GET") {
      options = { ...options, query: extraOpts.query || {} };
    }

    const res = await ofetch(path, {
      ...options,
      onRequest: (context) => {
        if (process.server) {
          const requestLog = {
            baseURL: context.options.baseURL,
            request: context.request,
            method: context.options.method,
            headers: JSON.stringify(context.options.headers),
            query: JSON.stringify({ ...context.options.query }),
          };
          // console.log("request", requestLog);
        }

        return context;
      },
      onResponse: (context) => {
        if (process.server) {
          const serverLog = {
            ...context.response._data,
            response: JSON.stringify(context.response._data?.response),
          };
          // console.log("response", serverLog);
        }
      },
    });

    if (process.client) {
      const sessionStore = useSessionStore();
      //Token expired
      if (res && res.status == RESPONSE_STATUS.expired) {
        // console.info("expired", path, options.query);
        sessionStore.signOut();
      }
      //Profile removed
      if (res && res.status == RESPONSE_STATUS.profileRemoved) {
        console.info("profile removed");
        const pid = sessionStore.activeProfile?.id;
        const profiles = sessionStore.profiles?.filter((it) => it.id != pid);
        sessionStore.$patch({
          profiles,
          activeProfile: null,
        });
      }
    }
    return res;
  } catch (error) {
    // TODO: toast status interceptor
    // console.error("error-> ", error);
  }
}
export const useGetMethod = async (path, query, extraOpts) => {
  const res = await call(path, { query, ...extraOpts });
  if (extraOpts?.raw) {
    return res;
  }
  return res.response;
};
export const useGetAllMethod = async (path, query) => {
  return call(path, { query });
};

export const usePostMethod = async (path, options) => {
  const res = await call(path, { ...options, method: "POST" });
  return res;
};

export const usePutMethod = async (path, options) => {
  const res = await call(path, { ...options, method: "PUT" });
  return res;
};
